<script lang="ts" setup>
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { stickyNoteUnpined } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const handleClick = (event: Event) => {
  cardMeta.unpin(event);

  const stickyType = card.type.functionality;
  trackEvent(stickyNoteUnpined(stickyType, "pin-button"));
};
</script>

<template>
  <button class="sticky-note-pin" @click="handleClick">
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="10"
        y1="7"
        x2="10"
        y2="12"
        stroke-width="2"
        stroke="#1c1e21"
        stroke-linecap="round"
      />
      <circle cx="10" cy="5" r="5" fill="#ff123b" />
    </svg>
  </button>
</template>

<style lang="scss" scoped>
@use "@/styles/colors";

.sticky-note-pin {
  position: absolute;
  cursor: pointer;
  width: 30px;
  display: flex;
  justify-content: center;
  right: 41%;
  transform: rotate(50deg);
  top: -12px;
  outline: revert;
}
</style>
