import { defineStore } from "pinia";

export const useActionStore = defineStore("action", {
  state: () => ({
    previewTimeout: 0,
    previewing: null as unknown | null,
  }),
  getters: {
    wasPreviewing(state) {
      window.clearTimeout(state.previewTimeout);
      return state.previewing;
    },
  },
  actions: {
    preview(action: unknown, execute: () => void, delay: number) {
      this.previewTimeout = window.setTimeout(() => {
        execute();
        this.previewing = action;
      }, delay);
    },
    endPreview() {
      this.previewing = null;
    },
  },
});
