import { rectangle } from "@/math/coordinates";
import { Board } from "@/model/board";
import { BoardCard } from "@/model/card";
import {
  Rectangle,
  RelativeCoordinate,
  relativeCoord,
} from "@/model/coordinates";
import { Shape, fixLine } from "@/model/shape";

export const borderLines = [
  fixLine("bv1", relativeCoord(0, 0), relativeCoord(0, 1)),
  fixLine("bv2", relativeCoord(1, 0), relativeCoord(1, 1)),
  fixLine("bh1", relativeCoord(0, 0), relativeCoord(1, 0)),
  fixLine("bh2", relativeCoord(0, 1), relativeCoord(1, 1)),
];

export interface BoardLocation {
  name: string;
  bounds: Rectangle<RelativeCoordinate>;
  shapes: Shape[];

  matches(coord: RelativeCoordinate): boolean;

  index(): number[];
}

export const fullBoardLocation: BoardLocation = {
  name: "",
  bounds: rectangle(relativeCoord(0, 0), relativeCoord(1, 1)),
  shapes: [...borderLines],
  matches() {
    return true;
  },
  index() {
    return [];
  },
};

export function cardsInLocation(board: Board, loc: BoardLocation): BoardCard[] {
  const cards = new Array<BoardCard>();
  for (const id in board.cards) {
    if (loc.matches(board.cards[id].meta.pos)) {
      cards.push(board.cards[id]);
    }
  }
  return cards;
}
