import OverviewModal from "@/components/modal/OverviewModal.vue";
import TeamObjectivesModal from "@/components/modal/TeamObjectivesModal.vue";
import MetricsModal from "@/components/modal/metrics/MetricsModal.vue";
import OverallMetricsModal from "@/components/modal/metrics/OverallMetricsModal.vue";
import { namedKey, namedNoKey } from "@/components/utils/Shortcuts";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useBoardMenuStore } from "@/store/boardMenu";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useDrawStore } from "@/store/draw";
import { useModalStore } from "@/store/modal";
import { usePointerStore } from "@/store/pointer";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useSwitchSessionStore } from "@/store/switchSession";
import { useTimerStore } from "@/store/timer";
import { useWorkModeStore } from "@/store/workMode";
import { i18n } from "@/translations/i18n";
import {
  boardDiffOpened,
  metricsModalOpened,
  pointerTrailActivated,
  searchClosed,
  searchOpened,
  timerSidebarOpened,
  workModeChanged,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { action, defineActions, getActionTrigger } from "./actions";

export const toggleActions = defineActions("toggle", {
  showTrailingPointer: action(showTrailingPointer, {
    name: /*$t*/ "action.pointer",
    shortcut: namedKey("KeyA", { modifiers: ["shift"] }),
    icon: "menu-bottom/pointer",
  }),
  toggleWorkingMode: action(toggleWorkingMode, {
    shortcut: namedKey("KeyM", { modifiers: ["shift"] }),
  }),
  showPlanningMode: action(
    () => {
      trackEvent(workModeChanged("planning", "topbar-menu"));
      useWorkModeStore().setWorkingMode("planning");
    },
    {
      name: /*$t*/ "action.planMode",
      icon: "menu-top/pi",
    },
  ),
  showExecutionMode: action(
    () => {
      trackEvent(workModeChanged("execution", "topbar-menu"));
      useWorkModeStore().setWorkingMode("execution");
    },
    {
      name: /*$t*/ "action.executionMode",
      icon: "menu-top/progress",
    },
  ),
  showTimer: action(toggleTimer, {
    name: /*$t*/ "action.timer",
    shortcut: namedKey("KeyT", { modifiers: ["altCtrl"] }),
    icon: "menu-top/timer",
  }),
  showDiff: action(toggleBoardDiff, {
    name: /*$t*/ "boardDiff.boardTitle",
    icon: "action/activity",
    shortcut: namedKey("KeyH"),
  }),
  showSwitchSession: action(toggleSwitchSession, {
    name: /*$t*/ "switchSession.title",
  }),
  showSearch: action(toggleSearch, {
    name: /*$t*/ "action.search",
    shortcut: namedKey("/"),
  }),
  showTeamObjectives: action(
    () => {
      const board = useBoardStore().currentBoard();
      if (board.type === "team") {
        useModalStore().open(TeamObjectivesModal, {
          attrs: { boardId: board.id },
        });
      }
    },
    {
      name: /*$t*/ "action.teamObjectives",
      shortcut: namedKey("KeyO", { modifiers: ["altCtrl"] }),
    },
  ),
  showOverview: action(
    (attrs) => useModalStore().open(OverviewModal, { attrs }),
    {
      name: /*$t*/ "action.showOverview",
      shortcut: namedNoKey(() => i18n.global.t("mouse.doubleClick")),
    },
  ),
  showProgramMetrics: action(() => useModalStore().open(OverallMetricsModal), {
    name: /*$t*/ "action.programMetrics",
    icon: "menu-top/metrics",
  }),
  showTeamMetrics: action(
    () => {
      trackEvent(metricsModalOpened("topbar-menu"));
      useModalStore().open(MetricsModal);
    },
    {
      name: /*$t*/ "action.teamMetrics",
      icon: "menu-top/metrics",
    },
  ),
  shiftMenu: action(() => useBoardMenuStore().toggleMenuPosition(), {
    name: /*$t*/ "action.shiftMenu",
    icon: "arrow/right-shift",
  }),
  showStats: action(() => useClientSettingsStore().toggleShowStats(1), {
    shortcut: namedKey("KeyI", { modifiers: ["altCtrl"] }),
  }),
  showStatGraphs: action(() => useClientSettingsStore().toggleShowStats(2), {
    shortcut: namedKey("KeyJ", { modifiers: ["altCtrl"] }),
  }),
  showDraw: action(() => useDrawStore().toggleActive()),
});

function showTrailingPointer(trigger: "board-actions-menu") {
  const isPointerActive = usePointerStore().isPointerActive;
  if (!isPointerActive) {
    const t = trigger || getActionTrigger(showTrailingPointer);
    trackEvent(pointerTrailActivated(t));
  }

  usePointerStore().togglePointerActivation();
}

function toggleWorkingMode() {
  const currentMode = useWorkModeStore().workingMode;
  const nextMode = currentMode === "planning" ? "execution" : "planning";

  const trigger = getActionTrigger(toggleWorkingMode) || "topbar-menu";

  useWorkModeStore().setWorkingMode(nextMode);
  trackEvent(workModeChanged(nextMode, trigger));
}

function toggleTimer(trigger?: "topbar-menu") {
  const timerStore = useTimerStore();
  if (!timerStore.active) {
    trackEvent(timerSidebarOpened(trigger || getActionTrigger(toggleTimer)));
  }

  timerStore.toggleTimer();
  useBoardMenuStore().setMenuPosition(
    useTimerStore().active ? "left" : "default",
  );
}

function toggleBoardDiff() {
  const isActive = useActivityStore().isBoardActivityOpen;
  if (!isActive) {
    trackEvent(boardDiffOpened());
  }
  useActivityStore().toggleBoard();
}

function toggleSwitchSession() {
  useSwitchSessionStore().toggleActive();
}

function toggleSearch(
  trigger?: "keyboard-shortcut" | "topbar-menu",
  keepFilters?: boolean,
) {
  const isActive = useSearchMenuStore().isSearchSidebarOpen;
  const t = trigger || getActionTrigger(toggleSearch);

  if (isActive) {
    trackEvent(searchClosed(keepFilters, t));
  } else {
    trackEvent(searchOpened(t));
  }

  useSearchMenuStore().toggleSearch();
}
