import { RelativeCoordinate } from "@/model/coordinates";
import { useArtStore } from "@/store/art";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { i18n } from "@/translations/i18n";

import { BasePlanningBoard } from "./BasePlanningBoard";
import { PlanningBoardLocation } from "./location/PlanningBoardLocation";

export class ProgramBoard extends BasePlanningBoard<"program"> {
  get groups() {
    const milestoneEvents = {
      id: "",
      name: i18n.global.t("programBoard.milestoneEvents"),
    };
    const otherArts = useArtStore().isMultiArt
      ? [{ id: "", name: i18n.global.t("programBoard.otherArts") }]
      : [];
    const emptyTeam = { id: "", name: "" };
    return [
      milestoneEvents,
      ...useTeamStore().teamsInArt(this.board.artId || ""),
      ...otherArts,
      emptyTeam,
    ];
  }

  location(pos: RelativeCoordinate | number, top?: number) {
    return PlanningBoardLocation.ofTeams(this.groups, pos, top);
  }

  positionalCardProperties(pos: RelativeCoordinate) {
    const loc = this.location(pos);
    return { iterationId: loc.iterationId, teamId: loc.groupId };
  }

  get showPermanentLinks() {
    return useClientSettingsStore().permanentProgramLinks;
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("program");
  }
}
