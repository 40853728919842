<template>
  <div :id="board.id" class="board board-flex">
    <LoadingIndicator v-if="!board.loaded" global />
    <LinkLayers :board="board" :color="linkColor" />
    <FlexBackground :type="background" class="background" />
    <StickyNote
      v-for="card in board.cards"
      :key="card.data.id"
      :card="card.data"
      :card-meta="card.meta"
      :level-of-details="levelOfDetails"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";

import LinkLayers from "@/components/LinkLayer/LinkLayers.vue";
import StickyNote from "@/components/StickyNote/StickyNote.vue";
import FlexBackground from "@/components/background/FlexBackground.vue";
import { ActionType } from "@/components/card/actions";
import { normalLinkColors } from "@/model/colors";
import { useBoardsStore } from "@/store/boards";

import LoadingIndicator from "../ui/LoadingIndicator/LoadingIndicator.vue";
import FluidBoard from "./FluidBoard";

@Component({
  components: { LinkLayers, LoadingIndicator, FlexBackground, StickyNote },
})
export default class FlexBoard extends mixins(FluidBoard) {
  linkColor = normalLinkColors.flex;
  actions: ActionType[] = ["delete", "link", "dragLink", "close"];

  get board() {
    return useBoardsStore().boardByType("flex");
  }

  get background() {
    return this.board.flexType?.background;
  }
}
</script>

<style lang="scss">
.board-flex {
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
