import { RelativeCoordinate } from "@/model/coordinates";
import { useStickyTypeStore } from "@/store/stickyType";

import { BaseBoard, ContextInfo } from "./BaseBoard";

export class FlexBoard extends BaseBoard<"flex"> {
  get possibleStickyTypes() {
    return useStickyTypeStore().flexBoardStickyTypes(this.board.flexType.id);
  }

  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return {
      draw: this.showDraw,
      selection: {
        stickyMove: true,
      },
    };
  }
}
