import { Ref, computed, onMounted, watch } from "vue";

import { linkActions } from "@/action/linkActions";
import { useDelayedAction } from "@/composables/utils/useDelayedAction";
import { Board } from "@/model/board";
import { Card } from "@/model/card";
import { useDraggingStore } from "@/store/dragging";
import { useLinkStore } from "@/store/link";
import { stickyNotePinned, stickyNoteUnpined } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { eventTarget } from "@/utils/dom/dom";

export interface Props {
  el?: Ref<HTMLDivElement | undefined>;
  board: Ref<Readonly<Board>>;
  card: Card;
  disabled?: Ref<Readonly<boolean>>;
  delay?: number; // delay in milliseconds
}

export function usePin({ el, card, board, disabled, delay = 500 }: Props) {
  const isPinned = computed(() => useLinkStore().isPinned(card.id));

  onMounted(() => {
    if (disabled?.value) return;

    el?.value?.addEventListener("pointerdown", handlePointerDown);
    el?.value?.addEventListener("pointerup", handlePointerUp);
  });

  watch(
    () => useDraggingStore().dragging[card.id],
    () => {
      cancelPin();
      cancelUnpin();
    },
  );

  const handlePointerDown = (event: PointerEvent) => {
    const target = eventTarget(event)!;
    const isLinkDrag = target.classList.contains("link-drag");

    if (disabled?.value || isLinkDrag) return;

    if (isPinned.value) {
      delayedUnpin(event);
    } else {
      delayedPin(event);
    }
  };

  const handlePointerUp = () => {
    cancelPin();
    cancelUnpin();
  };

  // used to analytics
  const trackStateChange = (state: "pinned" | "unpinned") => {
    const stickyType = card.type.functionality;
    if (state === "pinned") {
      trackEvent(stickyNotePinned(stickyType, "long-press-on-sticky-note"));
    } else {
      trackEvent(stickyNoteUnpined(stickyType, "long-press-on-sticky-note"));
    }
  };

  // prevent clicks on links when pinning/unpinning
  const linkClickPrevent = (target: HTMLElement | null) => {
    if (target instanceof HTMLAnchorElement) {
      target.addEventListener(
        "click",
        (e) => {
          e.preventDefault();
        },
        { once: true },
      );
    }
  };

  const pin = (event?: Event) => {
    if (event) {
      linkClickPrevent(eventTarget(event));
    }
    if (isPinned.value || useDraggingStore().isLinkDragging) return;

    linkActions.markCardLinkedCards("mouse", board.value.cards[card.id]);

    trackStateChange("pinned");
  };

  const unpin = (event?: Event) => {
    if (event) {
      linkClickPrevent(eventTarget(event));
    }
    if (!isPinned.value || useDraggingStore().isLinkDragging) return;

    useLinkStore().removeAllMarks();

    trackStateChange("unpinned");
  };

  const [delayedPin, cancelPin] = useDelayedAction(pin, delay);
  const [delayedUnpin, cancelUnpin] = useDelayedAction(unpin, delay);

  return { isPinned, unpin, pin };
}
