<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  label: string;
  windows: string[];
  mac: string[];
  isWindows: boolean;
  ariaLabel?: string;
  column?: number;
}>();

const { t } = useI18n();

const characters = computed(() => {
  return props.isWindows ? props.windows : props.mac;
});

const valueString = computed(() => {
  return characters.value.join(", ");
});

const composedAriaLabel = computed(
  () => props.ariaLabel || t("label.shortcut", { value: valueString }),
);
</script>

<template>
  <div class="shortcut-entry" :style="{ gridColumn: column }">
    <dt>{{ label }}</dt>
    <dd :aria-label="composedAriaLabel">
      <slot>
        <span
          v-for="(char, i) in characters"
          :key="i"
          class="key"
          data-t
          aria-hidden="true"
        >
          {{ char }}
        </span>
      </slot>
    </dd>
  </div>
</template>

<style lang="scss" scoped>
.shortcut-entry {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
</style>
