import { NamedKey, noKey } from "@/components/utils/Shortcuts";
import { Icon } from "@/model/icon";

export type Action<
  TParams extends unknown[] = [],
  TReturn = void,
  TState = Record<string, never>,
> = ActionFun<TParams, TReturn> & { data: ActionData<TParams, TState> };

type ActionFun<TParams extends unknown[], TReturn> = (
  source: ActionSource,
  ...params: TParams
) => TReturn;

export interface ActionData<TParams extends unknown[], TState> {
  id: string;
  name: string;
  history?: {
    merge?: boolean;
    saveState: (...params: TParams) => TState;
  };
  shortcut?: NamedKey;
  icon?: Icon;
  startPreview?: PreviewFun<TParams>;
  stopPreview?: PreviewFun<TParams>;
}

type PreviewFun<TParams extends unknown[]> = (...params: TParams) => void;

export type ActionSource =
  | "mainMenu"
  | "contextMenu"
  | "modal"
  | "keyboard"
  | "mouse"
  | "board"
  | "card"
  | "dragDrop"
  | "keyboardDrag"
  | "internal";

export interface ActionDefinitionData<TParams extends unknown[], TState> {
  name?: string;
  history?: {
    merge?: boolean;
    saveState: (...params: TParams) => TState;
  };
  shortcut?: NamedKey;
  icon?: Icon;
}

export function makeAction<TParams extends unknown[], TReturn, TState>(
  fun: ActionFun<TParams, TReturn>,
  data: ActionData<TParams, TState>,
): Action<TParams, TReturn, TState> {
  const res = fun as Action<TParams, TReturn, TState>;
  res.data = data;
  return res;
}

export function makeActionData<TParams extends unknown[], TState>(
  data: ActionDefinitionData<TParams, TState>,
  startPreview?: PreviewFun<TParams>,
  stopPreview?: PreviewFun<TParams>,
): ActionData<TParams, TState> {
  return {
    id: "",
    name: data.name || "",
    history: data.history,
    shortcut: data.shortcut || noKey,
    icon: data.icon,
    startPreview,
    stopPreview,
  };
}
