<script setup lang="ts">
import { computed } from "vue";

import SelectStickyType from "@/components/SelectStickyType/SelectStickyType.vue";
import { useBoardStore } from "@/store/board";
import { useSearchMenuStore } from "@/store/searchMenu";

import StickyTypeItemTooltip from "../tooltips/StickyTypeItemTooltip.vue";
import SearchDropdown from "./SearchDropdown.vue";

const store = useSearchMenuStore();
const stickyTypes = useBoardStore().possibleStickyTypes;

const selection = computed({
  get: () => store.selectedStickyTypes,
  set: (values: Array<{ id: string }>) =>
    (store.selectedStickyTypeIds = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <SearchDropdown
    name="sticky-type"
    icon="general/sticky"
    label-key="general.stickyTypes"
    :selected="selection.length > 0"
  >
    <template #tooltip>
      <StickyTypeItemTooltip :types="selection" />
    </template>
    <SelectStickyType v-model="selection" :sticky-types="stickyTypes" />
  </SearchDropdown>
</template>
