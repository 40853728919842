<script lang="ts" setup>
import { computed } from "vue";

import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import StickyLinkModal from "@/components/modal/stickyLink/StickyLinkModal.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { Card } from "@/model/card";
import { useModalStore } from "@/store/modal";
import { stickyNoteLinkingModalOpened } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

import { isActionVisible } from "../../utils";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const visible = computed(() => isActionVisible("link", card, board.value));

const handleLinkModalOpen = (card: Card) => {
  const stickyType = card.type.functionality;
  trackEvent(stickyNoteLinkingModalOpened(stickyType, "action-menu"));

  useModalStore().open(StickyLinkModal, {
    attrs: { cardIds: [card.id], "data-ignore-click": true },
    trigger: "action-menu",
  });
};
</script>

<template>
  <ActionMenuItem
    v-if="visible"
    name="link"
    :tooltip-text="$t('cardAction.link')"
    :aria-label="$t('label.actionMenu.link')"
    @click="handleLinkModalOpen(card)"
  >
    <SvgIcon name="action/link" width="20" height="20" />
  </ActionMenuItem>
</template>
