import { BoardType } from "@/model/baseTypes";
import { RelativeCoordinate } from "@/model/coordinates";

import { BaseBoard, ContextInfo } from "./BaseBoard";
import { PlanningBoardLocation } from "./location/PlanningBoardLocation";

/* eslint-disable @typescript-eslint/class-literal-property-style */
export abstract class BasePlanningBoard<
  T extends BoardType,
> extends BaseBoard<T> {
  get showRiskyLinks() {
    return true;
  }

  abstract location(
    pos: RelativeCoordinate | number,
    top?: number,
  ): PlanningBoardLocation;

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const actions: ContextInfo = {
      draw: this.showDraw,
      selection: {
        stickyMove: true,
        link: true,
      },
    };
    if (c) {
      const loc = this.location(c);
      actions.region = {
        name: loc.names().join(" "),
        overview: true,
      };
    }
    return actions;
  }
}
