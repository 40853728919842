import { isAlmSync } from "@/backend/Backend";
import { RelativeCoordinate } from "@/model/coordinates";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useConnectionStore } from "@/store/connection";
import { useStickyTypeStore } from "@/store/stickyType";

import { BaseBoard, ContextInfo } from "./BaseBoard";
import { TeamBoardLocation } from "./location/TeamBoardLocation";

/* eslint-disable @typescript-eslint/class-literal-property-style */
export class TeamBoard extends BaseBoard<"team"> {
  location(pos: RelativeCoordinate | number) {
    return TeamBoardLocation.of(this.board.iterations, pos);
  }

  positionalCardProperties(pos: RelativeCoordinate) {
    const loc = this.location(pos);
    return { iterationId: loc.iterationId, teamId: this.board.team.id };
  }

  get showPermanentLinks() {
    return useClientSettingsStore().permanentTeamLinks;
  }

  get showRiskyLinks() {
    return true;
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("team");
  }

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const loc = c ? this.location(c) : null;

    const actions: ContextInfo = {
      draw: this.showDraw,
      selection: {
        stickyMove: true,
        link: true,
      },
    };

    if (loc?.isIteration || loc?.isRisks()) {
      actions.region = {
        name: loc.name,
        arrange: true,
        overview: true,
        sync:
          useConnectionStore().alm &&
          isAlmSync() &&
          !!loc.boardIteration &&
          loc.boardIteration.state.status !== "syncing",
        zoom: true,
      };
    }
    return actions;
  }
}
