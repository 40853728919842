import { clamp } from "lodash-es";

import { boardAspect, fakeZoom, stickySize } from "@/model/Settings";
import {
  BoardCoordinate,
  Rectangle,
  RelativeCoordinate,
  WindowCoordinate,
  boardCoord,
  clientCoord,
  relativeCoord,
  windowCoord,
  windowSize,
} from "@/model/coordinates";
import { ZoomedSize } from "@/model/size";
import { useBoardStore } from "@/store/board";
import { useBoardSizeStore } from "@/store/boardSize";
import { useSidePanelStore } from "@/store/sidePanel";
import { useZoomStore } from "@/store/zoom";
import { getScrollbarSize, isScrollbarVisible } from "@/utils/dom/dom";

import { divided, minus, plus, times } from "./coordinates";

/**
 * Convert a window coordinate into a board coordinate.
 * @param e The window coordinate to be converted.
 * @param limitCoords If the coordinate should be checked and adjusted if needed
 *    - "input": Check the window coordinate parameter to be inside the window
 *    - "output": Check the resulting board coordinate to be inside the board
 * @param additionalOffsets A list of additional offsets to the given coordinate that should also be checked.
 */
export function windowToBoard(
  e: WindowCoordinate,
  limitCoords: "output" | "none" = "output",
  additionalOffsets: Array<{ offset: WindowCoordinate }> = [],
): BoardCoordinate {
  const boardSize = useBoardSizeStore().size;
  const zoom = useZoomStore().factor;
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const limited = limit(e);
  for (const additional of additionalOffsets) {
    combineDeltas(limited, limit(plus(e, times(additional.offset, zoom))));
  }
  return times(plus(limited, limited.delta), fakeZoom);

  function limit(inp: WindowCoordinate) {
    const c = boardCoord(
      (inp.x - boardSize.left) / zoom - xHalf * boardSize.width,
      (inp.y - boardSize.top) / zoom - yHalf * boardSize.height,
    );
    return {
      ...c,
      delta:
        limitCoords === "output" ? minus(insideBoard(c), c) : boardCoord(0, 0),
    };
  }

  function combineDeltas(
    target: { delta: BoardCoordinate },
    combine: { delta: BoardCoordinate },
  ) {
    if (Math.abs(combine.delta.x) > Math.abs(target.delta.x)) {
      target.delta.x = combine.delta.x;
    }
    if (Math.abs(combine.delta.y) > Math.abs(target.delta.y)) {
      target.delta.y = combine.delta.y;
    }
  }
}

function insideBoard(e: BoardCoordinate): BoardCoordinate {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const h = useBoardSizeStore().size.height;
  return boardCoord(
    clamp(e.x, 0, (1 - 2 * xHalf) * useBoardSizeStore().size.width),
    clamp(e.y, 0, (1 - yHalf * 2) * h),
  );
}

export function windowToRelative(e: WindowCoordinate): RelativeCoordinate {
  const boardSize = useBoardSizeStore().size;
  const zoom = useZoomStore().factor;
  const xBase = (e.x - boardSize.left + window.scrollX) / zoom;
  const yBase = (e.y - boardSize.top + window.scrollY) / zoom;
  return relativeCoord(xBase / boardSize.width, yBase / boardSize.height);
}

export function relativeToWindow(
  e: RelativeCoordinate,
  boardSize: ZoomedSize = {
    ...useBoardSizeStore().size,
    zoom: useZoomStore().factor,
  },
): WindowCoordinate {
  return windowCoord(
    boardSize.zoom * (e.x * boardSize.width) + boardSize.left,
    boardSize.zoom * (e.y * boardSize.height) + boardSize.top,
  );
}

export function boardSize(): Rectangle<WindowCoordinate> {
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  const h = useBoardSizeStore().size.height;
  const p0 = boardToWindow(boardCoord(0, 0));
  const p1 = boardToWindow(
    boardCoord(
      fakeZoom * (1 - 2 * xHalf) * useBoardSizeStore().size.width,
      fakeZoom * (1 - yHalf * 2) * h,
    ),
  );
  return {
    p0: windowCoord(Math.floor(p0.x), Math.floor(p0.y)),
    p1: windowCoord(Math.ceil(p1.x), Math.ceil(p1.y)),
  };
}

export function boardToWindow(e: BoardCoordinate): WindowCoordinate {
  const boardSize = useBoardSizeStore().size;
  const zoom = useZoomStore().factor;
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return windowCoord(
    (e.x / fakeZoom + xHalf * boardSize.width) * zoom + boardSize.left,
    (e.y / fakeZoom + yHalf * boardSize.height) * zoom + boardSize.top,
  );
}

export function boardToWindowSimple(c: BoardCoordinate): WindowCoordinate {
  return divided(c, fakeZoom) as unknown as WindowCoordinate;
}

export function windowToBoardSimple(c: WindowCoordinate): BoardCoordinate {
  return times(c, fakeZoom) as unknown as BoardCoordinate;
}

/**
 * Converts a board coordinate for the top-left corner of the card to
 * a relative coordinate for the center of the card
 *
 * @param coordinates The board coordinate for the top-left corner of the card
 * @returns The relative coordinate for the center of the card
 */
export function boardToRelative(
  coordinates: Partial<BoardCoordinate>,
): RelativeCoordinate {
  if (coordinates.x === undefined || coordinates.y === undefined) {
    return relativeCoord(0.5, 0.5);
  }
  const xHalf = useBoardStore().currentBoard().cardSize.x / 2;
  const yHalf = useBoardStore().currentBoard().cardSize.y / 2;
  return relativeCoord(
    coordinates.x / (useBoardSizeStore().size.width * fakeZoom) + xHalf,
    coordinates.y / (useBoardSizeStore().size.height * fakeZoom) + yHalf,
  );
}

/**
 * Direct conversion from board to relative coordinate
 */
export function boardToRelativeSimple(e: BoardCoordinate): RelativeCoordinate {
  return relativeCoord(
    e.x / (useBoardSizeStore().size.width * fakeZoom),
    e.y / (useBoardSizeStore().size.height * fakeZoom),
  );
}

/**
 * Converts a relative coordinate for the top-left corner of a card to a board coordinate
 * for the center of the card
 *
 * @param e The relative coordinate for the top-left corner of the card
 * @returns The board coordinate for the center of the card
 */
export function relativeToBoard(e: RelativeCoordinate): BoardCoordinate {
  const cardSize = useBoardStore().currentBoard().cardSize;
  return boardCoord(
    (e.x / cardSize.x + 0.5) * stickySize,
    (e.y / cardSize.y + 0.5) * stickySize,
  );
}

/**
 * Returns the given position of the center of the sticky, corrected to bring
 * it inside the board at the nearest edge if needed
 *
 * @param pos Position of the center of the card
 * @returns [corrected_position, delta_(offset_from_pos)]
 */
export function cardInsideBoardRelative(
  pos: RelativeCoordinate,
): [RelativeCoordinate, RelativeCoordinate] {
  const { x: width, y: height } = useBoardStore().currentBoard().cardSize;

  const newX = clamp(pos.x, width / 2, 1 - width / 2);
  const newY = clamp(pos.y, height / 2, 1 - height / 2);
  const delta = relativeCoord(newX - pos.x, newY - pos.y);

  return [relativeCoord(newX, newY), delta];
}

const scrollbarSize = getScrollbarSize();

/**
 * If the card is outside the board/viewport, moves it back inside the board.
 * This modifies the first input parameter (a) in place.
 *
 * @returns The offset by which the card was moved
 *          (explicitly as {offsetX: number, offsetY: number} to avoid confusion)
 */
export function cardInsideBoardAndViewport(
  pos: BoardCoordinate,
  size: { width: number; height: number },
): { offsetX: number; offsetY: number } {
  const { x: originalX, y: originalY } = pos;
  const boardSize = useBoardSizeStore().size;
  const zoom = useZoomStore().factor;

  // card inside board
  if (pos.x < 0) {
    pos.x = 0;
  }
  let x = pos.x + size.width - boardSize.width * fakeZoom;
  if (x > 0) {
    pos.x -= x;
  }
  if (pos.y < 0) {
    pos.y = 0;
  }
  let y = pos.y + size.height - boardSize.height * fakeZoom;
  if (y > 0) {
    pos.y -= y;
  }

  const scrollbarsVisible = isScrollbarVisible();
  const scrollbarX = scrollbarsVisible.x ? scrollbarSize : 0;
  const scrollbarY = scrollbarsVisible.y ? scrollbarSize : 0;

  const outline = 2; // the outline of the sticky note
  const gap = 2; // desired gap between sticky note and viewport

  // Adjust viewport size for any open side panels
  const leftPanelSize = useSidePanelStore().left.width;
  const rightPanelSize = useSidePanelStore().right.width;

  // card inside view port (and not hidden by side panels)
  const viewportLeft =
    window.scrollX - boardSize.left + leftPanelSize + outline + gap;
  const viewportRight =
    viewportLeft +
    window.innerWidth -
    leftPanelSize -
    rightPanelSize -
    scrollbarY -
    outline * 3 -
    gap;
  const viewportTop = window.scrollY + outline + gap;
  const viewportBottom =
    window.scrollY -
    boardSize.top -
    outline -
    scrollbarX -
    gap +
    window.innerHeight;

  x = pos.x * zoom - viewportLeft * fakeZoom;
  if (x < 0) {
    pos.x -= x / zoom;
  }
  x = (pos.x + size.width) * zoom - viewportRight * fakeZoom;
  if (x > 0) {
    pos.x -= x / zoom;
  }

  y = pos.y * zoom - viewportTop * fakeZoom;
  if (y < 0) {
    pos.y -= y / zoom;
  }
  y = (pos.y + size.height) * zoom - viewportBottom * fakeZoom;
  if (y > 0) {
    pos.y -= y / zoom;
  }

  return { offsetX: pos.x - originalX, offsetY: pos.y - originalY };
}

export function calcBoardSize(
  zoom: number = useZoomStore().factor,
): ZoomedSize {
  const margin = useBoardSizeStore().leftTopMargin;
  const windowSize = minus(
    visibleWindowSize(),
    useBoardSizeStore().totalVisibleMargin,
  );
  const boardSpace = minus(
    windowSize,
    useBoardSizeStore().totalScrollableMargin,
  );
  if (windowSize.x / windowSize.y < boardAspect) {
    // window is higher than boardAspect -> make board less high, add margin at top
    const height = windowSize.x / boardAspect;
    const width = windowSize.x;
    return {
      zoom,
      top: margin.y + Math.max(0, (boardSpace.y - height * zoom) / 2),
      left: margin.x,
      height,
      width,
    };
  }
  // window is broader than boardAspect -> make board less broad, add margin at left
  const width = windowSize.y * boardAspect;
  const height = windowSize.y;
  return {
    zoom,
    top: margin.y,
    left: margin.x + Math.max(0, (boardSpace.x - width * zoom) / 2),
    height,
    width,
  };
}

export function relativeClientCoord(elem: MouseEvent): RelativeCoordinate {
  return windowToRelative(clientCoord(elem));
}

export function visibleWindowSize() {
  return minus(windowSize(), useBoardSizeStore().totalWindowMargin);
}
