import { AlmTypeInfo } from "./baseTypes";
import color from "./color.module.scss";
import { Icon } from "./icon";
import { Team } from "./session";

// basic user information about the current user returned from the isAuthenticated endpoint
export interface TechnicalUser {
  id: string;
  company: string;
  role: Role;
  teams: Team[];
}

const roles = ["observer", "user", "admin", "planning_interval_admin"] as const;

export type Role = (typeof roles)[number];

export function technicalUser(
  id: string,
  company: string,
  role: string,
): TechnicalUser {
  return { id, company, role: asRole(role), teams: [] };
}

function asRole(s: string): Role {
  const r = s as Role;
  return roles.includes(r) ? r : "observer";
}

// extended user information about any user returned from the getUser endpoint
export type AuthUser = PersonalUser | AlmUser;

type UserType =
  | "regular" // regular user
  | "backend" // user running tasks in the backend
  | "alm" // alm tool user e.g. jira
  | "unknown" // could not load the user from backend
  | "loading" // user in loading state
  | "no"; // no user (for search by assignee)

export interface PersonalUser {
  type: UserType;
  id: string;
  name: string;
  email: string;
  imageUrl?: string;
  color: string;
  preferredLanguage: string;
  hash?: string;
  almMapping?: AlmMapping;
}

export type AlmMapping = "complete" | "pip-only" | "alm-only";

export interface AlmUser extends PersonalUser {
  type: "alm";
  iconName: Icon; // (e.g. the 'jira' icon)
}

interface OrgEntity {
  id: string;
  name: string;
}

export interface UserOrgEntities {
  team: OrgEntity[];
  art: OrgEntity[];
  train: OrgEntity[];
}

export const emptyUserOrgEntities: UserOrgEntities = {
  team: [],
  art: [],
  train: [],
};

export const baseUser = {
  email: "",
  color: color.menu, // Must be a valid hex color
  preferredLanguage: "en",
};

export function backendUser(): AuthUser {
  return {
    ...baseUser,
    type: "backend",
    id: "backend",
    name: "System",
  };
}

export function isBackendUserId(userId: string) {
  return userId === "backend";
}

/**
 * Generates a user representing the session's ALM tool
 * (since a session can only have one ALM, we know any
 * 'backend' events are coming from that ALM)
 */
export function almUser(almInfo: AlmTypeInfo): AlmUser {
  return {
    ...baseUser,
    type: "alm",
    id: "alm",
    name: almInfo.name,
    iconName: almInfo.colorIcon,
  };
}

export function isAlmUser(user: AuthUser): user is AlmUser {
  return user.type === "alm";
}

export function almOnlyUser(id: string, color: string): PersonalUser {
  const name = id.substring(0, 6) + " " + id.substring(id.length - 6);
  return {
    ...baseUser,
    type: "regular",
    almMapping: "alm-only",
    id,
    name,
    color,
  };
}

export function isUnknownUser(user: AuthUser | null) {
  return !user || user?.type === "unknown";
}
