import { Session } from "@/model/session";
import { i18n } from "@/translations/i18n";

import { isAfter, isBefore, plusDays } from "./date";
import { formatLongDate } from "./dateFormat";

const formatDate = formatLongDate;

function sessionStartDate(session: Session) {
  return session.startDate || session.iterations[0].start;
}

function sessionEndDate(session: Session) {
  return session.iterations[session.iterations.length - 1].end;
}

export function sessionDateRange(session: Session) {
  return i18n.global.t("date.range", {
    from: formatDate(sessionStartDate(session)),
    to: formatDate(plusDays(sessionEndDate(session), -1)),
  });
}

export function isSessionActive(session: Session) {
  return (
    isBefore(sessionStartDate(session), new Date()) &&
    isAfter(sessionEndDate(session), new Date())
  );
}
