<script setup lang="ts">
import { cardActions } from "@/action/cardActions";
import TeamSelector from "@/components/card/components/TeamSelector/TeamSelector.vue";
import { cardKey, cardMethodsKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import type { Team } from "@/model/session";
import { NO_TEAM_ID } from "@/store/team";
import { stickyNoteDependOnTeamChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const cardMethods = injectStrict(cardMethodsKey);

const props = defineProps<{
  trigger: "action-menu" | "attribute-chip"; // used for event tracking analytics
}>();

const handleChangeDependentOn = (team: Team | null) => {
  cardActions.setDepend("card", card.id, team?.id || NO_TEAM_ID);

  const isTeamFromSameArt = card.artId === team?.artId;

  trackEvent(
    stickyNoteDependOnTeamChanged(
      card.type.functionality,
      isTeamFromSameArt,
      props.trigger,
    ),
  );

  if (team?.id) {
    void cardMethods.animate?.("mirroring");
  }

  dropdown.close();
};
</script>

<template>
  <TeamSelector
    show-other-arts
    :title="$t('actionMenu.dependentOn')"
    @select="handleChangeDependentOn"
  />
</template>
