<script lang="ts" setup>
import { computed } from "vue";

import { linkActions } from "@/action/linkActions";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { BoardCoordinate } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useCardStore } from "@/store/card";
import { useDraggingStore } from "@/store/dragging";
import { getLinkTargetId, useLinkStore } from "@/store/link";
import { useSearchMenuStore } from "@/store/searchMenu";
import {
  stickyLinkRemoved,
  stickyNoteLinkDragStarted,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

import { findDeleteIconPos } from "./utils";

const props = defineProps<{ scale: number; offset: BoardCoordinate }>();

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
const isDragging = computed(() => !!useDraggingStore().dragging[card.id]);
const board = injectStrict(boardKey);
const links = computed(() => {
  return card.links.flatMap((link) => {
    const matches = useSearchMenuStore().matchesLinkStates(link, true);
    if (matches) {
      const linkTargetId = getLinkTargetId(card, link);
      const linkedCard = useLinkStore().boardCardByLink(
        linkTargetId,
        board.value,
      );
      if (linkedCard) {
        const deleteIconPos = findDeleteIconPos(
          cardMeta.pos,
          props.scale,
          linkedCard.meta.pos,
          props.offset,
        );
        if (deleteIconPos) {
          return { linkId: link.id, ...deleteIconPos, to: linkTargetId };
        }
      }
    }
    return [];
  });
});

const removeLink = (link: (typeof links.value)[number]) => {
  const storeLink = useLinkStore().linksById[link.linkId];
  const from = useCardStore().cards[storeLink.from];
  const to = useCardStore().cards[storeLink.to];
  trackEvent(
    stickyLinkRemoved(
      from.id,
      from.type.functionality,
      useBoardStore().currentBoard().type,
      to.id,
      to.type.functionality,
      "link-remove-button",
    ),
  );
  linkActions.remove("card", link.linkId);
};
const handleDragStarted = () => {
  trackEvent(stickyNoteLinkDragStarted(card.type.functionality));
};
</script>

<template>
  <template v-if="!isDragging && !cardMeta.isReadonly">
    <SvgIcon
      v-for="link in links"
      :key="link.linkId"
      name="action/delete-link"
      data-no-screenshot
      data-no-animate
      width="2em"
      height="2em"
      class="link-remove"
      :style="{ left: link.x + 'px', top: link.y + 'px' }"
      :title="$t('linkModal.removeLink')"
      @click.stop="removeLink(link)"
    />
  </template>

  <div
    v-if="!isDragging && !cardMeta.isReadonly"
    class="link-drag"
    data-no-screenshot
    data-no-animate
    aria-hidden="true"
    @pointerdown="handleDragStarted"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 17">
      <circle cx="5" cy="12" r="4.5" stroke="currentColor" />
      <path fill="currentColor" d="M4.5 0h1v8h-1z" />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors";

.link-remove {
  position: absolute;
  margin-left: -1em;
  margin-top: -1em;
  cursor: pointer;
}

.link-drag {
  pointer-events: all;
  cursor: grabbing;
  position: absolute;
  left: 45%;
  width: 20px;
  bottom: -38px;
  color: colors.$primary-color;

  svg {
    pointer-events: none;
  }

  .sticky-note-progress ~ & {
    bottom: -49px;
  }
}
</style>
