<template>
  <ModalLegacy
    set-return-focus="#flex_tab"
    class="flex-modal"
    :class="{ readonly: !canEdit }"
    :description="$t('label.flexModal.description')"
  >
    <ModalHeaderLegacy :title="$t('flexModal.collaborationCanvas')">
      <!-- Link to Cockpit -->
      <template v-if="canGoToCockpit" #default>
        <a
          target="_blank"
          :href="setupUrl"
          :aria-label="$t('label.flexModal.manage')"
          @keydown.enter.stop
        >
          {{ $t("flexModal.manage") }}
        </a>
      </template>

      <!-- Add Canvas Button -->
      <template v-if="canEdit" #action>
        <BaseButton
          v-if="canDuplicate"
          style="margin-right: 10px"
          color="grey"
          @click="addDuplicate"
          @keydown.enter.stop
          @keydown.space.stop
        >
          {{ $t("flexModal.duplicateCanvas") }}
        </BaseButton>
        <BaseButton
          ref="addCanvas"
          @click="addType"
          @keydown.enter.stop
          @keydown.space.stop
        >
          {{ $t("flexModal.addCanvas") }}
        </BaseButton>
      </template>

      <!-- Search -->
      <template #search>
        <SearchInputLegacy
          :items-per-row="3"
          :title="$t('label.flexModal.search')"
          @search="updateQuery"
          @select="select(selectedItem())"
          @mark="selectNext"
          @close="close('keyboard-shortcut')"
        />
      </template>
    </ModalHeaderLegacy>

    <div class="main">
      <!-- Categories -->
      <div class="categories">
        <div id="catDragImage">
          <div v-if="categoryDrag.data" class="drag-icon">
            <div class="category">
              <div />
              <div>{{ categoryDrag.data.name }}</div>
            </div>
          </div>
        </div>
        <div
          ref="categoriesListbox"
          v-keyboard-nav.soft-focus.ignore-trigger="{
            selector: '.category-elem',
          }"
          class="scrollable"
          role="listbox"
          :tabindex="categoriesListboxTabindex"
          :aria-label="$t('label.flexModal.categories')"
          @scroll="categoryDrag.data = null"
          @focus="focusOnCategories"
          @focusout="categoriesOnFocusout"
        >
          <div class="scroll-border">
            <FlexCategoryElem
              v-for="(cat, index) in categories"
              :key="cat.id"
              v-draggable="{
                enabled: canEdit,
                drag: categoryDrag,
                data: cat,
                image: 'catDragImage',
              }"
              class="category-elem"
              role="option"
              tabindex="-1"
              :cat="cat"
              :current="currentCategory.id === cat.id"
              :aria-selected="currentCategory.id === cat.id"
              :class="{
                'drag-board-over': cat.id === boardDrag.categoryId,
                'drag-category-over': cat === categoryDrag.data,
              }"
              :position="index"
              :list-length="categories.length"
              @click="setCurrentCategory(cat)"
              @delete="delCategory"
              @drag-start="startCategoryDrag(cat)"
              @move-up="moveCategory(cat, -1)"
              @move-down="moveCategory(cat, 1)"
              @pointerenter="dragOverCategory(cat)"
              @pointerleave="dragOverCategory(null)"
              @pointerup="dropCategory"
              @keydown.enter.stop
              @keydown.space.stop
              @focusout="handleCategoryFocusout(cat, $event)"
            />
          </div>
        </div>

        <!-- Add Category Button -->
        <div class="add-category">
          <BaseButton
            variant="outlined"
            color="grey"
            @click="addCat"
            @keydown.enter.stop
            @keydown.space.stop
          >
            {{ $t("flexModal.addCategory") }}
          </BaseButton>
        </div>
      </div>

      <!-- Canvases -->
      <div class="flex-boards">
        <div
          ref="scrollable"
          class="scrollable"
          :role="!!editBoardId ? 'region' : 'listbox'"
          :aria-label="$t('label.flexModal.canvases')"
          @wheel="stopScrollPropagation"
        >
          <div v-if="!loading && boards[''].length === 0" class="message">
            <div v-if="types.length === 0">
              <I18nT
                v-if="canGoToCockpit"
                keypath="flexModal.noCanvasConfigure"
                scope="global"
              >
                <template #cockpit>
                  <a target="_blank" :href="setupUrl">
                    {{ $t("flexModal.cockpit") }}
                  </a>
                </template>
              </I18nT>
              <span v-else>
                {{ $t("flexModal.noCanvasAsk") }}
              </span>
            </div>
            <div v-else>
              {{ $t("flexModal.noCanvasConfiguredMessage") }}
            </div>
          </div>
          <div v-if="dragHelp" class="message down">
            <div>{{ $t("flexModal.dragCanvases") }}</div>
          </div>
          <!-- Results count (screen reader only) -->
          <ScreenReaderMessage>
            {{ $t("label.resultsCount", { count: resultsCount }) }}
          </ScreenReaderMessage>
          <div
            v-for="(t, index) in found"
            :key="index"
            class="type"
            role="group"
            :aria-label="t.type || $t('label.flexModal.categoryGroup')"
          >
            <div class="head" :class="{ single: !t.type }">{{ t.type }}</div>
            <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
            <div
              v-for="board in t.boards"
              :id="optionId(board)"
              :key="board.id"
              :role="editBoardId === board.id ? 'region' : 'option'"
              class="board-elem"
              tabindex="0"
              :class="{ current: board === selectedItem() }"
              :aria-label="board.name"
              @click.stop="select(board)"
              @keydown.enter.stop="select(board)"
              @keydown.space.stop="select(board)"
              @focus="setSelectedBoard(board)"
              @focusout="handleBoardFocusout(board, $event)"
            >
              <FlexBoardElem
                v-draggable="{
                  enabled: canEdit,
                  drag: boardDrag,
                  data: board,
                  image: 'boardDragImage',
                }"
                :board="board"
                :dragging="!!boardDrag.data"
                :edit="editBoardId === board.id"
                @set-name="setName"
                @select="select"
                @edit="editMode"
                @deleted="onBoardDeleted(board)"
                @duplicated="loadBoards"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="boardDragImage" class="board-drag-image">
        <div />
        <FlexBackground
          v-if="boardDrag.data"
          :type="boardDrag.data.flexType.background"
          class="background"
        />
      </div>
    </div>

    <DeleteCategory
      v-if="categoryToDelete"
      class="dialog"
      @close="categoryToDelete = null"
      @delete="delCategory(categoryToDelete, true)"
    />

    <AddFlexBoard
      v-if="showTypes"
      class="dialog"
      :types="types"
      :cockpit-url="canGoToCockpit ? setupUrl : ''"
      @close="showTypes = false"
      @add="add"
    />

    <DuplicateFlexBoard
      v-if="showDuplicates"
      class="dialog"
      @close="showDuplicates = false"
      @duplicate="duplicate"
    />
  </ModalLegacy>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Ref, Watch } from "vue-property-decorator";

import { categoryActions } from "@/action/categoryActions";
import { flexActions } from "@/action/flexActions";
import {
  duplicateFlex,
  getStickyTypes,
  loadCategories,
  loadFlexBoards,
} from "@/backend/Backend";
import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import FlexBackground from "@/components/background/FlexBackground.vue";
import SearchInputLegacy from "@/components/input/SearchInputLegacy.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { Drag } from "@/components/utils/Gestures";
import { cockpitSessionUrl } from "@/components/utils/cockpitUrls";
import ScrollSupport from "@/mixins/ScrollSupport";
import SearchBase from "@/mixins/SearchBase";
import SelectList from "@/mixins/SelectList";
import { Board, BoardData } from "@/model/board";
import { windowCoord } from "@/model/coordinates";
import { Category, FlexType } from "@/model/flexboard";
import { currentRoute, goToBoard, replaceQuery } from "@/router/navigation";
import { useCategoryStore } from "@/store/category";
import { useFlexStore } from "@/store/flex";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { useSessionStore } from "@/store/session";
import { useStickyTypeStore } from "@/store/stickyType";
import { useToastStore } from "@/store/toast";
import { useUserStore } from "@/store/user";
import { i18n } from "@/translations/i18n";
import { ModalClosedTrigger } from "@/utils/analytics/events";
import { nextIndex } from "@/utils/list";
import { unique } from "@/utils/text/text";

import ModalHeaderLegacy from "../ModalHeaderLegacy.vue";
import ModalLegacy from "../ModalLegacy.vue";
import AddFlexBoard from "./AddFlexBoard.vue";
import DeleteCategory from "./DeleteCategory.vue";
import DuplicateFlexBoard from "./DuplicateFlexBoard.vue";
import FlexBoardElem from "./FlexBoardElem.vue";
import FlexCategoryElem from "./FlexCategoryElem.vue";
import { DragInfo, Draggable, initDrag } from "./draggable";

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface BoardsType {
  [category: string]: BoardType[];
}

interface BoardType {
  type: string;
  boards: Array<BoardData<"flex">>;
}

const allCategory = { id: "", name: "", boardIds: [] };

@Component({
  name: "FlexModal",
  components: {
    DeleteCategory,
    DuplicateFlexBoard,
    AddFlexBoard,
    SearchInputLegacy,
    ModalHeaderLegacy,
    FlexBoardElem,
    FlexCategoryElem,
    ModalLegacy,
    FlexBackground,
    BaseButton,
    ScreenReaderMessage,
  },
  directives: { Draggable },
})
export default class FlexModal extends mixins(
  ScrollSupport,
  SelectList,
  SearchBase,
) {
  @Ref("scrollable") readonly scrollElem!: HTMLElement;
  @Ref("categoriesListbox") readonly categoriesListboxElem!: HTMLElement;
  @Ref("addCanvas") readonly addCanvasElem!: HTMLElement;

  types = new Array<FlexType>();
  categoriesListboxTabindex: 0 | -1 = 0;
  currentCategory: Category = allCategory;
  showTypes = false;
  showDuplicates = false;
  categoryToDelete: Category | null = null;
  editBoardId: string | null = null;
  boardDrag: DragInfo<BoardData<"flex">> & {
    categoryId: string | null;
  } = initDrag({ categoryId: null });
  categoryDrag: DragInfo<Category> & {
    pos: number;
  } = initDrag(
    { pos: -1 },
    {
      start(drag: Drag<void>, data: Category) {
        return !!data.id;
      },
      move(drag: Drag<void>) {
        return windowCoord(0, drag.pos.y);
      },
    },
  );
  query = "";
  loading = false;
  upDownOffset = 3;
  leftRightOffset = 1;
  setupUrl = cockpitSessionUrl("flex");

  mounted() {
    this.selectList = [];
    loadCategories().then((cats) => {
      const urlCat = currentRoute().query.category;
      if (urlCat) {
        const cat = cats.find((c) => c.name === urlCat);
        if (cat) {
          this.setCurrentCategory(cat);
        }
      }
      useCategoryStore().set(cats);
      this.loadBoards();
    });
  }

  async loadBoards() {
    this.loading = true;
    try {
      useStickyTypeStore().stickyTypes = await getStickyTypes();
      const { flexTypes, flexBoards } = await loadFlexBoards();
      useFlexStore().setFlexModel(flexTypes, flexBoards);
      this.types = flexTypes;
    } finally {
      this.loading = false;
    }
  }

  @Watch("boards", { deep: true })
  boardsChanged() {
    this.updateMarks();
  }

  get boards(): BoardsType {
    const boards: BoardsType = { "": [] };
    for (const cat of useCategoryStore().getCategories) {
      boards[cat.id] = [];
    }
    useFlexStore().flexBoards.forEach((board) => addBoard("", board));
    useCategoryStore().getCategories.forEach((cat) =>
      cat.boardIds.forEach((id) =>
        addBoard(cat.id, useFlexStore().flexBoardById(id)),
      ),
    );
    return boards;

    function addBoard(categoryId: string, board?: BoardData<"flex">) {
      if (!board) {
        return;
      }
      const t = boards[categoryId].find((b) => b.type === board.flexType.name);
      if (t) {
        t.boards.push(board);
      } else {
        boards[categoryId].push({ type: board.flexType.name, boards: [board] });
      }
    }
  }

  get categories(): Category[] {
    return [
      { id: "", name: this.$t("flexModal.all"), boardIds: [] },
      ...useCategoryStore().getCategories,
    ];
  }

  get canGoToCockpit() {
    return useUserStore().isAllowed("cockpit");
  }

  get canEdit() {
    return useUserStore().isAllowed("edit");
  }

  get canDuplicate() {
    return useUserStore().isAllowed("duplicateFlex");
  }

  get dragHelp() {
    const types = this.found.length;
    const boards = types && this.found[0].boards.length;
    return !this.loading && types > 0 && types <= 1 && boards <= 3;
  }

  /**
   * Number of boards found (across all visible groups)
   */
  get resultsCount() {
    return this.found.flatMap((t) => t.boards).length;
  }

  addType() {
    if (this.canEdit) {
      this.showTypes = true;
    }
  }

  addDuplicate() {
    if (this.canDuplicate) {
      this.showDuplicates = true;
    }
  }

  async duplicate(board: Board) {
    try {
      await duplicateFlex(board.id, useSessionStore().session.current.id);
      useToastStore().show(/*$t*/ "flexModal.duplicated", { level: "ok" });
      this.loadBoards();
    } catch (e) {
      useToastStore().show(/*$t*/ "flexModal.duplicatedError", {
        level: "error",
      });
    }
  }

  add(type: FlexType) {
    flexActions
      .add(
        "modal",
        type,
        unique(i18n.global.t("flexModal.defaultCanvasName"), this.existName),
      )
      .then((board) => {
        if (this.currentCategory.id) {
          categoryActions.addBoard("modal", board.id, this.currentCategory.id);
        }
        this.editMode(board);
      });
    this.showTypes = false;
  }

  editMode(b?: BoardData<"flex">) {
    this.editBoardId = b?.id || null;
  }

  setName(board: BoardData<"flex">, name: string) {
    board.name = unique(name, this.existName);
    flexActions.update("modal", board.id, board.name);
  }

  existName(name: string) {
    return this.boards[""].some((t) => t.boards.some((b) => b.name === name));
  }

  updateQuery(query: string) {
    this.query = query;
    this.updateMarks();
  }

  updateMarks() {
    this.found = [];
    this.selectList = [];
    const q = this.query.toLowerCase();
    const filter = (b: BoardData<"flex">) =>
      q.length === 0 || b.name.toLowerCase().indexOf(q) >= 0;
    if (this.currentCategory.id) {
      this.updateMarksFlat(filter);
    } else {
      this.updateMarksTypes(filter);
    }
  }

  updateMarksFlat(filter: (b: BoardData<"flex">) => boolean) {
    this.found.push({ type: "", boards: [] });
    useFlexStore().flexBoards.forEach((board) => {
      if (
        this.currentCategory.boardIds.find((id) => id === board.id) &&
        filter(board)
      ) {
        this.found[0].boards.push(board);
        this.selectList.push(board);
      }
    });
  }

  updateMarksTypes(filter: (b: BoardData<"flex">) => boolean) {
    this.boards[this.currentCategory.id].forEach((board) => {
      const boards = board.boards.filter(filter);
      if (boards.length > 0) {
        this.found = this.found.concat({ type: board.type, boards });
        this.selectList = this.selectList.concat(boards);
        while (this.selectList.length % 3 !== 0) {
          this.selectList = this.selectList.concat(null);
        }
      }
    });
  }

  optionId(item: BoardData<"flex">) {
    return "flexboard-" + item.id;
  }

  selectNext(offset: number) {
    if (this.selectList.length > 0) {
      this.setSelected(nextIndex(this.selectList, this.selectIndex, offset));
      if (!this.selectedItem()) {
        this.selectNext(offset === 1 ? 1 : -1);
      }
    }
  }

  /**
   * Set the board as 'selected' in the context of SelectList
   * (allows us to keep SelectList in sync with tab-initiated focus)
   */
  setSelectedBoard(board: BoardData<"flex">) {
    const i = this.selectList.indexOf(board);
    if (i >= 0) {
      this.setSelected(i);
    }
  }

  /**
   * When the input blurs (eg. when user presses 'enter'), make sure the focus stays on the board element
   */
  handleBoardFocusout(board: BoardData<"flex">, event: FocusEvent) {
    // Only manage focus when we're not already moving focus to another element
    if (!event.relatedTarget) {
      const boardElem = document.getElementById(this.optionId(board));
      boardElem?.focus();
    }
  }

  /**
   * When the input blurs (eg. when user presses 'enter'), make sure the focus stays on the category element
   */
  handleCategoryFocusout(cat: Category, event: FocusEvent) {
    // Only manage focus when we're not already moving focus to another element
    if (!event.relatedTarget) {
      const catElem = document.getElementById(`flexModal-category-${cat.id}`);
      catElem?.focus();
    }
  }

  /**
   * When a canvas is deleted, move focus to the last canvas (instead of blurring completely)
   */
  onBoardDeleted(board: BoardData<"flex">) {
    const shownBoards = this.found.flatMap((t) => t.boards);
    const i = shownBoards.indexOf(board);
    const focusBoard =
      shownBoards[i === shownBoards.length - 1 ? i - 1 : i + 1];
    if (focusBoard) {
      const focusTargetId = this.optionId(focusBoard);
      document.getElementById(focusTargetId)?.focus();
    } else {
      this.addCanvasElem.focus();
    }
  }

  async select(board?: BoardData<"flex">) {
    // clear on pinned sticky notes on the flex board
    useLinkStore().removeAllMarks();

    if (board) {
      await loadIfNeeded(board.name);
      goToBoard({ type: "flex", name: board.name });
      this.close("confirm-button");
    }

    async function loadIfNeeded(name: string) {
      if (!useFlexStore().flexBoardByName(name)) {
        useStickyTypeStore().stickyTypes = await getStickyTypes();
        const { flexTypes, flexBoards } = await loadFlexBoards();
        useFlexStore().setFlexModel(flexTypes, flexBoards);
      }
    }
  }

  setCurrentCategory(cat: Category) {
    this.currentCategory = cat;
    this.updateMarks();
    replaceQuery({ category: cat.id ? cat.name : undefined });
  }

  /**
   * When focus moves to the listbox, focus on the first child
   */
  focusOnCategories() {
    const target =
      this.categoriesListboxElem?.querySelector(".current") ||
      this.categoriesListboxElem?.querySelector(".category-elem");

    (target as HTMLElement)?.focus();

    // Don't allow focus to tab to the parent while focused on a child
    this.categoriesListboxTabindex = -1;
  }

  /**
   * Make the listbox focusable when none of its children are in focus
   */
  categoriesOnFocusout(event: FocusEvent) {
    if (!this.categoriesListboxElem.contains(event.relatedTarget as Node)) {
      this.categoriesListboxTabindex = 0;
    }
  }

  addCat() {
    categoryActions.add("modal", this.$t("flexModal.newCategory"));
  }

  delCategory(cat: Category | null, force?: boolean) {
    if (!cat) return;

    if (!force && cat.boardIds.length > 0) {
      this.categoryToDelete = cat;
    } else {
      categoryActions.remove("modal", cat.id);
      this.setCurrentCategory(allCategory);
      document.querySelector<HTMLElement>(".category-elem")?.focus();
    }
  }

  /**
   * Reorder the category by the given distance
   */
  moveCategory(cat: Category, distance: number) {
    const oldPos = useCategoryStore().getCategories.findIndex(
      (c) => c.id === cat.id,
    );
    const newPos = oldPos + distance;

    // Check that the new value makes sense
    if (newPos < 0 || newPos >= useCategoryStore().getCategories.length) {
      return;
    }

    categoryActions.update("modal", cat.id, {
      position: newPos,
    });

    // Return focus to the 'recreated' category element
    setTimeout(() => {
      document.getElementById(`flexModal-category-${cat.id}`)?.focus();
    }, 10);
  }

  startCategoryDrag(cat: Category) {
    this.categoryDrag.pos = useCategoryStore().getCategories.findIndex(
      (c) => c.id === cat.id,
    );
  }

  dragOverCategory(cat: Category | null) {
    if (this.boardDrag.data) {
      this.dragBoardOverCategory(this.boardDrag.data, cat);
    }
    if (this.categoryDrag.data && cat && this.categoryDrag.data !== cat) {
      this.dragCategoryOverCategory(this.categoryDrag.data, cat);
    }
  }

  dragBoardOverCategory(board: BoardData<"flex">, cat: Category | null) {
    if (cat === null) {
      this.boardDrag.categoryId = null;
    } else if (cat.id !== this.currentCategory.id) {
      const boardIsInCat = cat.boardIds.find((id) => id === board.id);
      if (!boardIsInCat || !cat.id) {
        this.boardDrag.categoryId = cat.id;
      }
    }
  }

  dragCategoryOverCategory(fromCat: Category, toCat: Category) {
    const newPos = useCategoryStore().getCategories.findIndex(
      (c) => c.id === toCat.id,
    );
    if (newPos >= 0 && this.categoryDrag.pos !== newPos) {
      this.categoryDrag.pos = newPos;
      categoryActions.update("modal", fromCat.id, {
        position: newPos,
      });
    }
  }

  dropCategory() {
    if (this.boardDrag.data) {
      categoryActions.removeBoard(
        "modal",
        this.boardDrag.data.id,
        this.currentCategory.id,
      );
      categoryActions.addBoard(
        "modal",
        this.boardDrag.data.id,
        this.boardDrag.categoryId!,
      );
      this.boardDrag.categoryId = null;
    }
  }

  close(trigger: ModalClosedTrigger) {
    useModalStore().hide(trigger);
  }
}
</script>

<style lang="scss">
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

.flex-modal {
  a {
    outline: revert;
  }

  .main {
    border-top: 3px solid colors-old.$menu-color;
    margin-top: 20px;
    padding-bottom: 80px;
    height: 100%;
    display: flex;
    flex-direction: row;

    .categories {
      position: relative;
      flex-basis: auto;
      flex-grow: 1;
      margin-top: 1em;
      border-right: 3px solid colors-old.$menu-color;
      padding-right: 1em;

      .scrollable {
        margin: 0 0 3em -1em;

        .scroll-border {
          padding: 0 1.5em;
        }
      }

      .drag-icon {
        width: 100%;
        pointer-events: none;
        position: absolute;
        padding-left: 0.4em;
        background-color: colors-old.$back-color;
        z-index: z-index.$low;
      }
    }

    .flex-boards {
      position: relative;
      flex-basis: auto;
      flex-grow: 4;

      .scrollable {
        margin: 1em 0 0 1em;
      }
    }

    .board-drag-image {
      padding-bottom: 20px;
      filter: opacity(0.5);
    }

    .type {
      .head {
        overflow: hidden;

        &.single {
          border-bottom-color: colors-old.$back-color;
        }
      }

      .board-elem {
        padding: 2%;
        width: 33%;
        display: inline-block;
        cursor: pointer;

        .flex-board-elem {
          position: relative;
        }

        // Hide icon buttons *visually* until hover/focus
        // (they're still accessible to screen readers)
        .icons {
          opacity: 0;
        }

        &:hover .icons,
        &:focus-within .icons {
          opacity: 1;
        }
      }
    }
  }

  .text {
    position: relative;

    .check {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .background {
    border: 1px solid colors-old.$menu-color;
    width: 100%;
    height: 100%;
  }

  .add-category {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;

    & > div {
      display: inline-block;
    }
  }

  .bottom {
    font-size: 75%;
    z-index: z-index.$low;
    text-transform: uppercase;

    & > div,
    & > .item {
      width: 50%;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
    }

    img {
      height: 1em;
      margin-right: 0.5em;
    }
  }

  .dialog {
    position: absolute;
    left: 0;
    top: 0;
    background-color: colors-old.$hover-color;
    height: 100%;
    width: 100%;
    z-index: z-index.$low;

    .delete-dialog {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 200px;
      width: 300px;
      margin-left: -150px;
      margin-top: -100px;
      border: 2px solid colors-old.$menu-color;
      background-color: colors-old.$back-color;
      text-align: center;
      padding: 1em;
      white-space: pre-wrap;

      .action svg {
        height: 1.5em;
        vertical-align: middle;
      }
    }

    .type-dialog {
      position: absolute;
      left: 5%;
      top: 15%;
      height: 80%;
      width: 90%;
      border: 2px solid colors-old.$menu-color;
      background-color: colors-old.$divider-color;

      .scrollable {
        margin: 5em 0 0;
        overflow-y: scroll;

        /* otherwise, hover icons cause scrollbar to become visible */

        .flex-button {
          display: inline-block;
          width: 33%;
          padding: 2%;
          text-align: center;
          outline: revert;
        }
      }

      .type-name {
        width: 90% !important;
        height: 3em;
        overflow: hidden;
      }

      .close-button {
        position: absolute;
        right: 1em;
        top: 1em;
      }
    }
  }

  .message {
    text-align: center;
    width: 100% !important;
    margin: 1em 0;
    font-size: 150%;

    &.down {
      position: absolute;
      bottom: 2em;
    }

    #add-canvas-title {
      color: colors.$text;
    }
  }
}
</style>
