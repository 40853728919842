<template>
  <div
    v-keyboard-nav-local.soft-focus-initial.ignore-trigger="{
      selector: '.board-tab-item',
    }"
    role="tablist"
    aria-orientation="vertical"
    class="boards-item"
  >
    <ActionButton
      v-if="hasSolutionBacklogBoard"
      id="solution_backlog_tab"
      :action="actions.goToSolutionBacklog"
      :activated="current === 'solution_backlog'"
      :tabindex="current === 'solution_backlog' ? 0 : -1"
      class="board-tab-item"
      aria-controls="solution_backlog_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
    <ActionButton
      v-if="hasSolutionBoard"
      id="solution_tab"
      :action="actions.goToSolution"
      :activated="current === 'solution'"
      :tabindex="current === 'solution' ? 0 : -1"
      class="board-tab-item"
      aria-controls="solution_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
    <MenuItemDelimiter v-if="hasSolutionBoard" horizontal />
    <ActionButton
      id="backlog_tab"
      :action="actions.goToBacklog"
      :activated="current === 'backlog'"
      :tabindex="current === 'backlog' ? 0 : -1"
      class="board-tab-item"
      aria-controls="backlog_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
    <ActionButton
      id="program_tab"
      :action="actions.goToProgram"
      :activated="current === 'program'"
      :tabindex="current === 'program' ? 0 : -1"
      class="board-tab-item"
      aria-controls="program_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
    <ActionButton
      id="objectives_tab"
      :action="actions.goToObjectives"
      :activated="current === 'objectives'"
      :tabindex="current === 'objectives' ? 0 : -1"
      class="board-tab-item"
      aria-controls="objectives_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
    <ActionButton
      id="risk_tab"
      :action="actions.goToRisk"
      :activated="current === 'risk'"
      :tabindex="current === 'risk' ? 0 : -1"
      class="board-tab-item"
      aria-controls="risk_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
    <ActionButton
      id="team_tab"
      :action="actions.goToTeam"
      :activated="current === 'team'"
      :tabindex="current === 'team' ? 0 : -1"
      class="board-tab-item"
      aria-controls="team_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
    <ActionButton
      id="flex_tab"
      :action="actions.goToCanvas"
      :activated="current === 'flex'"
      :tabindex="current === 'flex' ? 0 : -1"
      class="board-tab-item"
      aria-controls="flex_panel"
      role="tab"
      position="right"
      trigger="sidebar-menu"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { RouteLocationNormalizedLoaded } from "vue-router";

import { navigationActions } from "@/action/navigationActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { useBoardsStore } from "@/store/boards";

import MenuItemDelimiter from "../../MenuItemDelimiter.vue";

@Component({
  components: { ActionButton, MenuItemDelimiter },
  directives: { keyboardNavLocal: keyboardNavigation() },
})
export default class BoardsItem extends mixins(ShortcutUser, EventBusUser) {
  current: string | null = null;
  actions = navigationActions;

  @Watch("$route", { immediate: true })
  routeChanged(route: RouteLocationNormalizedLoaded) {
    //ok as board param is not repeatable
    this.current = route.params.board as string;
  }

  created() {
    Object.values(this.actions).forEach((action) =>
      this.globalActionShortcut(action),
    );
  }

  get hasSolutionBacklogBoard() {
    return useBoardsStore().hasSolutionBacklogBoard;
  }

  get hasSolutionBoard() {
    return useBoardsStore().hasSolutionBoard;
  }
}
</script>

<style lang="scss" scoped>
.boards-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
