import { RelativeCoordinate } from "@/model/coordinates";
import { useArtStore } from "@/store/art";
import { useStickyTypeStore } from "@/store/stickyType";
import { i18n } from "@/translations/i18n";

import { BasePlanningBoard } from "./BasePlanningBoard";
import { PlanningBoardLocation } from "./location/PlanningBoardLocation";

/* eslint-disable @typescript-eslint/class-literal-property-style */
export class SolutionBoard extends BasePlanningBoard<"solution"> {
  get groups() {
    const milestoneEvents = {
      id: "",
      name: i18n.global.t("programBoard.milestoneEvents"),
    };
    const emptyArt = { id: "", name: "" };
    const arts = useArtStore().arts;
    return [milestoneEvents, ...arts, emptyArt];
  }

  location(pos: RelativeCoordinate | number, top?: number) {
    return PlanningBoardLocation.ofArts(this.groups, pos, top);
  }

  positionalCardProperties(pos: RelativeCoordinate) {
    const loc = this.location(pos);
    return { iterationId: loc.iterationId, artId: loc.groupId };
  }

  get showPermanentLinks() {
    return true;
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("solution");
  }

  get creatableStickyTypes() {
    // we can't select a team on the solution-planning board, hence we should not allow creating team board stickies (dependencies)
    // also we should not allow creating art backlog stickies on the solution-planning board, because we do not automatically create
    // the origin on the art backlog board (not implemented in the backend)
    return this.possibleStickyTypes.filter(
      (type) => type.origin !== "team" && type.origin !== "backlog",
    );
  }
}
