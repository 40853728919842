import { defineStore } from "pinia";

import { removeBoardSubscriptions } from "@/backend/Backend";
import { BaseBoard, ContextInfo } from "@/components/board/BaseBoard";
import { boardOf } from "@/components/board/boardFactory";
import { BoardLocation } from "@/components/board/location/BoardLocation";
import { sendStickyNoteAction } from "@/composables/useEventBus";
import { BoardType, Id } from "@/model/baseTypes";
import { Board } from "@/model/board";
import { BoardCard, Card } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { Group } from "@/model/session";
import { StickyType } from "@/model/stickyType";
import {
  startUserHeartbeat,
  stopUserHeartbeat,
} from "@/services/heartbeat.service";

import { useBoardsStore } from "./boards";
import { usePointerStore } from "./pointer";
import { useUsersOnBoardStore } from "./usersOnBoard";

class BoardNotFoundError extends Error {}

export const useBoardStore = defineStore("board", {
  state: () => {
    return {
      board: undefined as Board | undefined,
      activeCardId: null as string | null,
      enlargedStickyNoteId: null as string | null,
      magnifying: false,
    };
  },
  getters: {
    baseBoard: (state): BaseBoard<BoardType> => boardOf(state.board),
    positionalCardProperties() {
      return (pos: RelativeCoordinate): Partial<Card> =>
        this.baseBoard.positionalCardProperties(pos);
    },
    boardLocation() {
      return (pos: RelativeCoordinate | number, top?: number): BoardLocation =>
        this.baseBoard.location(pos, top);
    },
    boardGroups(): Group[] {
      return this.baseBoard.groups;
    },
    // sticky types that can possibly appear on the board
    possibleStickyTypes(): StickyType[] {
      return this.baseBoard.possibleStickyTypes;
    },
    // sticky types that can be used to create a new sticky note
    creatableStickyTypes(): StickyType[] {
      return this.baseBoard.creatableStickyTypes;
    },
    showPermanentLinks(): boolean {
      return this.baseBoard.showPermanentLinks;
    },
    showRiskyLinks(): boolean {
      return this.baseBoard.showRiskyLinks;
    },
    contextActions() {
      return (c?: RelativeCoordinate): ContextInfo =>
        this.baseBoard.contextActions(c);
    },
    hasCurrentBoard: (state) => {
      return typeof state.board !== "undefined";
    },
    currentBoard: (state) => (): Board => {
      if (state.board) {
        return state.board;
      }
      throw new BoardNotFoundError("No current board is set");
    },
    boardId() {
      return () => this.currentBoard().id;
    },
    isCurrentBoardFluid: (state) => {
      return state.board?.type !== "objectives";
    },
    boardLocationFromIndex() {
      return (index: string[]): BoardLocation =>
        this.boardLocation(parseInt(index[0], 10), parseInt(index[1], 10));
    },
    selectedStickyIds: (state) => {
      return Object.keys(state.board?.selected || {});
    },
    selectedStickies(): BoardCard[] {
      return this.selectedStickyIds.map((id) => this.currentBoard().cards[id]);
    },
    selectedOrActiveStickies(): BoardCard[] {
      if (this.selectedStickyIds.length > 0) {
        return this.selectedStickies;
      }
      const active = this.activeCard;
      return active ? [active] : [];
    },
    isStickySelected: (state) => {
      return (cardId: string) => cardId in (state.board?.selected || {});
    },
    isStickyIdSelectedOrActive: (state) => {
      return (id: string) =>
        id in (state.board?.selected || {}) || state.activeCardId === id;
    },
    areMultipleStickiesSelected() {
      return this.selectedStickyIds?.length > 1;
    },
    activeCard(): BoardCard | null {
      const activeCardId = this.activeCardId;
      return activeCardId ? this.currentBoard().cards[activeCardId] : null;
    },
  },
  actions: {
    switchBoard(board?: Board) {
      const newBoard =
        board || (this.board && useBoardsStore().boardByType(this.board.type));
      const res = { board: this.board, cards: new Array<BoardCard>() };
      if (newBoard && newBoard.id !== this.board?.id) {
        if (this.hasCurrentBoard) {
          res.cards = this.leaveBoard().cards;
        }
        this.board = newBoard;
        startUserHeartbeat();
      }
      return res;
    },
    leaveBoard() {
      const board = this.currentBoard();
      this.setActiveCardId(null);
      const cards = [];
      for (const id in board.cards) {
        const card = board.cards[id];
        if (card.meta.editing) {
          cards.push(card);
          card.meta.editing = false;
        }
      }
      stopUserHeartbeat();
      usePointerStore().reset();
      useUsersOnBoardStore().usersOnBoard = [];
      removeBoardSubscriptions();
      return { board, cards };
    },
    selectSticky(stickyId: string) {
      this.currentBoard().selected[stickyId] = true;
    },
    unselectSticky(stickyId: string, board?: Board) {
      delete (board ?? this.currentBoard()).selected[stickyId];
    },
    setActiveCardId(cardId: string | null) {
      if (this.activeCardId && cardId === null) {
        sendStickyNoteAction(this.activeCardId, { action: "changed" });
      }
      this.activeCardId = cardId;
    },
    setEnlargedStickyNoteId(id: string | null) {
      this.enlargedStickyNoteId = id;
    },
    setActiveCard(e: Id & { active: boolean }) {
      // don't clear active card if a different one is active
      if (!e.active && this.activeCardId !== e.id) {
        return;
      }
      this.setActiveCardId(e.active ? e.id : null);
    },
  },
});
