<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import ArtSelector from "@/components/card/components/ArtSelector/ArtSelector.vue";
import { cardKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import type { Art } from "@/model/session";
import { NO_ART_ID, useArtStore } from "@/store/art";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const arts = computed(() => useArtStore().arts);
const selected = computed(() =>
  arts.value.find((art) => art.id === card.artId),
);

const handleArtChange = (art: Art | null) => {
  cardActions.setArt("card", card.id, art?.id || NO_ART_ID);
  dropdown.close();
};
</script>

<template>
  <ArtSelector
    class="change-art"
    :selected="selected"
    :arts="arts"
    @select="handleArtChange"
  />
</template>
