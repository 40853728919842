import { RelativeCoordinate } from "@/model/coordinates";
import { useStickyTypeStore } from "@/store/stickyType";

import { BaseBoard, ContextInfo } from "./BaseBoard";
import { RiskBoardLocation } from "./location/RiskBoardLocation";

export class RiskBoard extends BaseBoard<"risk"> {
  location(pos: RelativeCoordinate | number) {
    return RiskBoardLocation.of(pos);
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("risk");
  }

  get creatableStickyTypes() {
    // we can’t select a team on the risk board, hence we should not allow creating team board stickies on the risk board
    return this.possibleStickyTypes.filter((type) => type.origin !== "team");
  }

  contextActions(c?: RelativeCoordinate): ContextInfo {
    const actions: ContextInfo = {
      draw: this.showDraw,
      selection: {
        stickyMove: true,
        link: true,
      },
    };
    if (c) {
      const loc = this.location(c);
      actions.region = {
        name: loc.name,
        arrange: true,
        overview: true,
        zoom: true,
      };
    }
    return actions;
  }
}
