import { isAlmSync } from "@/backend/Backend";
import ConfirmSyncBacklogModal from "@/components/modal/ConfirmSyncBacklogModal.vue";
import { BoardType } from "@/model/baseTypes";
import { RelativeCoordinate } from "@/model/coordinates";
import { useConnectionStore } from "@/store/connection";
import { useModalStore } from "@/store/modal";

import { BaseBoard, ContextInfo } from "./BaseBoard";

export abstract class BaseBacklogBoard<
  T extends BoardType,
> extends BaseBoard<T> {
  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return {
      syncProgramBacklog:
        isAlmSync() && useConnectionStore().alm
          ? () => useModalStore().open(ConfirmSyncBacklogModal)
          : undefined,
      draw: this.showDraw,
      selection: {
        link: true,
        mirror: true,
        team: true,
      },
    };
  }
}
