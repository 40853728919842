<script setup lang="ts">
import { computed, onMounted, provide, ref } from "vue";

import { drawActions } from "@/action/drawActions";
import ActionMenu from "@/components/ActionMenu/ActionMenu.vue";
import { boardKey } from "@/components/board/injectKeys";
import * as Gestures from "@/components/utils/Gestures";
import { key } from "@/components/utils/Shortcuts";
import { useNativeEvents } from "@/composables/useNativeEvents";
import {
  relativeClientCoord,
  windowToRelative,
} from "@/math/coordinate-systems";
import {
  clampRelativeCoord,
  distance2,
  isHorizontal,
  minus,
  nearestLinePoint,
  plus,
} from "@/math/coordinates";
import { snapDistance } from "@/model/Settings";
import {
  CoordinateComponent,
  Line,
  LineComponent,
  RelativeCoordinate,
  WindowCoordinate,
  clientCoord,
  oppositeLineComponent,
  relativeCoord,
} from "@/model/coordinates";
import { Shape } from "@/model/shape";
import cssValue from "@/model/variable.module.scss";
import { useBoardStore } from "@/store/board";
import { useContextMenuStore } from "@/store/contextMenu";
import { Selected, useDrawStore } from "@/store/draw";
import {
  drawShapeCreated,
  drawShapeDeleted,
  drawShapeSelected,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { terminateEvent } from "@/utils/dom/dom";

import DrawContextMenu from "./components/DrawContextMenu.vue";
import ShapeActionMenu from "./components/ShapeActionMenu.vue";
import TextLine from "./components/TextLine.vue";
import TextRect from "./components/TextRect.vue";
import { useDrawActionMenu } from "./composables/useDrawActionMenu";
import { usePointerPosition } from "./composables/usePointerPosition";
import { useSelectionShape } from "./composables/useSelectionShape";
import { useSvgShapes } from "./composables/useSvgShapes";
import { shapeKey } from "./injectKeys";

const width = 1600;
const height = 900;

provide(boardKey, ref(useBoardStore().currentBoard()));

const root = ref<HTMLElement>();

const { line, rectAroundPoint, rectAlongLine } = useSvgShapes(width, height);
const anchor = rectAroundPoint(8);
const editableLine = rectAlongLine(2);

const { selectionRect } = useSelectionShape(width, height);

const { pointerPos } = usePointerPosition(root);

const { addEventListener } = useNativeEvents();

onMounted(() => {
  addEventListener(document, "keydown", keyHandler, true);
  Gestures.dragScroll(root.value!);
});

type Anchor = LineComponent | "line";

const selected = computed(() => useDrawStore().selected);
const shape = computed(() => selected.value?.shape);
const board = computed(() => useBoardStore().currentBoard());
const active = computed(() => useDrawStore().active);
const zIndex = computed(() =>
  active.value ? board.value.maxZ + 1 : cssValue.zIndexBoard,
);
const pointerEvents = computed(() => (active.value ? "all" : "none"));

provide(shapeKey, shape);
const actionMenu = useDrawActionMenu(shape);

function keyHandler(e: KeyboardEvent) {
  if (active.value && !actionMenu.isOpen) {
    if (
      key(drawActions.removeShape.data.shortcut!.key)(e) ||
      e.key === "Delete"
    ) {
      terminateEvent(e);
      if (selected.value) {
        drawActions.removeShape("keyboard", selected.value.shape.id);
      }
      trackEvent(drawShapeDeleted("keyboard-shortcut"));
    }
  }
}

function shapeDown(shape: Shape, anchor: Anchor, e: PointerEvent) {
  trackEvent(drawShapeSelected());
  useDrawStore().selected = {
    shape,
    anchor,
    offset: {
      p0: minus(shape.p0, relativeClientCoord(e)),
      p1: minus(shape.p1, relativeClientCoord(e)),
    },
  };
}

function up(e: PointerEvent) {
  if (selected.value?.anchor && !isDragOverMenu(e)) {
    drawActions.editShape("mouse", selected.value.shape);
    selected.value.anchor = null;
  }
}

function isDragOverMenu(e: PointerEvent) {
  return (
    e.type === "pointerleave" && actionMenu.isInMenu(e.relatedTarget as Node)
  );
}

function move(event: PointerEvent) {
  if (selected.value?.anchor) {
    moveShape(selected.value.shape, selected.value, relativeClientCoord(event));
    event.stopPropagation();
    actionMenu.updateMenu();
  } else if (canStartShape() && pointerPos.down) {
    startShape(pointerPos.down);
  }
}

function moveShape(shape: Shape, selected: Selected, pos: RelativeCoordinate) {
  if (selected.anchor === "line") {
    shape.p0 = clampRelativeCoord(plus(selected.offset.p0, pos));
    shape.p1 = clampRelativeCoord(plus(selected.offset.p1, pos));
  } else if (selected.anchor) {
    const fixPoint = shape[oppositeLineComponent(selected.anchor)];
    shape[selected.anchor] =
      Math.abs(fixPoint.x - pos.x) < Math.abs(fixPoint.y - pos.y)
        ? relativeCoord(fixPoint.x, pos.y)
        : relativeCoord(pos.x, fixPoint.y);
  }

  for (const other of board.value.shapes) {
    snap(shape, other);
  }
}

function snap(shape: Shape, other: Shape) {
  if (
    other.id !== shape.id &&
    other.type === "line" &&
    isHorizontal(shape) !== isHorizontal(other)
  ) {
    snapPoint("p0");
    snapPoint("p1");
  }

  function snapPoint(point: LineComponent) {
    const n = nearestLinePoint(shape[point], other);
    if (distance2(n.point, shape[point]) < snapDistance) {
      const comp = components(shape);
      if (n.t < 0 || n.t > 1) {
        // near the end of the other line -> snap the whole line
        shape.p0[comp.fix] = n.point[comp.fix];
        shape.p1[comp.fix] = n.point[comp.fix];
      }
      // snap the near point only
      shape[point][comp.flex] = n.point[comp.flex];
    }
  }
}

function components(line: Line<RelativeCoordinate>): {
  fix: CoordinateComponent;
  flex: CoordinateComponent;
} {
  return isHorizontal(line) ? { fix: "y", flex: "x" } : { fix: "x", flex: "y" };
}

function canStartShape() {
  return useDrawStore().tool === "line" && !selected.value?.anchor;
}

function startShape(pos: WindowCoordinate) {
  const shape: Shape = {
    id: "",
    type: "line",
    fixed: false,
    p0: windowToRelative(pos),
    p1: windowToRelative(pos),
  };
  useDrawStore().selected = { shape, anchor: "p1" };
  drawActions.addShape("mouse", shape).then((shape) => {
    selected.value!.shape = shape;
  });
  trackEvent(drawShapeCreated());
  trackEvent(drawShapeSelected());
}

function isSelected(shape: Shape) {
  return shape.id === selected.value?.shape.id;
}

function contextMenu(e: MouseEvent) {
  useContextMenuStore().open(DrawContextMenu, {
    position: clientCoord(e),
  });
}
</script>

<template>
  <ActionMenu :controller="actionMenu">
    <ShapeActionMenu />
  </ActionMenu>
  <svg
    ref="root"
    class="draw-layer"
    :class="{ active }"
    :viewBox="`0 0 ${width} ${height}`"
    preserveAspectRatio="none"
    stroke="currentColor"
    :style="{ zIndex, pointerEvents }"
    aria-hidden="true"
    @pointermove.capture="move"
    @pointerup="up"
    @pointerleave="up"
    @contextmenu="contextMenu"
  >
    <g
      v-for="shape in board.shapes"
      :id="`shape-${shape.id}`"
      :key="shape.id"
      :class="{ selected: isSelected(shape) }"
    >
      <TextLine
        v-if="!active || shape.fixed"
        v-bind="{ ...line(shape), ...shape.label }"
      />
      <template v-else>
        <TextRect
          class="line"
          v-bind="{ ...editableLine(shape), ...shape.label }"
          @pointerdown="shapeDown(shape, 'line', $event)"
        />
        <rect
          class="anchor"
          v-bind="anchor(shape.p0)"
          @pointerdown="shapeDown(shape, 'p0', $event)"
        />
        <rect
          class="anchor"
          v-bind="anchor(shape.p1)"
          @pointerdown="shapeDown(shape, 'p1', $event)"
        />
      </template>
    </g>
    <rect v-if="!!selectionRect" v-bind="selectionRect" class="selection" />
  </svg>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/variables/a11y" as colors-a11y;
@use "sass:color";

.draw-layer {
  position: absolute;
  width: 100% * variables.$fake-zoom;
  height: 100% * variables.$fake-zoom;
  fill: none;

  // this is not inside line element because it would break the screenshot function
  // if different elements have different stroke/fill make sure screenshot still works
  color: colors-a11y.$board-border;
  stroke-width: 2;

  &.active {
    background-color: color.change(colors-old.$back-color, $alpha: 0.5);

    line {
      stroke: colors-old.$menu-color;
    }
  }

  text {
    fill: colors-a11y.$board-border;
    stroke-width: 0.7;
    stroke-linejoin: round; // avoid strange buggy effects rendering text
    font-size: 8.5px;
  }

  rect {
    &.line {
      cursor: move;
      fill: colors-old.$menu-color;
      stroke: transparent;
      stroke-width: 8;
    }

    &.anchor {
      cursor: move;
      stroke: colors-old.$menu-color;
      stroke-width: 2;
      rx: 2;
    }
  }

  g.selected {
    text {
      stroke: colors-old.$menu-color;
      fill: colors-old.$menu-color;
    }

    rect.anchor {
      fill: colors-old.$menu-color;
    }
  }

  .selection {
    stroke-width: 1;
    color: colors-old.$primary-color;
  }
}
</style>
