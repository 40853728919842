import { mapValues } from "lodash-es";

import { captureMessage } from "@/error/sentry";
import { times } from "@/math/coordinates";
import CardFlag from "@/model/CardFlag";
import { zoomFactor } from "@/model/Settings";
import { Color, IdMap } from "@/model/baseTypes";
import { BoardIteration } from "@/model/board";
import { Reaction, Reactions, reactions } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { Category, FlexBackground, FlexType } from "@/model/flexboard";
import { Link, LinkType } from "@/model/link";
import { Objective, ObjectiveUpdate } from "@/model/objective";
import { SearchResult } from "@/model/search";
import { Art, Iteration, Session, Team } from "@/model/session";
import { AlmStickyType, Priority, StickyType } from "@/model/stickyType";
import { TimerEvent } from "@/model/timer";
import {
  AuthUser,
  PersonalUser,
  UserOrgEntities,
  almOnlyUser,
} from "@/model/user";
import { loadReactiveUser } from "@/services/user.service";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useStickyTypeStore } from "@/store/stickyType";
import { colorFromNumber } from "@/utils/color";
import { plusDays, utcDateToLocalMidnight } from "@/utils/date";
import { hash } from "@/utils/general";

import {
  ServerAlmStickyTypeData,
  ServerArt,
  ServerAuthUser,
  ServerBoardIteration,
  ServerCategory,
  ServerCompanySettings,
  ServerEvent,
  ServerFlexBackground,
  ServerFlexType,
  ServerIteration,
  ServerLink,
  ServerLinkType,
  ServerObjective,
  ServerSearchResult,
  ServerSession,
  ServerSettings,
  ServerStickyType,
  ServerTeam,
  ServerUserTeam,
} from "../serverModel";

export function mapSessions(
  sessions: ServerSession[],
  iterations: ServerIteration[][],
): Session[] {
  return sessions
    .map((session, index) => ({
      id: session.session_id,
      name: session.name,
      creationDate: session.creation_date,
      startDate: session.planning_dates?.start
        ? utcDateToLocalMidnight(session.planning_dates.start)
        : null,
      archived: session.archived,
      almStatus: null,
      iterations: mapIterations(iterations[index]),
    }))
    .sort((a, b) => {
      const d = b.creationDate - a.creationDate;
      return d > 0 ? 1 : d < 0 ? -1 : 0;
    });
}

export function mapLinkTypes(types: ServerLinkType[]): LinkType[] {
  return types.map((type) => ({
    id: type._id,
    from: type.from_sticky_type,
    to: type.to_sticky_type,
  }));
}

export function mapLinks(links: ServerLink[]): Link[] {
  return links.map((link) => ({
    id: link.id,
    from: link.from_sticky_id,
    to: link.to_sticky_id,
    type: link.link_type_id,
    state: "default",
  }));
}

export function mapScale(scale: number): {
  factor: number;
} & RelativeCoordinate {
  return { factor: scale, ...times(zoomFactor, scale) };
}

export function mapBoardIterations(
  bis: ServerBoardIteration[],
  existing?: BoardIteration[],
): BoardIteration[] {
  const iter = new Array<BoardIteration>();
  bis.forEach((bi) => {
    const old = existing?.[bi.board_iteration_id];
    iter[bi.board_iteration_id] = {
      velocity: +bi.velocity,
      load: old?.load || 0,
      state: old?.state || { status: null, detail: null },
    };
  });
  return iter;
}

export function mapStickyType(stickyType: ServerStickyType): StickyType {
  const priorities = stickyType.step_estimate
    ? prioritiesByValue(stickyType.step_estimate_values)
    : undefined;
  const color = checkColor(stickyType.id, stickyType.color);
  return {
    id: stickyType.id,
    name: stickyType.name,
    almType: stickyType.alm_type,
    color,
    altColor: stickyType.alt_color || color,
    priorities,
    functionality: stickyType.functionality,
    origin: stickyType.origin_board_type,
    flexOrigin: stickyType.origin_flexboard_type || null,
    usable: stickyType.available_board_types,
    flexUsable: stickyType.available_flexboard_types || [],
  };

  function prioritiesByValue(stepEstimates?: Priority[]) {
    if (stepEstimates) {
      return Object.fromEntries(
        stepEstimates.map((stepEstimate) => [stepEstimate.value, stepEstimate]),
      );
    }
  }

  function checkColor(id: string, c: Color | undefined | null): Color {
    if (c) {
      return c;
    }
    void captureMessage("Sticky type has no color", { stickyType: { id } });
    return [0.5, 0.5, 0.5, 1];
  }
}

export function mapAlmStickyTypes(
  almStickyTypes: ServerAlmStickyTypeData[],
): IdMap<AlmStickyType> {
  return Object.fromEntries(
    almStickyTypes.map((almType) => [
      almType.id,
      { id: almType.id, isMapped: almType.is_mapped },
    ]),
  );
}

export function mapIterations(iters: ServerIteration[]): Iteration[] {
  return iters
    .sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0))
    .map((iter) => ({
      id: iter.id,
      name: iter.name,
      start: utcDateToLocalMidnight(iter.start),
      end: plusDays(utcDateToLocalMidnight(iter.end), 1),
    }));
}

export function mapObjective(objective: ServerObjective): Objective {
  const mappedObjective = mapPartialObjective(objective);

  return { ...mappedObjective, cards: mappedObjective.cards || [] };
}

/**
 * Similar to mapObjective, but the return value may not have a cards property
 * (used to communicate that the cards property hasn't been updated)
 */
export function mapPartialObjective(
  objective: ServerObjective,
): ObjectiveUpdate {
  return {
    id: objective.objective_id,
    text: objective.text,
    description: objective.description,
    bv: objective.bv,
    av: objective.av,
    cards: objective.stickies?.map((obj) => {
      return { id: obj.id, isOrigin: obj.is_origin };
    }),
  };
}

export function mapFlexTypes(types: ServerFlexType[]): FlexType[] {
  return types.map((type) => ({
    id: type.id,
    name: type.name,
    background: type.background,
  }));
}

export function mapSearchResults(
  results: ServerSearchResult[],
): SearchResult[] {
  return results.map((result) => ({
    kind: "sticky",
    boardId: result.board_id,
    id: result.id,
    text: result.text ?? "",
    typeId: result.type_id,
    teamId: result.team_id?.toString(),
    artId: result.art_id,
    flag: CardFlag.fromFlagString(result.flag_type),
    almId: result.alm_issue_id,
    iterationId: result.iteration_number,
    groupId: result.sticky_group,
    status: useAlmItemTypeStore().calcStatus(
      result.status,
      useStickyTypeStore().findStickyType(result.type_id),
      result.team_id?.toString(),
      result.art_id,
      result.alm_source_id ?? null,
      result.status_class,
    ),
    dependTeamId: result.depend_team_id?.toString(),
    precondTeamId: result.precond_team_id?.toString(),
    points: result.story_points,
  }));
}

export function mapEvents(events: ServerEvent[]): TimerEvent[] {
  return events.flatMap((event) =>
    event.type === "timer"
      ? [
          {
            id: event.id,
            boardId: event.board_id,
            artId: event.art_id,
            type: "timer",
            createdById: event.created_by,
            createdAt: event.created_at,
            updatedById: event.updated_by,
            updatedAt: event.updated_at,
            data: {
              name: event.data.name,
              state: event.data.state,
              duration: event.data.duration,
              end: event.data.end,
            },
          },
        ]
      : [],
  );
}

export function mapReactions(reactionUsers: {
  [reaction in Reaction]?: string[];
}): Reactions {
  const res = {} as Reactions;
  for (const reaction of reactions) {
    res[reaction] = (reactionUsers[reaction] || []).map((id) =>
      loadReactiveUser({ id }),
    );
  }
  return res;
}

export function mapCategories(categories: ServerCategory[]): Category[] {
  return categories.map((cat) => ({
    id: cat._id,
    name: cat.name,
    boardIds: cat.board_ids,
  }));
}

export function mapArts(arts: ServerArt[]): Art[] {
  return arts.map((art) => ({
    id: art.id ? "" + art.id : "",
    name: art.name,
  }));
}

export function mapTeams(teams: ServerTeam[]): Team[] {
  return teams.map((team) => ({
    id: "" + team.user_id,
    name: team.name,
    artId: team.art_id ? "" + team.art_id : undefined,
  }));
}

export function mapUserTeams(teams: ServerUserTeam[]): Team[] {
  return teams.map((team) => ({
    id: "" + team.id,
    name: team.name,
    artId: team.art_id ? "" + team.art_id : undefined,
  }));
}

export function mapUser(user: ServerAuthUser): AuthUser {
  const name = mapUserName(user);
  return {
    type: "regular",
    id: user.id,
    name,
    email: user.email,
    imageUrl: user.image_url,
    color: user.color || colorFromNumber(hash(name)),
    preferredLanguage: user.preferred_language,
    hash: user.hash,
  };
}

export function mapUserName(user: { name?: string; email?: string }) {
  return user.name || user.email?.substring(0, user.email.indexOf("@")) || "";
}

export function mapSettings(settings: ServerSettings) {
  return {
    isBacklogAlmStickyDeletable: !settings.backlog_forbid_JIRA_sticky_delete,
    isTeamAlmStickyDeletable: !settings.team_forbid_sticky_delete,
    isPriorityEditable: !settings.backlog_forbid_WSJF_edit,
    confirmDelete: settings.confirm_sticky_delete || false,
    moveBetweenTeams: settings.move_activated || false,
    mirrorAssignedBacklogStickiesToTeamBoard:
      settings.backlog_mirror_assigned_stickies_to_team_board || false,
    iterationLoadWarn: settings.iteration_load_warn || null,
    iterationLoadCritical: settings.iteration_load_critical || null,
  };
}

export function mapCompanySettings(data: ServerCompanySettings = {}) {
  return {
    autoLogoutAfter: data.auto_logout_after || 0,
  };
}

export function mapFlexBackgrounds(
  backgrounds: ServerFlexBackground[],
): FlexBackground[] {
  return backgrounds.map(({ id, name, info_link }) => ({
    id,
    name,
    infoLink: info_link,
  }));
}

export function mapUserId(
  userId?: string | null,
  almId?: string | null,
): PersonalUser | null {
  return userId
    ? loadReactiveUser(
        { id: userId },
        { almMapping: almId ? "complete" : "pip-only" },
      )
    : almId
    ? almOnlyUser(almId, colorFromNumber(hash(almId)))
    : null;
}

type UserOrgEntitiesInput = {
  [K in keyof UserOrgEntities]: Array<{ id: number; name: string }>;
};

function mapIdsToStringsInArray(
  items: Array<{ id: number; name: string }>,
): Array<{ id: string; name: string }> {
  return items.map((item) => ({
    ...item,
    id: item.id.toString(),
  }));
}

export function mapOrgEntities(
  entities: UserOrgEntitiesInput,
): UserOrgEntities {
  return mapValues(entities, mapIdsToStringsInArray);
}
