import { clamp, minBy, range } from "lodash-es";

import { boardActions } from "@/action/boardActions";
import { sendCardMove } from "@/components/card/animator";
import {
  calcBoardSize,
  relativeToWindow,
  visibleWindowSize,
} from "@/math/coordinate-systems";
import {
  add,
  distance2,
  divided,
  minus,
  plus,
  times,
} from "@/math/coordinates";
import { boardAspect, fakeZoom } from "@/model/Settings";
import { IdMap } from "@/model/baseTypes";
import { Board } from "@/model/board";
import {
  Rectangle,
  RelativeCoordinate,
  relativeCoord,
} from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useBoardSizeStore } from "@/store/boardSize";
import { useBoardsStore } from "@/store/boards";
import { useZoomStore } from "@/store/zoom";

import { applyZoom } from "./boardSize";

export interface PositionedCard {
  data: { id: string };
  meta: { pos: RelativeCoordinate };
}

export function arrangeCards(
  board: Board,
  cards: PositionedCard[],
  bounds: Rectangle<RelativeCoordinate>,
) {
  const width = useBoardSizeStore().size.width * fakeZoom;
  const height = useBoardSizeStore().size.height * fakeZoom;
  const fieldWidth = (bounds.p1.x - bounds.p0.x) * width;
  const fieldHeight = (bounds.p1.y - bounds.p0.y) * height;
  const offset = relativeCoord(0, 0.08);
  const gap = { x: 1.1, y: 1.1 };
  let cardSize;
  let xc;
  let yc;
  let factor = 1;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    cardSize = times(board.cardSize, gap.x * factor, gap.y * factor);
    xc = Math.floor((fieldWidth - offset.x * width) / (cardSize.x * width));
    yc = Math.floor((fieldHeight - offset.y * height) / (cardSize.y * height));
    if (xc * yc >= cards.length) {
      break;
    }
    factor =
      (fieldWidth - offset.x * width) /
      (xc + 1.1) /
      (gap.x * board.cardSize.x * width);
  }
  boardActions.setCardSize("modal", board.id, board.cardSize.factor * factor);
  const base = plus(bounds.p0, plus(offset, divided(cardSize, 2)));
  const used: IdMap<boolean> = {};
  for (let i = 0; i < cards.length; i++) {
    let minDist = 1e9;
    let minC = 0;
    const target = plus(base, times(cardSize, i % xc, Math.floor(i / xc)));
    for (let c = 0; c < cards.length; c++) {
      if (!used[c]) {
        const dist = distance2(cards[c].meta.pos, target);
        if (dist < minDist) {
          minDist = dist;
          minC = c;
        }
      }
    }
    used[minC] = true;
    const id = cards[minC].data.id;
    const boardId = board.id;
    useBoardsStore().setCardPos({ id, boardId, ...target });
    sendCardMove(id, boardId, target);
  }
}

export function alignCards(
  cards: PositionedCard[],
  direction: "horizontal" | "vertical",
): PositionedCard[] {
  const sum = relativeCoord(0, 0);
  for (const card of cards) {
    add(sum, card.meta.pos);
  }
  return cards.map((card) => {
    const pos =
      direction === "horizontal"
        ? relativeCoord(card.meta.pos.x, sum.y / cards.length)
        : relativeCoord(sum.x / cards.length, card.meta.pos.y);
    return { data: { id: card.data.id }, meta: { pos } };
  });
}

export function distributeCards(
  cards: PositionedCard[],
  direction: "horizontal" | "vertical",
): PositionedCard[] {
  const comp = direction === "horizontal" ? "x" : "y";
  const sorted = [...cards].sort((a, b) => a.meta.pos[comp] - b.meta.pos[comp]);
  const start = sorted[0].meta.pos[comp];
  const end = sorted[sorted.length - 1].meta.pos[comp];
  const dist = (end - start) / (cards.length - 1);
  return sorted.map((card, index) => {
    const pos =
      direction === "horizontal"
        ? relativeCoord(start + dist * index, card.meta.pos.y)
        : relativeCoord(card.meta.pos.x, start + dist * index);
    return { data: { id: card.data.id }, meta: { pos } };
  });
}

export function lineupCards(
  cards: PositionedCard[],
  cardSize: RelativeCoordinate,
  cardDistance: number,
  regionOf: (pos: RelativeCoordinate) => Rectangle<RelativeCoordinate>,
): PositionedCard[] {
  const bounds = boundsOf(cards);
  const columns = similarColumnCount(cards, cardSize, bounds);
  const region = regionOf(bounds.min);
  return moveCards(cards, (card, index) => {
    const pos = plus(
      bounds.min,
      relativeCoord(
        cardSize.x * cardDistance * (index % columns),
        cardSize.y * cardDistance * Math.floor(index / columns),
      ),
    );
    return assureInsideRegion(pos, region, cardSize);
  });
}

function similarColumnCount(
  cards: PositionedCard[],
  cardSize: RelativeCoordinate,
  bounds: {
    min: RelativeCoordinate;
    max: RelativeCoordinate;
  },
) {
  const aspectOfBounds =
    ((cardSize.x + bounds.max.x - bounds.min.x) /
      (cardSize.y + bounds.max.y - bounds.min.y)) *
    boardAspect;
  return minBy(range(1, cards.length + 1), (cols) => {
    const aspect = cols / Math.ceil(cards.length / cols);
    return Math.abs(aspectOfBounds - aspect);
  })!;
}

function assureInsideRegion(
  pos: RelativeCoordinate,
  region: Rectangle<RelativeCoordinate>,
  cardSize: RelativeCoordinate,
) {
  return relativeCoord(
    clamp(pos.x, region.p0.x + cardSize.x / 2, region.p1.x - cardSize.x / 2),
    clamp(pos.y, region.p0.y + cardSize.y / 2, region.p1.y - cardSize.y / 2),
  );
}

function boundsOf(cards: PositionedCard[]) {
  const min = relativeCoord(1, 1);
  const max = relativeCoord(0, 0);
  cards.forEach((card) => {
    min.x = Math.min(min.x, card.meta.pos.x);
    min.y = Math.min(min.y, card.meta.pos.y);
    max.x = Math.max(max.x, card.meta.pos.x);
    max.y = Math.max(max.y, card.meta.pos.y);
  });
  return { min, max };
}

function moveCards(
  cards: PositionedCard[],
  calcNewPos: (card: PositionedCard, index: number) => RelativeCoordinate,
): PositionedCard[] {
  const movedCards = new Set<string>();
  return cards.map((card, index) => {
    const newPos = calcNewPos(card, index);
    const nearest = nearestCard(newPos);
    return { data: { id: nearest.data.id }, meta: { pos: newPos } };
  });

  function nearestCard(pos: RelativeCoordinate) {
    let minDist = 10;
    let nearest = cards[0];
    cards.forEach((card) => {
      if (!movedCards.has(card.data.id)) {
        const dist = distance2(card.meta.pos, pos);
        if (dist < minDist) {
          minDist = dist;
          nearest = card;
        }
      }
    });
    movedCards.add(nearest.data.id);
    return nearest;
  }
}

export function zoomToRegion(
  bounds: Rectangle<RelativeCoordinate>,
  adjustZoom = 0.95,
) {
  const targetSize = minus(
    relativeToWindow(bounds.p1),
    relativeToWindow(bounds.p0),
  );
  const windowSize = visibleWindowSize();
  const zooms = {
    x: windowSize.x / targetSize.x,
    y: windowSize.y / targetSize.y,
  };
  const zoom = useZoomStore().factor * adjustZoom * Math.min(zooms.x, zooms.y);
  const zoomedSize = calcBoardSize(zoom);
  const zoomedTopLeft = relativeToWindow(bounds.p0, zoomedSize);
  const zoomedTargetSize = minus(
    relativeToWindow(bounds.p1, zoomedSize),
    zoomedTopLeft,
  );
  const overhang = minus(windowSize, zoomedTargetSize);
  const centered = minus(zoomedTopLeft, divided(overhang, 2));
  applyZoom(zoom, {
    target: minus(centered, useBoardSizeStore().windowMargin.leftTop),
    smooth: true,
  });
}

export function findFreePosition(
  coord: RelativeCoordinate,
  step: RelativeCoordinate,
): RelativeCoordinate {
  const minDist = Math.min(step.x, step.y);
  const cardSize = useBoardStore().currentBoard().cardSize;
  const min = { x: cardSize.x / 2, y: cardSize.y / 2 };
  const max = { x: 1 - cardSize.x / 2, y: 1 - cardSize.y / 2 };
  const c = { ...coord };
  const cards = useBoardStore().currentBoard().cards;
  let notFree;
  do {
    notFree = false;
    for (const id in cards) {
      const card = cards[id];
      if (distance2(card.meta.pos, c) < 0.9 * minDist * minDist) {
        notFree = true;
        // Position 'c' already has a sticky -> adjust by step
        c.x = clamp(c.x + step.x, min.x, max.x);
        c.y = clamp(c.y + step.y, min.y, max.y);
        break;
      }
    }
  } while (
    notFree &&
    (c.x > min.x || c.y > min.y) &&
    (c.x < max.x || c.y < max.y)
  );
  return c;
}
