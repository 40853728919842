import { saveAs } from "file-saver";

import { captureException, captureMessage } from "@/error/sentry";
import { boardAspect, screenShotWidth } from "@/model/Settings";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardSizeStore } from "@/store/boardSize";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import { useZoomStore } from "@/store/zoom";

import {
  removeBoxShadows,
  resetBoxShadows,
  resetExplicitCurrentColor,
  resetExplicitSize,
  setExplicitCurrentColor,
  setExplicitSize,
  stickyNotes,
  svgElementsOnBoard,
} from "./screenshotDom";

export function screenShot() {
  useToastStore().show(/*$t*/ "message.generateImage", { duration: 0 });
  setTimeout(async () => {
    try {
      await screenShotWithAdjustedDom();
    } finally {
      useToastStore().hide();
    }
  }, 100);
}

// adjust the DOM to avoid element styles that are not supported by html2canvas
async function screenShotWithAdjustedDom() {
  try {
    stickyNotes().forEach(removeBoxShadows);
    svgElementsOnBoard().forEach((svg) => {
      setExplicitCurrentColor(svg);
      setExplicitSize(svg);
    });
    await doScreenShot();
  } catch (fail) {
    void captureException(fail);
  } finally {
    stickyNotes().forEach(resetBoxShadows);
    svgElementsOnBoard().forEach((svg) => {
      resetExplicitCurrentColor(svg);
      resetExplicitSize(svg);
    });
  }
}

async function doScreenShot() {
  const { default: html2canvas } = await import("@nidi/html2canvas");
  const scale = screenShotScale();
  const canvas = await html2canvas(document.body, {
    scale,
    logging: false,
    ignoreElements: (e: any) => e.getAttribute("data-no-screenshot") !== null,
  });
  cutOutBoard(canvas, scale).toBlob((blob) => {
    if (blob) {
      saveAs(blob, screenShotName());
    } else {
      void captureMessage("Could not generate screenshot blob");
    }
  });
}

function screenShotScale() {
  return screenShotWidth / (useZoomStore().factor * effectiveBoardWidth());
}

function effectiveBoardWidth() {
  return parseInt(document.getElementById("boards")!.style.width);
}

function cutOutBoard(canvas: HTMLCanvasElement, scale: number) {
  const target = document.createElement("canvas");
  target.width = screenShotWidth;
  target.height = screenShotWidth / boardAspect;
  target
    .getContext("2d")!
    .drawImage(
      canvas,
      -useBoardSizeStore().size.left * scale,
      -useBoardSizeStore().size.top * scale,
    );
  return target;
}

function screenShotName() {
  const session = safeFilename(useSessionStore().session.current.name);
  const art = useArtStore().isMultiArt
    ? "-" + safeFilename(useArtStore().currentArt.name)
    : "";
  return `${session}${art}-${boardName()}.png`;
}

function safeFilename(s: string) {
  return s.replace(/[^A-Za-z0-9]+/g, "_");
}

function boardName() {
  const board = useBoardStore().currentBoard();
  switch (board.type) {
    case "team":
      return "team-" + safeFilename(board.team.name || "");
    case "flex":
      return safeFilename(board.name || "");
    default:
      return board.type;
  }
}
