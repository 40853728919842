<script lang="ts" setup>
import { computed } from "vue";

import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import StickyStatus from "@/components/StickyNote/components/StickyNoteFooter/components/StickyStatus/StickyStatus.vue";
import { cardKey } from "@/components/card/injectKeys";
import StatusDot from "@/components/ui/StatusDot/StatusDot.vue";
import { isWorkitem } from "@/model/stickyType";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);

const isStickyStatusVisible = computed(() => {
  // hidden in execution mode
  if (useWorkModeStore().isExecutionMode) return false;
  // visible only for work items
  return isWorkitem(card);
});
</script>

<template>
  <ActionMenuItem
    v-if="isStickyStatusVisible"
    name="status"
    :tooltip-text="card.status?.name ?? $t('statusDistribution.status')"
    :aria-label="$t('label.actionMenu.status')"
  >
    <StatusDot :status-class="card.status?.statusClass || 'undefined'" />
    <template #dropdown>
      <StickyStatus trigger="action-menu" />
    </template>
  </ActionMenuItem>
</template>
