import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { bootstrap, set, setOptions } from "vue-gtag";
import { RouteLocation, RouteLocationRaw } from "vue-router";

import { openConnection } from "@/backend/Connection";
import { IntercomStatic } from "@/declare/windowIntercom";
import { setUser } from "@/error/sentry";
import { AuthUser, Role, TechnicalUser } from "@/model/user";
import { getUserTeams } from "@/services/userAdmin.service";
import { useCompanySettingsStore } from "@/store/companySettings";
import { useLicenseStore } from "@/store/license";
import { useLoadingStore } from "@/store/loading";
import { useMessengerStore } from "@/store/messenger";
import { useUserStore } from "@/store/user";
import { identifyUser } from "@/utils/analytics/track";
import { whenChildElementWithClass } from "@/utils/dom/dom";
import {
  faroUrl,
  gaKey,
  intercomApi,
  intercomId,
  isCloud,
} from "@/utils/env/Environment";
import { isFeatureEnabled, parseUrlWithoutRouter } from "@/utils/env/feature";
import { initFeatureToggles } from "@/utils/env/featureToggles";
import { setupInactivityLogout } from "@/utils/inactivityLogout";

import {
  currentRoute,
  getTargetPageName,
  goToLogin,
  goToLogout,
} from "./navigation";

type Target = RouteLocation["query"] & { path: string };

export async function login(
  user: TechnicalUser,
  target: Target,
): Promise<RouteLocationRaw> {
  setLastLoginAt();

  useUserStore().technicalUser = { ...user, teams: await getUserTeams() };
  setUser(user.company, user.id);
  const authUser = await connect(user);
  initUserTracking(authUser, user, target);
  initIntercom(authUser, user);
  initFaro(authUser, user);
  initInactivityTimeout();

  const query = { ...target } as RouteLocation["query"];
  delete query.session;
  delete query.board;
  delete query.team;
  delete query.path;
  return { path: target.path || "/page/session", query };
}

async function connect(user: TechnicalUser): Promise<AuthUser> {
  const connectionName = `${user.company}:${user.id}:${Math.floor(
    Date.now() / 1000,
  )}`;
  const [authUser] = await Promise.all([
    useLoadingStore().load("loading.user", useUserStore().loadCurrentUser()),
    useLoadingStore().load(
      "loading.featureToggles",
      initFeatureToggles(user.company),
    ),
    useLoadingStore().load(
      "loading.connect",
      openConnection(
        () => currentRoute().path,
        { login: goToLogin, logout: goToLogout },
        connectionName,
      ),
    ),
  ]);
  return authUser;
}

async function initUserTracking(
  authUser: AuthUser,
  user: TechnicalUser,
  target: Target,
) {
  if (isCloud && useLicenseStore().license.tracking) {
    identifyUser();
  }

  if (isCloud && gaKey && useLicenseStore().license.tracking) {
    setOptions({
      config: {
        id: gaKey,
        params: {
          page_title: getTargetPageName(target),
          user_id: authUser.id,
          language: authUser.preferredLanguage,
        },
      },
    });
    await bootstrap();
    set({ user_properties: { company: user.company, role: user.role } });
  }
}

function initInactivityTimeout() {
  const timeoutMinutes = useCompanySettingsStore().autoLogoutAfter;
  setupInactivityLogout({
    timeoutMinutes,
    onTimeout: () => goToLogout("inactivity"),
  });
}

function initFaro(authUser: AuthUser, user: TechnicalUser) {
  if (
    !faroUrl ||
    !isFeatureEnabled({ query: parseUrlWithoutRouter() }, "faro")
  ) {
    return;
  }
  let sampleRate = 0.08;
  const sampleAll = isFeatureEnabled(
    { query: parseUrlWithoutRouter() },
    "faro-sample-all",
  );
  if (sampleAll) {
    sampleRate = 1;
  }

  const faro = initializeFaro({
    url: faroUrl,
    app: {
      name: "piplanning",
      version: window.BUILD_HASH,
      environment: window.ENVIRONMENT,
    },
    sessionTracking: {
      samplingRate: sampleRate,
    },
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations(),
      // This packages is optional because it increases the bundle size noticeably.
      // Only add it if you want tracing data.
      // new TracingInstrumentation(),
    ],
  });

  faro?.api.setUser({
    id: authUser.id,
    attributes: {
      company: user.company,
    },
  });
}

function initIntercom(authUser: AuthUser, user: TechnicalUser) {
  if (
    isCloud &&
    intercomId &&
    intercomApi &&
    useLicenseStore().license.tracking
  ) {
    window.Intercom = null as unknown as IntercomStatic; // delete existing intercom (dummy) instance
    window.loadIntercom(intercomId, intercomApi);
  }

  // show the custom intercom launcher only when the default launcher would be shown (it's in the DOM but made invisible by CSS)
  // the reason is that the default launcher can be configured to appear only in certain conditions (e.g. user role)
  // custom launcher cannot be configured like this.
  // Remove this code when we don't need this function anymore (because intercom launcher should always be shown)
  ifDefaultLauncherShown(() => (useMessengerStore().show = true));

  window.Intercom("boot", {
    custom_launcher_selector: ".messenger-item",
    email: authUser.email,
    name: authUser.name,
    user_id: authUser.id,
    user_hash: authUser.hash,
    role: intercomRoles[user.role],
    language_override: authUser.preferredLanguage,
    avatar: {
      type: "avatar",
      image_url: authUser.imageUrl || "",
    },
    company: {
      company_id: user.company,
      name: user.company,
      plan: useLicenseStore().license.plan,
    },
  });
}

function ifDefaultLauncherShown(action: () => void) {
  whenChildElementWithClass(document.body, "intercom-lightweight-app", (app) =>
    whenChildElementWithClass(app, "intercom-launcher", action),
  );
}

const intercomRoles: { [role in Role]: string } = {
  observer: "Observer",
  user: "Member",
  admin: "Admin",
  planning_interval_admin: "PI Admin",
};

function setLastLoginAt() {
  localStorage.setItem("lastLoginAt", Date.now().toString());
}
