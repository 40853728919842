<script setup lang="ts">
import { storeToRefs } from "pinia";

import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import { useSwitchSessionStore } from "@/store/switchSession";
import { switchSessionOpened } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

const handleClick = () => {
  trackEvent(switchSessionOpened());
};

const { active: isSwitchSessionOpen } = storeToRefs(useSwitchSessionStore());
</script>

<template>
  <div
    class="switch-session-item"
    :class="{ 'not-activated': !isSwitchSessionOpen }"
  >
    <ActionButton
      :action="toggleActions.showSwitchSession"
      :activated="isSwitchSessionOpen"
      icon="menu-top/logo"
      :aria-label="$t('label.menuTop.changeSession')"
      @click="handleClick"
    />
  </div>
</template>

<style lang="scss">
.switch-session-item {
  display: flex;

  .base-button {
    width: 40px;
    height: 40px;
  }
}

.dark-mode {
  .switch-session-item.not-activated {
    svg path {
      fill: var(--text-secondary-color);
    }
  }
}
</style>
