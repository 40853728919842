import { useStickyTypeStore } from "@/store/stickyType";

import { BaseBacklogBoard } from "./BaseBacklogBoard";

export class BacklogBoard extends BaseBacklogBoard<"backlog"> {
  positionalCardProperties() {
    return { priority: 0 };
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("backlog");
  }
}
