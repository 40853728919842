<script setup lang="ts">
import { sortBy, uniqBy } from "lodash-es";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { navigationActions } from "@/action/navigationActions";
import { useEventBus } from "@/composables/useEventBus";
import { BoardType, boardIcon, boardTypeName } from "@/model/baseTypes";
import { Icon } from "@/model/icon";
import { Art, Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useFlexStore } from "@/store/flex";
import { useNavigationStore } from "@/store/navigation";
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";
import { teamChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import BreadCrumbSelector from "./BreadCrumbSelector.vue";

const teamSelector = ref();

onMounted(() => {
  const { onOpenNavigation } = useEventBus();
  onOpenNavigation(() => teamSelector.value.open());
});

const { t } = useI18n();

const currentFlex = computed(() => {
  const board = useBoardStore().currentBoard();
  return board.type === "flex" ? board : undefined;
});
const flexTypes = computed(() => {
  return sortBy(
    uniqBy(
      useFlexStore().flexBoards.map((flex) => flex.flexType),
      (type) => type.id,
    ),
    (type) => type.name,
  );
});
const flexes = computed(() => {
  const flexType = currentFlex.value?.flexType.id;
  return flexType ? useFlexStore().flexBoardsByType(flexType) : [];
});

function selectFlexType(type: { id: string }) {
  const flexId = useNavigationStore().lastSelectedFlexId(type.id);
  // flexId might been deleted
  const flexById = flexId ? useFlexStore().flexBoardById(flexId) : undefined;
  const flex = flexById ?? useFlexStore().flexBoardsByType(type.id)[0];
  navigationActions.changeCanvas("mainMenu", flex.name);
}

function selectFlex(flex: { name: string }) {
  navigationActions.changeCanvas("mainMenu", flex.name);
}

const userTeams = computed(() => useUserStore().technicalUser.teams);

const teams = computed(() =>
  useTeamStore().teamsInCurrentArt.map(mark(isUserTeam, "general/user")),
);
const currentTeam = computed(() => useTeamStore().currentTeam);

function selectTeam(team: Team) {
  trackEvent(teamChanged("topbar-menu"));
  navigationActions.changeTeam("mainMenu", team);
}

const arts = computed(() =>
  useArtStore().arts.map(mark(isUserArt, "general/user")),
);
const currentArt = computed(() => useArtStore().currentArt);
const hasArtSelector = computed(() => currentArt.value.name);

function selectArt(art: Art) {
  navigationActions.changeArt("mainMenu", art);
}

const currentBoard = computed(() =>
  makeBoardItem(useBoardStore().currentBoard().type),
);

function makeBoardItem(type: BoardType) {
  return { id: type, name: t(boardTypeName(type)), mark: boardIcon(type) };
}

function mark<T>(prop: (item: T) => boolean, icon: Icon) {
  return (item: T) => ({ ...item, mark: prop(item) ? icon : undefined });
}

function isUserTeam(team: Team) {
  return userTeams.value.some((userTeam) => userTeam.id === team.id);
}

function isUserArt(art: Art) {
  return userTeams.value.some((team) => team.artId === art.id);
}
</script>

<template>
  <div class="bread-crumb">
    <template v-if="currentFlex">
      <BreadCrumbSelector
        icon="board/canvas"
        :title="$t('general.canvasTypes')"
        :tooltip="$t('general.canvasType', { name: currentFlex.flexType.name })"
        :items="flexTypes"
        :value="currentFlex.flexType"
        @change="selectFlexType"
      />

      <BreadCrumbSelector
        icon="board/canvas"
        :title="$t('general.canvases', { type: currentFlex.flexType.name })"
        :tooltip="$t('general.canvas', { name: currentFlex.name })"
        terminal
        :items="flexes"
        :value="currentFlex"
        @change="selectFlex"
      />
    </template>

    <template v-else>
      <BreadCrumbSelector
        v-if="hasArtSelector"
        icon="org/art"
        :title="$t('general.arts')"
        :tooltip="$t('general.art', { name: currentArt.name })"
        :items="arts"
        :value="currentArt"
        @change="selectArt"
      />

      <BreadCrumbSelector
        ref="teamSelector"
        icon="org/team"
        :title="$t('general.teams')"
        :tooltip="$t('general.team', { name: currentTeam.name })"
        :items="teams"
        :value="currentTeam"
        @change="selectTeam"
      />

      <BreadCrumbSelector
        :icon="currentBoard.mark"
        :title="$t('general.boards')"
        :tooltip="$t('general.board', { name: currentBoard.name })"
        terminal
        :items="[]"
        :value="currentBoard"
      />
    </template>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/mixins/utils";

.bread-crumb {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--text-secondary-color);

  & > span {
    max-width: 15vw;
  }

  h1 {
    @include utils.ellipsis;

    margin-left: 8px;
  }

  .select-button {
    font-weight: font.$weight_normal;
  }
}
</style>
