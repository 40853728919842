<script setup lang="ts">
import { v4 as uuid } from "uuid";
import { computed, inject, ref, useSlots, watch } from "vue";

import { shapeKey } from "@/components/Draw/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import DropdownMenu, {
  type DropdownMenuProps,
} from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import {
  shapeActionMenuItemClicked,
  stickyNoteActionMenuItemClicked,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

const card = inject(cardKey, undefined);
const shape = inject(shapeKey, undefined);

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  active?: boolean;
  tooltipText?: string;
  width?: DropdownMenuProps["width"];
  maxHeight?: DropdownMenuProps["maxHeight"];
  disabled?: boolean;
  name: string;
  ariaLabel?: string;
  as?: "button" | "a";
}>();

const slots = useSlots();

const dropdownId = slots.dropdown ? uuid() : undefined;
const isDropdownOpen = ref(false);
const isActive = computed(() => {
  return props.active || (isDropdownOpen.value && slots.dropdown);
});
const triggerButton = ref<HTMLButtonElement>();

watch(isDropdownOpen, (newVal: boolean) => {
  if (!newVal) {
    triggerButton.value?.focus();
  }
});

const handleClick = () => {
  // don't count events when the dropdown is open
  if (isDropdownOpen.value) return;

  if (card) {
    trackEvent(
      stickyNoteActionMenuItemClicked(props.name, card.type.functionality),
    );
  }
  if (shape) {
    trackEvent(shapeActionMenuItemClicked(props.name));
  }
};
</script>

<template>
  <div v-if="!$slots.dropdown" class="action-menu-item">
    <BaseTooltip :delay="[500, 0]" :offset="[0, 8]" class="tooltip">
      <component
        :is="props.as || 'button'"
        ref="triggerButton"
        :class="['content', { active: isActive }]"
        :aria-label="ariaLabel"
        v-bind="$attrs"
        @click="handleClick"
      >
        <slot />
      </component>
      <template v-if="tooltipText" #content>{{ tooltipText }}</template>
    </BaseTooltip>
  </div>

  <DropdownMenu
    v-else
    class="action-menu-item"
    stay-open-on-content-click
    disable-teleport
    :disabled="disabled"
    :width="width || 'md'"
    :max-height="maxHeight || 'md'"
    @open="isDropdownOpen = true"
    @close="isDropdownOpen = false"
  >
    <template #trigger>
      <BaseTooltip :delay="[500, 0]" :offset="[0, 8]" class="tooltip">
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
        <button
          ref="triggerButton"
          :class="['content', { active: isActive }]"
          :aria-label="ariaLabel"
          :aria-controls="dropdownId"
          :aria-expanded="isDropdownOpen"
          :disabled="disabled"
          v-bind="$attrs"
          @click="handleClick"
        >
          <slot />
        </button>
        <div class="dropdown-icon">
          <svg
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="presentation"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.99993 1.06863e-10C5.55221 -7.98692e-06 5.99993 0.447701 5.99994 0.999986L6 4.99999C6 5.2652 5.89465 5.51956 5.70711 5.7071C5.51958 5.89464 5.26522 6 5 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H3.99999L3.99994 1.00001C3.99993 0.44773 4.44764 7.98713e-06 4.99993 1.06863e-10Z"
              fill="white"
              fill-opacity="0.5"
            />
          </svg>
        </div>
        <template v-if="tooltipText" #content>{{ tooltipText }}</template>
      </BaseTooltip>
    </template>

    <div v-if="$slots.dropdown" :id="dropdownId" class="wrapper">
      <slot name="dropdown" />
    </div>
  </DropdownMenu>
</template>

<style scoped lang="scss">
@use "@/styles/variables/colors";

.action-menu-item {
  position: relative;
  height: 36px;
  width: 36px;

  .tooltip {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  .content {
    padding: 8px;
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover:not(.disabled, .active),
    &:focus-within:not(.disabled, .active) {
      background-color: colors.$white-alpha-20;
    }

    &:active:not(.disabled) {
      background-color: colors.$white-alpha-40;
    }
  }

  .active {
    background-color: colors.$white-alpha-40;
  }

  .dropdown-icon {
    pointer-events: none;
    display: grid;
    place-items: center;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }

  .wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
}
</style>
