<script setup lang="ts">
import { computed } from "vue";

import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import { useSearchMenuStore } from "@/store/searchMenu";

import IterationItemTooltip from "../tooltips/IterationItemTooltip.vue";
import SearchDropdown from "./SearchDropdown.vue";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedIterations,
  set: (values: Array<{ id: number }>) =>
    (store.selectedIterationIds = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <SearchDropdown
    name="iteration"
    icon="general/iteration"
    label-key="general.iterations"
    :selected="selection.length > 0"
  >
    <template #tooltip>
      <IterationItemTooltip :iterations="selection" />
    </template>
    <SelectableList
      v-model="selection"
      v-keyboard-nav
      :values="store.allIterations"
      :header="$t('general.iterations')"
    >
      <template #default="{ value }">
        {{ $t("general.namedIteration", { name: value.name }) }}
      </template>
    </SelectableList>
  </SearchDropdown>
</template>
