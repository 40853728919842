<script setup lang="ts">
import { computed } from "vue";

import OrganizationBaseTree from "@/components/OrganizationBaseTree/OrganizationBaseTree.vue";
import {
  OrganizationTreeNodeData,
  SelectedNode,
} from "@/components/OrganizationBaseTree/types";
import { ART_BOARDS, SOLUTION_BOARDS, TEAM_BOARDS } from "@/model/board";
import { goToBoard } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useTeamStore } from "@/store/team";
import { switchSessionOrgTreeLevelSelected } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

const selected = computed<SelectedNode>(() => {
  const currentBoard = useBoardStore().currentBoard();
  const { type } = currentBoard;

  if (type === "team") {
    return { level: "team", id: currentBoard.team.id };
  }
  if (ART_BOARDS.includes(type)) {
    return { level: "art", id: currentBoard.artId ?? "" };
  }
  if (SOLUTION_BOARDS.includes(type)) {
    return { level: "solution-train" };
  }
  return { level: "none" };
});

const handleSelect = (node: OrganizationTreeNodeData) => {
  const currentBoard = useBoardStore().currentBoard();
  const { type } = currentBoard;

  const goToTeam = (id: string) => {
    trackEvent(switchSessionOrgTreeLevelSelected("team"));
    const team = useTeamStore().findTeam({ id });
    goToBoard({ type: "team", team });
  };

  const goToArt = (artId: string) => {
    trackEvent(switchSessionOrgTreeLevelSelected("art"));
    if (TEAM_BOARDS.includes(type) || type === "flex") {
      goToBoard({ type: "program", artId });
    } else if (ART_BOARDS.includes(type)) {
      goToBoard({ type, artId });
    } else if (type === "solution") {
      goToBoard({ type: "program", artId });
    } else if (type === "solution_backlog") {
      goToBoard({ type: "backlog", artId });
    }
  };

  const goToSolutionTrain = () => {
    trackEvent(switchSessionOrgTreeLevelSelected("solution-train"));
    if (TEAM_BOARDS.includes(type) || type === "flex") {
      goToBoard({ type: "solution" });
    } else if (ART_BOARDS.includes(type)) {
      goToBoard({ type: type === "backlog" ? "solution_backlog" : "solution" });
    }
  };

  switch (node.levelContext.level) {
    case "team":
      goToTeam(node.levelContext.id);
      break;

    case "art":
      goToArt(node.levelContext.id);
      break;

    case "solution-train":
      goToSolutionTrain();
      break;
  }
};
</script>

<template>
  <OrganizationBaseTree :selected="selected" @select="handleSelect" />
</template>
