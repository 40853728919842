import {
  Ref,
  WatchStopHandle,
  computed,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";

import { TEXT_INPUT_WRAPPER_CLASS } from "@/components/StickyNote/components/StickyNoteText/StickyNoteTextEditable.vue";
import { isKeyDown } from "@/components/utils/Shortcuts";
import {
  StickyNoteEnlarge,
  StickyNoteShrink,
  useEventBus,
} from "@/composables/useEventBus";
import { useTransition } from "@/composables/useTransition";
import { useDelayedAction } from "@/composables/utils/useDelayedAction";
import { Card } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useZoomStore } from "@/store/zoom";
import {
  type EnlargeTrigger,
  stickyNoteEnlarged,
  stickyNoteShrunk,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { eventTarget } from "@/utils/dom/dom";

export interface Props {
  el: Ref<HTMLDivElement | undefined>;
  card: Card;
  disabled?: Ref<Readonly<boolean>>;
}

export function useEnlarge({ el, card, disabled }: Props) {
  const boardStore = useBoardStore();
  const dragStore = useDraggingStore();

  const isDragged = computed(() => !!dragStore.dragging[card.id]);
  const isActive = computed(() => boardStore.activeCardId === card.id);
  const isEnlarging = ref(false);
  // change to this value will trigger the "transform: scale()" in useStyles
  const isEnlarged = ref(false);

  const { classes, transition } = useTransition(el);

  const zoomWatcher = ref<WatchStopHandle | null>(null);
  const enlargedWatcher = ref<WatchStopHandle | null>(null);

  onMounted(() => {
    if (disabled?.value) return;

    el?.value?.addEventListener("pointerenter", handleStickyNotePointerEnter);
    // global listeners so that the sticky note can be enlarged/shrunk from outside
    const { onStickyNoteAction } = useEventBus();
    onStickyNoteAction(null, "enlarge", handleEnlargeAction);
    onStickyNoteAction(card.id, "shrink", handleShrinkAction);
  });

  // dbl click enlarge
  const registerDblClickEventListener = () => {
    el.value?.addEventListener("dblclick", handleStickyNoteDblClick);
  };

  const removeDblClickEventListeners = () => {
    el.value?.removeEventListener("dblclick", handleStickyNoteDblClick);
  };

  const [action, cancel] = useDelayedAction(removeDblClickEventListeners, 500);
  watch(isActive, (active) => (active ? action() : cancel()));

  const handleStickyNoteDblClick = (event: MouseEvent) => {
    void enlarge("double-click"); // no need to focus since the user already cliked on the sticky note
    removeDblClickEventListeners();
    event.stopPropagation();
  };

  // listen to enlarge event on the event bus and then enlarge the sticky note
  const handleEnlargeAction = async (action: StickyNoteEnlarge) => {
    if (disabled?.value) return;

    // another sticky note was enlarged, we shrink this one
    if (isEnlarged.value && action.id !== card.id) {
      void shrink();
      return;
    }

    // the sticky note was not enlarged and the event is for this sticky note
    if (!isEnlarged.value && action.id === card.id) {
      document.addEventListener("pointerdown", shrinkOnBoardClick, {
        capture: true,
      });

      await enlarge(action.trigger);
      if (action.focus) focus();
    }
  };

  const handleShrinkAction = (action: StickyNoteShrink) => {
    if (isEnlarged.value && action.id === card.id) {
      void shrink();
    }
  };

  // used only when the sticky note was enlarged from outside
  const shrinkOnBoardClick = (event: MouseEvent) => {
    const target = eventTarget(event)!;
    // the click come from the same sticky note
    if (el.value?.contains(target)) {
      return;
    }
    // we ignore clicks from any html element that has data-ignore-click attribute so the sticky note
    // does not get deselected when we click on an item which was teleported to the body
    if (target.closest("[data-ignore-click]")) {
      return;
    }

    // the click came outside from the board
    if (!target.closest("#boards > .board")) return;

    void shrink();
  };

  const unregisterKeyUpAndPointerLeaveEvents = () => {
    document.removeEventListener("keyup", handleSpacebarPress);
    el.value?.removeEventListener("pointerleave", handleStickyNotePointerLeave);
  };

  const enlarge = async (trigger?: EnlargeTrigger) => {
    isEnlarged.value = true;
    isEnlarging.value = true;

    boardStore.setEnlargedStickyNoteId(card.id);

    await transition("enlarge-transition");
    isEnlarging.value = false;

    const cardType = card.type.functionality;
    trackEvent(stickyNoteEnlarged(cardType, trigger));

    zoomWatcher.value = watch(
      () => useZoomStore().factor,
      () => shrink({ immediate: true }),
      { once: true },
    );

    enlargedWatcher.value = watch(
      () => boardStore.enlargedStickyNoteId,
      () => {
        if (boardStore.enlargedStickyNoteId !== card.id && isEnlarged.value) {
          void shrink();
        }
      },
      { once: true },
    );
  };

  const shrink = async ({ immediate = false } = {}) => {
    enlargedWatcher.value?.();
    zoomWatcher.value?.();

    trackEvent(stickyNoteShrunk(card.type.functionality));

    document.removeEventListener("pointerdown", shrinkOnBoardClick, {
      capture: true,
    });

    isEnlarged.value = false;
    boardStore.setEnlargedStickyNoteId(null);

    if (immediate) return;

    isEnlarging.value = true;
    await transition("enlarge-transition");
    isEnlarging.value = false;
  };

  // focus the sticky note by clicking on it
  const focus = () => {
    const textInputWrapper = el.value?.querySelector(
      `.${TEXT_INPUT_WRAPPER_CLASS}`,
    ) as HTMLElement | undefined;

    textInputWrapper?.click();
  };

  // This will enlarge the sticky note when the user hovers over it, with spacebar
  // pressed down.
  const handleStickyNotePointerEnter = async (_event: PointerEvent) => {
    await nextTick();
    if (disabled?.value || isActive.value || isEnlarged.value) {
      return;
    }

    // when dragged, the pointer leaves and enters the sticky note many times
    if (isDragged.value) return;

    // allow the user to enlarge the sticky note by double clicking on it
    registerDblClickEventListener();

    if (isKeyDown("Space")) {
      void enlarge("spacebar");
    }

    document.addEventListener("keyup", handleSpacebarPress);
    el.value?.addEventListener("pointerleave", handleStickyNotePointerLeave);
  };

  // This will reset the enlarged sticky note when the user leaves the sticky note while
  // holding space bar/ This means the sticky was not selected by releasing the space bar

  const handleStickyNotePointerLeave = async () => {
    await nextTick();

    // when dragged, the pointer leaves and enters the sticky note many times
    if (isDragged.value) return;

    if (isEnlarged.value && isActive.value) return;
    if (isEnlarged.value && !isActive.value) {
      void shrink();
    }

    unregisterKeyUpAndPointerLeaveEvents();
    removeDblClickEventListeners();
  };

  const handleSpacebarPress = async (event: KeyboardEvent) => {
    if (
      event.code !== "Space" ||
      disabled?.value ||
      eventTarget(event)?.nodeName !== "BODY"
    ) {
      return;
    }
    const activeStickyId = boardStore.activeCardId;

    // user was hovering over the sticky note and then pressed the space bar. Enlarge and focus
    // (unless another sticky note is being edited)
    if (
      !isEnlarged.value &&
      !isActive.value &&
      !(activeStickyId && activeStickyId !== card.id)
    ) {
      await enlarge();
      focus();
    }

    // the sticky note was enlarged and the user released the space bar. Now we just focus
    if (isEnlarged.value) {
      focus();
    }

    unregisterKeyUpAndPointerLeaveEvents();
  };

  return { isEnlarged, isEnlarging, classes };
}
