const reverseRpcTopicMapping: Record<string, string> = {
  "info.get": "get_server_info",
  "license.get": "get_license_details",
  "sticky.get_scale": "get_sticky_scale",
  "timer.list": "get_events",
  "timer.delete": "delete_event",
  "timer.update": "timer.update_event",
  "timer.create": "timer.create_event",
  "sticky.list": "get_stickies",
  "iteration.list": "get_iterations",
  "objective.list": "get_objectives",
  "sticky.create": "new_sticky",
  "sticky.mirror": "mirror",
  "sticky.move": "move_to_team",
  "sticky.create_on_risk_board": "add_sticky_to_risk_board",
  "session.get": "alm.get_session_info",
  "item.get_by_alm_type": "item.get_alm_item_type",
  "item.get_by_sticky": "item.get_alm_item_type_for_sticky",
  "session.get_status": "session.get_status",
  "session.get_iteration_sync_status": "session.get_iteration_status",
  "session.sync_iteration": "session.start_iteration_sync",
  "session.sync_program_backlog": "session.start_program_backlog_sync",
  "project_source.list": "get_project_sources",
  "flexboard.add": "add_board",
  "flexboard.update": "update_board",
  "flexboard.delete": "delete_board",
  "background.list": "get_flexboard_backgrounds",
  "board_type.list": "get_flexboard_types",
  "category.list": "get_categories",
  "category.add": "add_category",
  "category.delete": "remove_category",
  "category.update": "update_category",
  "flexboard.add_to_category": "add_board_to_category",
  "flexboard.remove_from_category": "remove_board_from_category",
  "session.list": "get_sessions",
  "settings.get": "get_settings",
  "team.list": "get_teams",
  "art.list": "get_arts",
  "link.list": "get_links",
  "link_type.list": "get_link_types",
  "board.list": "get_boards",
  "sticky_type.list": "get_sticky_types",
  "link.create": "add_link",
  "objective_link.create": "add_objective_link",
  "link.delete": "remove_link",
  "objective_link.delete": "remove_objective_link",
  "sticky.search": "search_stickies",
  "sticky.get_group_board_ids": "get_board_ids_of_grouped_stickies",
  "sticky.changes": "get_sticky_changes",
  "shape.add": "add_shape",
  "shape.edit": "edit_shape",
  "shape.remove": "remove_shape",
  "flexboard.duplicate": "duplicate_flexboard",
  "status.get_random_data": "get_random_data",
};

/**
 * A decorator that translates the given path to a new path using the reverseRpcTopicMapping object.
 * The decorator checks if the provided path exists in reverseRpcTopicMapping, and if it does,
 * it uses the new path as the first argument when calling the original method.
 * If the path is not found in reverseRpcTopicMapping, an error is thrown.
 *
 * @example
 * \@translateRPCPathDecorator
 * someMethod(path, arg1, arg2) {
 *   // ...implementation
 * }
 */
export function translateRPCPath(
  target: unknown,
  key: string,
  descriptor: PropertyDescriptor,
) {
  const originalMethod = descriptor.value;

  descriptor.value = function (path: string, ...restArgs: unknown[]) {
    if (!(path in reverseRpcTopicMapping)) {
      throw new Error(`We did not map ${path} to WAMP topic`);
    }
    const newPath = reverseRpcTopicMapping[path];
    return originalMethod.apply(this, [newPath, ...restArgs]);
  };

  return descriptor;
}
