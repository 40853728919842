<script setup lang="ts">
import { computed } from "vue";

import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import { dependencyLinkItems, useSearchMenuStore } from "@/store/searchMenu";

import DependencyLinkItemTooltip from "../tooltips/DependencyLinkItemTooltip.vue";
import SearchDropdown from "./SearchDropdown.vue";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedDependencyLinkItems,
  set: (values: Array<{ id: string }>) =>
    (store.selectedDependencyLink = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <SearchDropdown
    name="dependency-link"
    icon="action/link"
    label-key="dependency.stickies"
    :selected="selection.length > 0"
  >
    <template #tooltip>
      <DependencyLinkItemTooltip :items="selection" />
    </template>
    <SelectNamedValue
      v-model="selection"
      :values="dependencyLinkItems"
      :header="$t('dependency.stickies')"
      translate
    />
  </SearchDropdown>
</template>
