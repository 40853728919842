import { toggleActions } from "@/action/toggleActions";
import { BoardType } from "@/model/baseTypes";
import { BoardData } from "@/model/board";
import { Card } from "@/model/card";
import { RelativeCoordinate } from "@/model/coordinates";
import { Group } from "@/model/session";
import { StickyType } from "@/model/stickyType";
import { drawModeOpened } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { BoardLocation, fullBoardLocation } from "./location/BoardLocation";

export interface ContextInfo extends BoardContextActions {
  selection?: SelectionContextActions;
  region?: { name: string } & RegionContextActions;
}

export type ContextActions = SelectionContextActions & RegionContextActions;

type BoardContextAction = () => void;

interface BoardContextActions {
  syncProgramBacklog?: BoardContextAction;
  draw?: BoardContextAction;
}

interface SelectionContextActions {
  stickyMove?: boolean;
  link?: boolean;
  mirror?: boolean;
  team?: boolean;
}

interface RegionContextActions {
  arrange?: boolean;
  overview?: boolean;
  sync?: boolean;
  zoom?: boolean;
}

/* eslint-disable @typescript-eslint/class-literal-property-style */
export abstract class BaseBoard<T extends BoardType> {
  constructor(protected board: BoardData<T>) {}

  get groups(): Group[] {
    return [];
  }

  location(_pos: RelativeCoordinate | number, _top?: number): BoardLocation {
    return fullBoardLocation;
  }

  positionalCardProperties(_pos: RelativeCoordinate): Partial<Card> {
    return {};
  }

  get showPermanentLinks() {
    return false;
  }

  get showRiskyLinks() {
    return false;
  }

  abstract get possibleStickyTypes(): StickyType[];

  get creatableStickyTypes(): StickyType[] {
    return this.possibleStickyTypes;
  }

  abstract contextActions(_c?: RelativeCoordinate): ContextInfo;

  showDraw() {
    trackEvent(drawModeOpened("context-menu"));
    toggleActions.showDraw("contextMenu");
  }
}
