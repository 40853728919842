import { BoardType } from "@/model/baseTypes";
import { BoardData } from "@/model/board";

import { BacklogBoard } from "./BacklogBoard";
import { BaseBoard } from "./BaseBoard";
import { FlexBoard } from "./FlexBoard";
import { ProgramBoard } from "./ProgramBoard";
import { ProgramObjectivesBoard } from "./ProgramObjectivesBoard";
import { RiskBoard } from "./RiskBoard";
import { SolutionBacklogBoard } from "./SolutionBacklogBoard";
import { SolutionBoard } from "./SolutionBoard";
import { TeamBoard } from "./TeamBoard";

export function boardOf<T extends BoardType>(
  board?: BoardData<T>,
): BaseBoard<BoardType> {
  switch (board?.type) {
    case "backlog":
      return new BacklogBoard(board);
    case "program":
      return new ProgramBoard(board);
    case "solution_backlog":
      return new SolutionBacklogBoard(board);
    case "solution":
      return new SolutionBoard(board);
    case "risk":
      return new RiskBoard(board);
    case "team":
      return new TeamBoard(board);
    case "flex":
      return new FlexBoard(board);
    case "objectives":
      return new ProgramObjectivesBoard(board);
    case undefined:
      return new ProgramObjectivesBoard(board as any);
  }
}
