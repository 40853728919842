import { RelativeCoordinate } from "@/model/coordinates";
import { StickyType } from "@/model/stickyType";

import { BaseBoard, ContextInfo } from "./BaseBoard";

export class ProgramObjectivesBoard extends BaseBoard<"objectives"> {
  get possibleStickyTypes(): StickyType[] {
    return [];
  }

  contextActions(_c?: RelativeCoordinate): ContextInfo {
    return {};
  }
}
