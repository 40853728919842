<script setup lang="ts">
import { computed } from "vue";

import SelectNamedValue from "@/components/ui/SelectNamedValue/SelectNamedValue.vue";
import { useSearchMenuStore } from "@/store/searchMenu";

import TeamItemTooltip from "../tooltips/TeamItemTooltip.vue";
import SearchDropdown from "./SearchDropdown.vue";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedTeams,
  set: (values: Array<{ id: string }>) =>
    (store.selectedTeamIds = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <SearchDropdown
    name="team"
    icon="org/team"
    label-key="general.teams"
    :selected="selection.length > 0"
  >
    <template #tooltip>
      <TeamItemTooltip :teams="selection" />
    </template>
    <SelectNamedValue
      v-model="selection"
      :values="store.allTeams"
      :header="$t('general.teams')"
      scrollable
    />
  </SearchDropdown>
</template>
