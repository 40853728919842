import { sortBy } from "lodash-es";

import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { linkActions } from "@/action/linkActions";
import { sender } from "@/backend/Sender";
import { Drag, registerDrag } from "@/components/utils/Gestures";
import { sendStickyNoteAction } from "@/composables/useEventBus";
import { captureMessage } from "@/error/sentry";
import {
  boardSize,
  boardToRelative,
  boardToWindow,
  boardToWindowSimple,
  windowToBoard,
  windowToBoardSimple,
  windowToRelative,
} from "@/math/coordinate-systems";
import { plus, times } from "@/math/coordinates";
import { Card } from "@/model/card";
import {
  BoardCoordinate,
  Rectangle,
  WindowCoordinate,
  boardCoord,
  windowCoord,
} from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useDraggingStore } from "@/store/dragging";
import { useSelectionStore } from "@/store/selection";
import { useZoomStore } from "@/store/zoom";

import { sendCardMove } from "./animator";

interface CardDrag {
  origin: WindowCoordinate;
  boardSize: Rectangle<WindowCoordinate>;
  selectedCards: CardInfo[];
}

interface CardInfo {
  id: string;
  el: HTMLElement;
  offset: WindowCoordinate;
}

export function registerCardDrag(
  card: Card,
  onEnd: () => void,
  e: PointerEvent,
) {
  registerDrag<CardDrag>({}, e, {
    start(drag) {
      if (drag.type === "link") {
        drag.el = drag.imageEl = drag.target;
        drag.origin = windowCoord(drag.el.offsetLeft, drag.el.offsetTop);
        drag.start = windowCoord(0, 0);
        drag.el.style.display = "none";
        useDraggingStore().startLinkDragging(
          card.id,
          drag.pointerId,
          windowToRelative(windowCoord(e.clientX, e.clientY)),
        );
      } else {
        const windowPos = boardToWindow(
          boardCoord(drag.el.offsetLeft, drag.el.offsetTop),
        );

        // When dragging from palette, grab the center of the sticky note
        // (the normal logic causes a bug when the palette is outside the board)
        const { fromPalette } = useDraggingStore().findById(card.id) || {};
        drag.start = fromPalette
          ? windowCoord(0, 0)
          : plus(drag.start, windowPos);

        drag.boardSize = boardSize();
        const selected = useBoardStore().selectedStickyIds;
        const availableSelectedCards =
          !useSelectionStore().singleCard &&
          selected.length > 0 &&
          useBoardStore().isStickySelected(card.id)
            ? selected.map((id) => selectedCardInfo(id, drag))
            : [selectedCardInfo(card.id, drag)];
        const board = useBoardStore().currentBoard();
        // sort multiselected cards by z-index to keep their relative z-positions when moving them to the front
        drag.selectedCards = sortBy(
          availableSelectedCards.filter((card) => card !== null) as CardInfo[],
          (info) => {
            const cardOnBoard = board.cards[info.id];
            if (cardOnBoard) {
              return cardOnBoard.meta.zIndex;
            } else {
              void captureMessage(
                "Drag error - selected card not found on board",
                {
                  info: {
                    boardId: board.id,
                    cardId: info.id,
                    availableSelectedCards,
                  },
                },
              );
              return 0;
            }
          },
        );

        for (const card of drag.selectedCards) {
          const zoom = useZoomStore().factor;
          const distance = times(card.offset, zoom);
          const wc = windowCoord(e.clientX, e.clientY);
          const startPos = windowToRelative(
            plus(plus(drag.start, wc), distance),
          );

          boardActions.cardToFront("internal", card.id);
          sender.startAlterCard(board.id, card.id);
          useDraggingStore().startCardDragging(card.id, undefined, startPos);
        }
        cardActions.setPosition(
          "board",
          drag.selectedCards.map((c) => c.id),
        );
      }
      return true;
    },
    move(drag) {
      if (drag.origin) {
        const limitCoords = drag.type === "link" ? "none" : "output";
        useDraggingStore().dragLink(
          drag.pointerId,
          boardToRelative(windowToBoard(drag.pos, limitCoords)),
        );
        return drag.pos;
      } else {
        return doWithSelected(drag, dragCard);
      }
    },
    stop(drag) {
      if (drag.origin) {
        const val = { dragId: drag.pointerId, id: card.id, el: drag.el };
        linkActions.addByDrag("dragDrop", val);
        onEnd();
        return { ...drag.origin };
      } else {
        const pos = doWithSelected(drag, endDragCard);
        onEnd();
        return pos;
      }
    },
  });

  function selectedCardInfo(
    cardId: string,
    drag: Drag<CardDrag>,
  ): CardInfo | null {
    const el = document.getElementById(cardId);
    if (el) {
      return { id: cardId, el, offset: cardOffset(el, drag) };
    }
    return null;
  }

  function doWithSelected(
    drag: Drag<CardDrag> & CardDrag,
    action: (card: CardInfo, coordinate: BoardCoordinate) => void,
  ): BoardCoordinate {
    const pos = windowToBoard(drag.pos, "output", drag.selectedCards);
    for (const card of drag.selectedCards) {
      action(card, cardCoordinate(card, pos));
    }
    return pos;
  }

  function dragCard(card: CardInfo, coordinate: BoardCoordinate) {
    const pos = boardToRelative(coordinate);
    sendCardMove(card.id, useBoardStore().currentBoard().id, pos);
    useDraggingStore().dragCard(card.id, pos);
  }

  function endDragCard(card: CardInfo, coordinate: BoardCoordinate) {
    const pos = boardToRelative(coordinate);
    sendCardMove(card.id, useBoardStore().currentBoard().id, pos);
    if (useDraggingStore().dragging[card.id].fromPalette) {
      setTimeout(() => {
        sendStickyNoteAction(card.id, {
          action: "enlarge",
          focus: true,
          trigger: "after-create",
        });
      }, 100);
    }
    useDraggingStore().endCardDragging(card.id, pos);
  }
}

function cardOffset(el: HTMLElement, drag: Drag<CardDrag>) {
  return boardToWindowSimple(
    boardCoord(
      el.offsetLeft - drag.el.offsetLeft,
      el.offsetTop - drag.el.offsetTop,
    ),
  );
}

function cardCoordinate(card: CardInfo, pos: BoardCoordinate) {
  return plus(pos, windowToBoardSimple(card.offset));
}
