<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import StatusDot from "@/components/ui/StatusDot/StatusDot.vue";
import { StatusClass, statusClassNames } from "@/model/baseTypes";
import { statusClassColors } from "@/model/colors";
import { Iteration } from "@/model/session";
import { useSessionStore } from "@/store/session";
import {
  statusDistributionHidden,
  statusDistributionShown,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { formatShortDate } from "@/utils/dateFormat";

import type {
  StatusDistributionData,
  StatusDistributionSource,
} from "./StatusDistribution";
import { BarChartData, createBarChartData } from "./components/StackedBarChart";
import StackedBarChart from "./components/StackedBarChart.vue";

const sourceKeys: { [source in StatusDistributionSource]: string } = {
  iteration: /*$t*/ "statusDistribution.emptyIteration",
  objective: /*$t*/ "statusDistribution.emptyObjective",
  sticky: /*$t*/ "statusDistribution.emptySticky",
  team: /*$t*/ "statusDistribution.emptyTeam",
  art: /*$t*/ "statusDistribution.emptyArt",
};

const props = defineProps<{
  value: StatusDistributionData;
  sourceItemType: StatusDistributionSource;
  iteration?: Iteration;
  tooltipOffset?: [number, number];
}>();

const { t } = useI18n();

const chartData = computed(() => {
  return {
    ...createBarChartData(props.value, (key, value) => ({
      name: t(statusClassNames[key]),
      points: value.points,
      status: key,
      color: statusClassColors[key],
    })),
    totalPoints: [...props.value.values()].reduce(
      (sum, item) => sum + item.points,
      0,
    ),
  } as BarChartData<StatusClass, any> & { totalPoints: number };
});

const items = computed(() => [...chartData.value.items]);
const emptyMessage = computed(() => t(sourceKeys[props.sourceItemType]));
const today = computed(() => formatShortDate(new Date()));
const iterationNow = computed(() =>
  useSessionStore().iterationProgress(new Date(), props.iteration),
);
const iterationState = computed(() => {
  const passed = iterationNow.value.iterationsPassed;
  if (passed <= 0) {
    return t("statusDistribution.iteration.notStarted");
  }
  if (passed >= 1) {
    return t("statusDistribution.iteration.ended");
  }
  return t("statusDistribution.iteration", {
    day: Math.ceil(iterationNow.value.daysPassedInIteration),
    total: iterationNow.value.daysInIteration,
  });
});

// Triggered when the popup is shown
function onShow() {
  trackEvent(statusDistributionShown(props.sourceItemType));
}

// Triggered when the popup is hidden
function onHide() {
  trackEvent(statusDistributionHidden(props.sourceItemType));
}
</script>

<template>
  <div class="status-distribution" data-testid="status-distribution">
    <BaseTooltip
      class="status-tooltip"
      position="bottom"
      tabindex="0"
      trigger-role="group"
      :aria-label="$t('statusDistribution.status')"
      :aria="{ content: 'owns' }"
      :offset="props.tooltipOffset"
      @shown="onShow"
      @hidden="onHide"
    >
      <template #content>
        <div class="status-distribution-content">
          <div v-if="iteration" class="iteration">
            <div>{{ $t("statusDistribution.today", { today }) }}</div>
            <div>{{ iterationState }}</div>
          </div>
          <div v-if="chartData.total === 0" class="empty">
            {{ emptyMessage }}
          </div>
          <table v-else>
            <thead>
              <tr>
                <th>{{ $t("statusDistribution.status") }}</th>
                <th>{{ $t("statusDistribution.storyPoints") }}</th>
                <th>{{ $t("statusDistribution.workItems") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <th scope="row">
                  <div>
                    <div class="status-dot-container">
                      <StatusDot radar-type :status-class="item.status" />
                    </div>
                    <span>{{ item.name }}</span>
                  </div>
                </th>
                <td>
                  {{
                    $t("general.partOfTotal", {
                      part: item.points,
                      total: chartData.totalPoints,
                    })
                  }}
                </td>
                <td>
                  {{
                    $t("general.partOfTotal", {
                      part: item.value,
                      total: chartData.total,
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <StackedBarChart class="bar-chart-wrap" :value="chartData" />
    </BaseTooltip>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.status-distribution {
  display: flex;
  height: 100%;

  .status-tooltip {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }

  .bar-chart-wrap {
    display: inline-flex;
    width: 100%;
  }

  table {
    column-gap: 40px !important;
  }

  &-content {
    .iteration {
      white-space: pre-line;
      border-bottom: 1px solid colors-old.$status-distribution-header-text-color;
      padding-bottom: 0.5em;
    }

    .status-dot-container {
      width: 1.5em;
      height: 1.5em;
    }

    td,
    th[scope="row"] {
      font-size: font.$size-normal;
      padding-bottom: 4px;

      &:not(:first-child) {
        text-align: right;
      }

      &:first-child {
        & > div {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }

    td:not(:last-child),
    th:not(:last-child) {
      padding-right: 10px;
    }

    th:not([scope="row"]) {
      font-size: font.$size-small;
      font-weight: font.$weight-bold;
      color: colors-old.$status-distribution-header-text-color;
      padding-bottom: 8px;

      &:first-child {
        text-align: left;
      }
    }

    .empty {
      padding: 0.3em 1em;
      white-space: nowrap;
    }
  }
}
</style>
