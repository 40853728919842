<script setup lang="ts">
import { clamp } from "lodash-es";
import { provide, ref, watch } from "vue";

import { useNativeEvents } from "@/composables/useNativeEvents";
import { useBoardSizeStore } from "@/store/boardSize";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useSidePanelStore } from "@/store/sidePanel";

import { sidePanelKey } from "./injectKeys";

const MIN_WIDTH = 150;
const MAX_WIDTH = () => window.innerWidth / 2;
const DEFAULT_WIDTH = 330;

const props = defineProps<{
  active: boolean;
  side: "left" | "right";
  resizable?: boolean;
  name?: string;
}>();

const emit = defineEmits<{ close: [] }>();
const close = () => emit("close");

provide(sidePanelKey, { close });

const width = ref(0);
const startX = ref(0);

watch(
  () => props.active,
  (active) => setWidth(active ? initialWidth(props.name) : 0),
  { immediate: true },
);

function setWidth(w: number) {
  if (useSidePanelStore().setWidth(props.side, w, close)) {
    width.value = w;
    if (props.side === "left") {
      useBoardSizeStore().setMarginLeft(w);
    }
    if (props.name && w !== 0) {
      useClientSettingsStore().setPanelWidth(props.name, w);
    }
  }
}

function initialWidth(name?: string) {
  return useClientSettingsStore().panelWidth(name || "") || DEFAULT_WIDTH;
}

const { addEventListener, removeEventListeners } = useNativeEvents();

function startResize(event: PointerEvent) {
  startX.value = event.pageX - width.value;
  addEventListener(document, "pointermove", doResize);
  addEventListener(document, "pointerup", removeEventListeners);
}

function doResize(event: PointerEvent) {
  setWidth(clamp(event.pageX - startX.value, MIN_WIDTH, MAX_WIDTH()));
}
</script>

<template>
  <transition :name="'side-panel-' + side">
    <div
      v-if="active"
      class="side-panel"
      :style="{ [side]: 0, width: width + 'px' }"
    >
      <div class="content">
        <slot></slot>
      </div>
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <div v-if="resizable" class="resizer" @pointerdown="startResize" />
    </div>
  </transition>
</template>

<style scoped lang="scss">
@use "@/styles/variables" as vars;
@use "@/styles/variables/colors";
@use "@/styles/side-panel";

.side-panel {
  position: fixed;
  top: vars.$top-header-height;
  bottom: 0;
  background-color: colors.$background-ui;

  .content {
    width: 100%;
    height: 100%;
  }

  .resizer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
    cursor: ew-resize;
  }
}

.side-panel-left-enter-active,
.side-panel-left-leave-active,
.side-panel-right-enter-active,
.side-panel-right-leave-active {
  transition:
    transform ease-in-out side-panel.$panel-transition,
    opacity ease-in-out side-panel.$panel-transition;
}

.side-panel-left-enter-from,
.side-panel-left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.side-panel-right-enter-from,
.side-panel-right-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>
