<script setup lang="ts">
import { isAlmSync } from "@/backend/Backend";
import { formatTimestamp } from "@/utils/dateFormat";
import { isMac } from "@/utils/dom/dom";
import { build } from "@/utils/env/Environment";

import { enterKey, shiftKey } from "../utils/Shortcuts";
import ModalTab from "./ModalTab.vue";
import TabbedModal from "./TabbedModal.vue";
import ShortcutEntry from "./shortcuts/ShortcutEntry.vue";

const isWindows = !isMac;
</script>

<template>
  <TabbedModal
    :title="$t('shortcutsModal.title')"
    :description="$t('label.shortcutsModal.description')"
    class="shortcuts-modal"
    width="80%"
    set-return-focus="#user-actions-trigger"
  >
    <ModalTab :title="$t('shortcutsModal.tab.general')">
      <div
        id="panel-0"
        class="row"
        role="tabpanel"
        aria-labelledby="tab-0"
        tabindex="0"
      >
        <dl class="description-list-grid">
          <!-- We don't actually implement this, we're just letting users know about the system default -->
          <div class="col1">
            <ShortcutEntry
              :label="$t('shortcutsModal.general.fullScreen')"
              :windows="['F11']"
              :mac="['⌃', '⌘', 'F']"
              :is-windows="isWindows"
            />
            <ShortcutEntry
              :label="$t('shortcutsModal.general.closeDropdown')"
              :windows="[$t('key.escape')]"
              :mac="[$t('key.escapeMac')]"
              :is-windows="isWindows"
            />
            <ShortcutEntry
              :label="$t('shortcutsModal.general.closeOverlay')"
              :windows="[$t('key.escape')]"
              :mac="[$t('key.escapeMac')]"
              :is-windows="isWindows"
            />
            <ShortcutEntry
              :label="$t('shortcutsModal.general.closeSearch')"
              :windows="[$t('key.escape')]"
              :mac="[$t('key.escapeMac')]"
              :is-windows="isWindows"
            />
            <ShortcutEntry
              :label="$t('shortcutsModal.general.keyboardShortcuts')"
              :windows="['?']"
              :mac="['?']"
              :is-windows="isWindows"
            />
          </div>
          <div class="col2">
            <ShortcutEntry
              :label="$t('shortcutsModal.screenshot')"
              :windows="[$t('key.ctrl'), 'E']"
              :mac="['⌘', 'E']"
              :is-windows="isWindows"
            />
            <ShortcutEntry
              :label="$t('shortcutsModal.settings')"
              :windows="[$t('key.ctrl'), ',']"
              :mac="['⌘', ',']"
              :is-windows="isWindows"
            />
            <ShortcutEntry
              :label="$t('label.zoomIn')"
              :windows="[$t('key.ctrl'), '+']"
              :mac="['⌘', '+']"
              :is-windows="isWindows"
            />
            <ShortcutEntry
              :label="$t('label.zoomOut')"
              :windows="[$t('key.ctrl'), '-']"
              :mac="['⌘', '-']"
              :is-windows="isWindows"
            />

            <!-- Pan shortcut: Not yet implemented -->
            <!-- <ShortcutEntry
              :label="$t('shortcutsModal.general.pan')"
              :windows="[]"
              :mac="[]"
              :aria-label="
                $t('shortcutsModal.action.drag', { action: $t('key.space') })
              "
              :is-windows="isWindows"
            >
              <I18nT
                keypath="shortcutsModal.action.drag"
                tag="span"
                scope="global"
                aria-hidden="true"
              >
                <template #action>
                  <span class="key">{{ $t("key.space") }}</span>
                </template>
              </I18nT>
            </ShortcutEntry> -->
          </div>
          <div class="col3">
            <!-- Scale Stickies -->
            <ShortcutEntry
              :label="$t('action.scaleStickies')"
              :windows="[]"
              :mac="[]"
              :aria-label="
                $t('shortcutsModal.alternative', {
                  action1: $t('key.ctrl') + ' ' + $t('key.arrowUp'),
                  action2: $t('key.arrowDown'),
                })
              "
              :is-windows="isWindows"
            >
              <I18nT
                keypath="shortcutsModal.alternative"
                tag="span"
                scope="global"
                aria-hidden="true"
              >
                <template #action1>
                  <span class="key">
                    {{ isWindows ? $t("key.ctrl") : "⌘" }}
                  </span>
                  <span class="key">{{ isWindows ? "⌃" : "▲" }}</span>
                </template>
                <template #action2>
                  <span class="key">{{ isWindows ? "⌄" : "▼" }}</span>
                </template>
              </I18nT>
            </ShortcutEntry>

            <!-- Previous/Next Team Board -->
            <ShortcutEntry
              :label="$t('shortcutsModal.previousBoard')"
              :windows="[]"
              :mac="[]"
              :aria-label="
                $t('shortcutsModal.alternative', {
                  action1: $t('key.shift') + ' ' + $t('key.arrowUp'),
                  action2: $t('key.arrowDown'),
                })
              "
              :is-windows="isWindows"
            >
              <I18nT
                keypath="shortcutsModal.alternative"
                tag="span"
                scope="global"
                aria-hidden="true"
              >
                <template #action1>
                  <span class="key">{{ shiftKey() }}</span>
                  <span class="key">{{ isWindows ? "⌃" : "▲" }}</span>
                </template>
                <template #action2>
                  <span class="key">{{ isWindows ? "⌄" : "▼" }}</span>
                </template>
              </I18nT>
            </ShortcutEntry>
          </div>
        </dl>
      </div>
    </ModalTab>
    <ModalTab :title="$t('shortcutsModal.tab.stickies')">
      <div
        id="panel-1"
        class="row"
        role="tabpanel"
        aria-labelledby="tab-1"
        tabindex="0"
      >
        <dl class="description-list-grid">
          <div class="col1">
            <ShortcutEntry
              :label="$t('shortcutsModal.stickies.copy')"
              :windows="[$t('key.ctrl'), 'C']"
              :mac="['⌘', 'C']"
              :is-windows="isWindows"
            />

            <ShortcutEntry
              :label="$t('action.paste')"
              :windows="[$t('key.ctrl'), 'P']"
              :mac="['⌘', 'P']"
              :is-windows="isWindows"
            />

            <ShortcutEntry
              :label="$t('action.duplicate')"
              :windows="[$t('key.ctrl'), 'D']"
              :mac="['⌘', 'D']"
              :is-windows="isWindows"
            />

            <!-- Duplicate (Mouse Gesture) -- Uncomment when functionality is added -->
            <!-- <ShortcutEntry
              :label="$t('shortcutsModal.stickies.duplicateMouse')"
              :windows="[]"
              :mac="[]"
              :aria-label="
                $t('shortcutsModal.action.drag', { action: $t('key.alt') })
              "
              :is-windows="isWindows"
            >
              <I18nT
                keypath="shortcutsModal.action.drag"
                tag="span"
                scope="global"
                aria-hidden="true"
              >
                <template #action>
                  <span class="key">{{ altKey() }}</span>
                </template>
              </I18nT>
            </ShortcutEntry> -->

            <!-- New line (only available in non-alm-sync'd sessions)-->
            <ShortcutEntry
              v-if="!isAlmSync()"
              :label="$t('shortcutsModal.newLine')"
              :windows="[shiftKey(), enterKey()]"
              :mac="[shiftKey(), enterKey()]"
              :is-windows="isWindows"
            />
          </div>

          <div class="col2">
            <ShortcutEntry
              :label="$t('shortcutsModal.emojis')"
              :windows="[':', $t('shortcutsModal.emojiId'), ':']"
              :mac="[':', $t('shortcutsModal.emojiId'), ':']"
              :is-windows="isWindows"
            >
              <code class="italics" aria-hidden="true" data-t>
                :{{ $t("shortcutsModal.emojiId") }}:
              </code>
            </ShortcutEntry>

            <ShortcutEntry
              :label="$t('shortcutsModal.stickies.addBoardLink')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              <code class="italics" data-t>
                {{ $t("shortcutsModal.stickies.addBoardLink.shortcut") }}
              </code>
            </ShortcutEntry>

            <ShortcutEntry
              :label="$t('shortcutsModal.addingTeamBoardLink')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              <code data-t class="italics">
                #team/{{ $t("shortcutsModal.teamName") }}
              </code>
            </ShortcutEntry>

            <ShortcutEntry
              :label="$t('shortcutsModal.linkToCanvas')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              <code data-t class="italics">
                #canvas/{{ $t("shortcutsModal.canvasName") }}
              </code>
            </ShortcutEntry>

            <ShortcutEntry
              :label="$t('shortcutsModal.link')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              <code data-t class="italics">
                {{
                  $t("shortcutsModal.alternative", {
                    action1: "https://< url >",
                    action2: "http://< url >",
                  })
                }}
              </code>
            </ShortcutEntry>
          </div>

          <div class="col3">
            <ShortcutEntry
              :label="$t('shortcutsModal.focusSticky')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              {{ $t("shortcutsModal.focusSticky.shortcut") }}
            </ShortcutEntry>

            <ShortcutEntry
              :label="$t('shortcutsModal.selectMultiple')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              <!-- {{ $t("shortcutsModal.selectMultiple.shortcut") }} -->

              <I18nT
                keypath="shortcutsModal.actionHoverOverSticky"
                tag="dd"
                scope="global"
                :aria-label="
                  $t('label.shortcut', {
                    value: $t('shortcutsModal.actionHoverOverSticky', {
                      action: $t('key.shift'),
                    }),
                  })
                "
              >
                <template #action>
                  <span class="key" aria-hidden="true">
                    {{ $t("key.shift") }}
                  </span>
                </template>
              </I18nT>
            </ShortcutEntry>

            <!-- Open link modal (when 1+ sticky is selected)-->
            <ShortcutEntry
              :label="$t('shortcutsModal.stickies.link')"
              :windows="['L']"
              :mac="['L']"
              :is-windows="isWindows"
            />

            <!-- Open assignee dropdown (when 1 sticky is selected)-->
            <ShortcutEntry
              :label="$t('shortcutsModal.stickies.assign')"
              :windows="['A']"
              :mac="['A']"
              :is-windows="isWindows"
            />
          </div>
        </dl>
      </div>
    </ModalTab>
    <ModalTab :title="$t('shortcutsModal.tab.tools')">
      <div
        id="panel-2"
        class="row"
        role="tabpanel"
        aria-labelledby="tab-2"
        tabindex="0"
      >
        <dl class="description-list-grid">
          <div class="col1">
            <ShortcutEntry
              :label="$t('shortcutsModal.newStickyObjective')"
              :windows="['N']"
              :mac="['N']"
              :is-windows="isWindows"
            />

            <ShortcutEntry
              :label="$t('shortcutsModal.search')"
              :windows="[$t('key.ctrl'), 'F']"
              :mac="['⌘', 'F']"
              :is-windows="isWindows"
            />

            <ShortcutEntry
              :label="$t('shortcutsModal.tools.nextPreviousSearch')"
              :windows="['⌃', '⌄']"
              :mac="['▲', '▼']"
              :is-windows="isWindows"
            />

            <ShortcutEntry
              :label="$t('shortcutsModal.tools.switchModes')"
              :windows="[shiftKey(), 'M']"
              :mac="[shiftKey(), 'M']"
              :is-windows="isWindows"
            />

            <ShortcutEntry
              :label="$t('label.actionMenu.activity')"
              :windows="['H']"
              :mac="['H']"
              :is-windows="isWindows"
            />
          </div>
          <div class="col2">
            <!-- Show pointer trail -->
            <ShortcutEntry
              :label="$t('shortcutsModal.pointer')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              <I18nT
                keypath="shortcutsModal.pressing"
                tag="dd"
                scope="global"
                :aria-label="
                  $t('label.shortcut', {
                    value: $t('shortcutsModal.pressing', {
                      key: 'A',
                    }),
                  })
                "
              >
                <template #key>
                  <span class="key" data-t aria-hidden="true">A</span>
                </template>
              </I18nT>
            </ShortcutEntry>

            <ShortcutEntry
              :label="$t('shortcutsModal.pointerMode')"
              :windows="[shiftKey(), 'A']"
              :mac="[shiftKey(), 'A']"
              :is-windows="isWindows"
            />

            <ShortcutEntry
              :label="$t('shortcutsModal.tools.magnifierMode')"
              :windows="[]"
              :mac="[]"
              :is-windows="isWindows"
            >
              <I18nT
                keypath="shortcutsModal.actionHoverOverSticky"
                tag="dd"
                scope="global"
                :aria-label="
                  $t('label.shortcut', {
                    value: $t('shortcutsModal.actionHoverOverSticky', {
                      action: $t('key.space'),
                    }),
                  })
                "
              >
                <template #action>
                  <span class="key" aria-hidden="true">
                    {{ $t("key.space") }}
                  </span>
                </template>
              </I18nT>
            </ShortcutEntry>
          </div>
        </dl>
      </div>
    </ModalTab>
    <div class="version">
      {{
        $t("version.info", {
          id: build.id,
          hash: build.hash,
          time: formatTimestamp(build.time),
        })
      }}
    </div>
  </TabbedModal>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/shadow";

.shortcuts-modal {
  .tabs-header {
    text-align: center;
  }

  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;

    .description-list-grid {
      grid-auto-columns: 1fr;
      gap: 12px 80px;
      grid-auto-flow: column;
      padding: 0 25px;

      // .col{n} classes are used to separate columns
      @each $i in 1, 2, 3 {
        .col#{$i} {
          display: contents;

          > .shortcut-entry {
            grid-column: #{$i};
          }
        }
      }
    }
  }

  .col {
    width: calc(100% / 3 - 50px);

    @media (width <= 1200px) {
      width: calc(50% - 50px);
    }

    @media (width <= 800px) {
      width: 100%;
    }
  }

  dl,
  dt,
  dd {
    display: flex;
    align-items: center;
    font-size: font.$size-small;
  }

  dl {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    justify-content: space-between;
    min-height: 30px;

    dd {
      justify-self: flex-end;
      color: colors-old.$text-secondary-color;
      white-space: nowrap;

      code {
        padding: 0.5em 0.9em;
        background-color: colors-old.$light-background-color;
        border-radius: 6px;
      }
    }

    p {
      color: colors-old.$placeholder-color;
      font-size: font.$size-normal;
    }

    .italics {
      font-style: italic;
    }
  }

  .shortcut-entry {
    display: grid;
    grid-template-columns: 1fr 1fr;

    span:not(.key) {
      color: colors.$text-subtle;
    }

    span.key,
    code {
      color: colors.$text;
    }
  }

  .key {
    display: inline-block;
    border: 1px solid colors-old.$shortcut-border-color;
    font-size: font.$size-smaller;
    line-height: initial;

    @include shadow.shortcut-button;

    border-radius: 4px;
    padding: 0.5em 0.9em;
    margin: 0 0.3em;
  }

  .version {
    width: 100%;
    text-align: right;
    font-size: 60%;
    color: colors-old.$text-secondary-color;
    user-select: text;
    margin-top: 10px;
  }
}
</style>
