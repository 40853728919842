<script lang="ts" setup>
import { computed } from "vue";

import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import { boardKey } from "@/components/board/injectKeys";
import SetStoryPoints from "@/components/card/components/SetStoryPoints/SetStoryPoints.vue";
import { cardKey } from "@/components/card/injectKeys";
import { isBacklogBoard } from "@/model/board";
import { isWorkitem } from "@/model/stickyType";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/vue";

import { toElipsis } from "../../utils";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const value = computed(() => card.points + "");
const visible = computed(() => {
  // show it only for work items
  if (!isWorkitem(card)) return false;
  // shot it only to for stickies that were created on the team board
  if (card.type.origin !== "team") return false;
  // hide it on backlog board
  if (isBacklogBoard(board.value.type)) return false;
  // show it only in execution mode
  return useWorkModeStore().isExecutionMode;
});
</script>

<template>
  <ActionMenuItem
    v-if="visible"
    width="auto"
    name="story-points"
    :tooltip-text="$t('statusDistribution.storyPoints') + ': ' + value"
    :aria-label="$t('label.actionMenu.storyPoints')"
  >
    <span
      class="story-points-item"
      :aria-label="$t('statusDistribution.storyPoints') + ': ' + value"
    >
      {{ toElipsis(value) }}
    </span>
    <template #dropdown>
      <SetStoryPoints v-focus-trap trigger="action-menu" />
    </template>
  </ActionMenuItem>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/typography";
@use "@/styles/variables/colors";

.story-points-item {
  @include typography.small("bold");

  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: colors.$background-ui;
  border-radius: 4px;
  color: colors.$text;
}
</style>
