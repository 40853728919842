import { Options, mixins } from "vue-class-component";

import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import { Board } from "@/model/board";
import { boardActivated, boardDeactivated } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { applyFullBoardSize } from "../utils/boardSize";

@Options({})
export default class BoardBase extends mixins(ShortcutUser, EventBusUser) {
  active = false;

  // must be overridden by subclasses
  get board(): Board {
    return {} as Board;
  }

  activated() {
    this.applyBoardSize();
    this.active = true;
    this.trackBoardEvent(boardActivated);
  }

  deactivated() {
    this.active = false;
    this.trackBoardEvent(boardDeactivated);
  }

  applyBoardSize() {
    applyFullBoardSize();
  }

  // This must be callable by the parent component
  focus() {
    this.$el?.focus();
  }

  /**
   * Tracks the given board event (boardActivated or boardDeactivated)
   */
  trackBoardEvent(boardEvent: typeof boardActivated | typeof boardDeactivated) {
    const teamId = "team" in this.board ? this.board.team?.id : undefined;
    trackEvent(
      boardEvent({
        type: this.board.type,
        id: this.board.id,
        artId: this.board.artId,
        teamId,
      }),
    );
  }
}
