import { captureException } from "@sentry/vue";
import { isString, throttle } from "lodash-es";
import { RouteLocationNamedRaw, isNavigationFailure } from "vue-router";

import { internalActions } from "@/action/internalActions";
import { BoardType } from "@/model/baseTypes";
import { Art, Team } from "@/model/session";
import { UserOrgEntities } from "@/model/user";
import { getOrgEntities } from "@/services/user.service";
import { useActivityStore } from "@/store/activity";
import { useArtStore } from "@/store/art";
import { useNavigationStore } from "@/store/navigation";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";
import { useZoomStore } from "@/store/zoom";

import { getRouter } from ".";
import {
  BoardTarget,
  LogoutReason,
  Query,
  QueryImpl,
  currentTeam,
  toQueryImpl,
} from "./types";

export function navigateBack() {
  switch (currentRoute().name) {
    case "settings":
    case "app":
      internalActions.leaveBoard();
      useActivityStore().inactivate();
      return goTo("session");
    case "session":
      return goTo("logout");
  }
}

export async function goToLogin() {
  try {
    return await getRouter().syncPush(() => ({ name: "login" }));
  } catch (fail) {
    return handleRouterFail(fail);
  }
}

export function goToLogout(reason: LogoutReason = "unknown") {
  return goTo("logout", { reason });
}

export function goToSession() {
  return goTo("session");
}

export async function replaceQuery(query: Query) {
  return getRouter()
    .syncReplace(() => {
      const current = currentRoute().query as QueryImpl;
      const newQuery: QueryImpl = {
        zoom: query.zoom || current.zoom,
        scrollX: query.scrollX || current.scrollX,
        scrollY: query.scrollY || current.scrollY,
        searchText:
          query.search?.text !== undefined
            ? query.search.text // keep empty search text to keep search dialog open
            : current.searchText,
        stickyId: "stickyId" in query ? query.stickyId : current.stickyId,
        searchFlag:
          query.search?.flag !== undefined
            ? query.search.flag || undefined
            : current.searchFlag,
        searchAssignee:
          query.search?.assignee !== undefined
            ? query.search.assignee || undefined
            : current.searchAssignee,
        searchTeam:
          query.search?.team !== undefined
            ? query.search.team || undefined
            : current.searchTeam,
        searchArt:
          query.search?.art !== undefined
            ? query.search.art || undefined
            : current.searchArt,
        searchIteration:
          query.search?.iteration !== undefined
            ? query.search.iteration || undefined
            : current.searchIteration,
        searchType:
          query.search?.type !== undefined
            ? query.search.type || undefined
            : current.searchType,
        searchDepLink:
          query.search?.depLink !== undefined
            ? query.search.depLink || undefined
            : current.searchDepLink,
        searchStatusClass:
          query.search?.statusClass !== undefined
            ? query.search.statusClass || undefined
            : current.searchStatusClass,
        searchLink:
          query.search?.link !== undefined
            ? query.search.link || undefined
            : current.searchLink,
        searchPos: query.search
          ? query.search.pos || undefined
          : current.searchPos,
        feature: current.feature,
        category: "category" in query ? query.category : current.category,
      };

      return {
        query: {
          ...current,
          ...(newQuery as Record<string, string | undefined>),
        },
      };
    })
    .catch(handleRouterFail);
}

const throttledReplaceQuery = throttle(() => {
  void replaceQuery({
    zoom: "" + useZoomStore().factor,
    scrollX: "" + Math.round(window.scrollX),
    scrollY: "" + Math.round(window.scrollY),
  });
}, 100);

export function pushZoomState() {
  if (useZoomStore().zooming) {
    return;
  }

  throttledReplaceQuery();
}

export function goToBoard(board: BoardTarget, query?: QueryImpl) {
  getRouter()
    .syncPush(() => mergeParams(boardParams(board), query))
    .catch(handleRouterFail);
}

export function boardUrl(
  session: string | undefined,
  board: BoardType,
  spec?: string,
  query?: string,
) {
  const params = boardParams({
    type: board,
    team: board === "team" && spec ? { id: "", name: spec } : undefined,
    name: board === "flex" && spec ? spec : undefined,
  });
  if (!params.team) {
    params.team = currentTeam;
  }
  if (session) {
    params.session = session;
  }
  const queryImpl = query
    ? toQueryImpl(new URLSearchParams(query).entries())
    : {};
  const router = getRouter();

  return router.resolve(mergeParams(params, queryImpl));
}

export function fullUrl(query: QueryImpl) {
  const base = window.location.origin + window.location.pathname;
  const search = Object.entries(query)
    .map(([key, value]) => key + "=" + value)
    .join("&");
  return base + "#" + currentRoute().path + "?" + search;
}

export function currentRoute() {
  return getRouter()?.currentRoute?.value;
}

export function getTargetPageName(target: { path: string }) {
  const route = getRouter().resolve(target);
  if (route.name !== "app") {
    return route.name;
  }
  return route.params.board || "unknown";
}

function handleRouterFail(fail?: unknown) {
  if (!isNavigationFailure(fail)) {
    return Promise.reject(fail);
  }
}

export async function goToApp() {
  const selectedSession = useSessionStore().session.selected;
  if (!selectedSession) {
    void captureException(new Error("No selected session when opening app"));
    return goTo("session");
  }

  const session = useSessionStore().uniqueSessionName(selectedSession);
  const user = useUserStore().technicalUser;

  try {
    switch (user.role) {
      case "observer":
      case "user":
        await handleUser(session);
        break;

      case "admin":
      case "planning_interval_admin":
        await handleAdmin(session, user);
        break;

      default:
        throw new Error(`Unhandled role: ${user.role}`);
    }
  } catch (fail) {
    return handleRouterFail(fail);
  }
}

async function handleUser(session: string) {
  const userTeams = useUserStore().technicalUser.teams;
  const team = entitiesInCurrentSession(useTeamStore().teams, userTeams)[0]; // team should always exist
  setupArtAndTeam(team);
  return navigateToApp({ session, board: "team", team: team.name });
}

async function handleAdmin(session: string, user: { id: string }) {
  const { team: userTeams, art: userArts } = fullOrgEntities(
    await getOrgEntities(user.id),
  );

  if (userArts.length) {
    const art = userArts[0];
    const team =
      userTeams.find((t) => t.artId === art.id) ??
      useTeamStore().teamsInArt(art.id)[0];
    setupArtAndTeam(team, art);
    return navigateToApp({ session, board: "program", team: team.name });
  } else if (userTeams.length) {
    return handleUser(session);
  } else {
    const firstArt = useArtStore().arts[0];
    const team = useTeamStore().teamsInArt(firstArt.id)[0];
    setupArtAndTeam(team, firstArt);
    return navigateToApp({ session, board: "program", team: team.name });
  }
}

function setupArtAndTeam(team: Team, art?: Art) {
  const selectedArt = art || useArtStore().artById(team.artId);
  if (selectedArt) useArtStore().setArt(selectedArt);
  useTeamStore().selectTeam(team);
  useTeamStore().team.current = team;
}

function fullOrgEntities(orgEntities: UserOrgEntities) {
  return {
    team: entitiesInCurrentSession(useTeamStore().teams, orgEntities.team),
    art: entitiesInCurrentSession(useArtStore().arts, orgEntities.art),
  };
}

function entitiesInCurrentSession<
  T extends { id: string },
  S extends { id: string },
>(allEntities: T[], userEntities: S[]): T[] {
  const userEntityIds = new Set(userEntities.map((e) => e.id));
  return allEntities.filter((entity) => userEntityIds.has(entity.id));
}

async function navigateToApp(params: {
  session: string;
  board: string;
  team?: string;
}) {
  try {
    await getRouter().syncPush(() => ({
      name: "app",
      params,
      query: { feature: currentRoute().query.feature, searchText: "" },
    }));
  } catch (fail) {
    return handleRouterFail(fail);
  }
}

async function goTo(name: string, query?: Record<string, string>) {
  try {
    return await getRouter().syncPush(() => {
      return {
        name,
        query: { feature: currentRoute().query.feature, ...query },
      };
    });
  } catch (fail) {
    return handleRouterFail(fail);
  }
}

function mergeParams(
  params: Record<string, string>,
  query?: QueryImpl,
): RouteLocationNamedRaw {
  return {
    params: {
      ...currentRoute().params,
      ...{ name: undefined },
      ...params,
    } as unknown as Record<string, string>, // the typing of vue-router is wrong: params can be undefined to remove them from the route
    query: { ...currentRoute().query, zoom: "1", ...query },
  };
}

function boardParams(target: BoardTarget): Record<string, string> {
  if (isString(target)) {
    return { board: target };
  }
  if (target.type === "flex") {
    const name = target.name;
    return { board: "flex", ...(name && { name }) };
  } else {
    const team = target.team?.name || teamInArt(target.artId);
    return { board: target.type, ...(team && { team }) };
  }

  function teamInArt(artId?: string) {
    if (artId) {
      const teamId = useNavigationStore().lastSelectedTeamId(artId);
      const teams = useTeamStore().teams;
      const team =
        teams.find((team) => team.id === teamId) ||
        teams.find((team) => team.artId === artId);
      return team?.name;
    }
  }
}
