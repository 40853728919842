<script setup lang="ts">
import { sortBy } from "lodash-es";
import { computed } from "vue";

import SelectUser from "@/components/SelectUser/SelectUser.vue";
import { AuthUser } from "@/model/user";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useUserStore } from "@/store/user";

import AssigneeItemTooltip from "../tooltips/AssigneeItemTooltip.vue";
import SearchDropdown from "./SearchDropdown.vue";

const store = useSearchMenuStore();
const currentUser = useUserStore().currentUser;

const selection = computed({
  get: () => store.selectedAssignees,
  set: (values: Array<{ id: string }>) => {
    store.selectedAssigneeIds = new Set(values.map((val) => val.id));
  },
});

/**
 * Returns all users currently assigned to any card on this board,
 * with the current user listed first
 */
const currentBoardAssignees = computed(() => {
  const users = store.allAssignees;
  const currentUserFirst = (user: AuthUser) => user.id !== currentUser?.id;

  return sortBy(users, [currentUserFirst]);
});
</script>

<template>
  <SearchDropdown
    name="assignee"
    icon="general/user"
    label-key="general.assignees"
    :selected="selection.length > 0"
  >
    <template #tooltip>
      <AssigneeItemTooltip :assignees="selection" />
    </template>
    <SelectUser
      v-model="selection"
      :values="currentBoardAssignees"
      :header="$t('general.assignees')"
      scrollable
    />
  </SearchDropdown>
</template>
