import MirrorModal from "@/components/modal/MirrorModal.vue";
import TeamModal from "@/components/modal/TeamModal.vue";
import StickyLinkModal from "@/components/modal/stickyLink/StickyLinkModal.vue";
import { namedKey, registerActionShortcut } from "@/components/utils/Shortcuts";
import { useBoardStore } from "@/store/board";
import { useModalStore } from "@/store/modal";
import { useSelectionStore } from "@/store/selection";
import { selectOnClickActivated } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import {
  action,
  defineActions,
  getActionTrigger,
  previewAction,
} from "./actions";

export const selectionActions = defineActions("selection", {
  selectOnClick: action(selectOnClick, {
    name: /*$t*/ "action.selectStickies",
    shortcut: namedKey("Shift"),
    icon: "selection/group",
  }),
  alignHorizontal: previewAction(
    (mode) => useSelectionStore().alignHorizontal(mode),
    {
      name: /*$t*/ "action.alignHorizontal",
      icon: "selection/align-horizontal",
    },
  ),
  alignVertical: previewAction(
    (mode) => useSelectionStore().alignVertical(mode),
    {
      name: /*$t*/ "action.alignVertical",
      icon: "selection/align-vertical",
    },
  ),
  distributeHorizontal: previewAction(
    (mode) => useSelectionStore().distributeHorizontal(mode),
    {
      name: /*$t*/ "action.distributeHorizontal",
      icon: "selection/distribute-horizontal",
    },
  ),
  distributeVertical: previewAction(
    (mode) => useSelectionStore().distributeVertical(mode),
    {
      name: /*$t*/ "action.distributeVertical",
      icon: "selection/distribute-vertical",
    },
  ),
  lineup: previewAction((mode) => useSelectionStore().lineup(mode), {
    name: /*$t*/ "action.lineup",
    icon: "selection/grid",
  }),
  link: action(
    () => {
      const cardIds = useBoardStore().selectedStickyIds;
      if (cardIds.length) {
        useModalStore().open(StickyLinkModal, { attrs: { cardIds } });
      }
    },
    {
      name: /*$t*/ "action.linkMultiple",
      icon: "action/link",
      shortcut: namedKey("KeyL"),
    },
  ),
  mirror: action(() => useModalStore().open(MirrorModal), {
    name: /*$t*/ "action.mirrorMultiple",
    icon: "action/mirror",
  }),
  setTeam: action(() => useModalStore().open(TeamModal), {
    name: /*$t*/ "action.team",
    icon: "org/team",
  }),
  undo: action(() => useSelectionStore().undo(), { icon: "arrow/undo" }),
});

/**
 * Register the link shortcut ('L')
 */
export function registerSelectionActions() {
  registerActionShortcut("", selectionActions.link, {});
}

function selectOnClick(trigger?: "board-actions-menu") {
  const isActive = useSelectionStore().selecting === "click";
  if (!isActive) {
    const t = trigger || getActionTrigger(selectOnClick);
    trackEvent(selectOnClickActivated(t));
  }

  useSelectionStore().toggleSelectOnClick();
}
