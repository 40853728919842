<script lang="ts" setup>
import { computed, nextTick, ref, watch } from "vue";

import { cardActions } from "@/action/cardActions";
import { isAlmSync } from "@/backend/Backend";
import { cardKey } from "@/components/card/injectKeys";
import { accept } from "@/components/utils/Shortcuts";
import { stickyNoteTextChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { eventTarget } from "@/utils/dom/dom";
import { removeNonPrintable } from "@/utils/general";
import { replaceEmoji } from "@/utils/text/emojiReplacer";
import { injectStrict } from "@/utils/vue";

defineProps<{ fontSize: number }>();

const textareaRef = ref<HTMLTextAreaElement>();
defineExpose({
  focus: () => textareaRef.value?.focus(),
  element: () => textareaRef.value,
});

const emit = defineEmits(["deactivateNote"]);

const card = injectStrict(cardKey);

// used for analytics
watch(textareaRef, (el) => {
  if (el) {
    const initialTextLength = el.value.length;
    el.addEventListener(
      "input",
      () => {
        const cardType = card.type.functionality;
        trackEvent(stickyNoteTextChanged(cardType, initialTextLength));
      },
      { once: true },
    );
  }
});

const maxTextLength = computed(() => (isAlmSync() ? 255 : 640));

const handleKeydown = (event: KeyboardEvent) => {
  if (accept(cardActions.duplicate.data.shortcut!.key)(event)) {
    cardActions.duplicate("keyboard");
    event.preventDefault();
  } else if (
    event.key === "Escape" ||
    (event.key === "Enter" &&
      (isAlmSync() || (!event.altKey && !event.shiftKey)))
  ) {
    event.preventDefault();
    event.stopPropagation();

    emit("deactivateNote");
    textareaRef.value?.blur();
  }
};

const handleTextInput = (event: Event) => {
  const target = eventTarget<HTMLTextAreaElement>(event)!;
  if (isAlmSync()) {
    target.value = removeNonPrintable(target.value);
  }
  replaceEmojis(target);
  cardActions.setText("card", card.id, target.value);
};

function replaceEmojis(element: HTMLTextAreaElement) {
  const replaced = replaceEmoji(element.value);
  if (replaced) {
    const cursor = element.selectionStart;
    element.value = replaced.text;
    void nextTick(() => {
      // restore the cursor position
      element.selectionStart = cursor - replaced.deltaLen;
      element.selectionEnd = cursor - replaced.deltaLen;
    });
  }
}
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
  <textarea
    ref="textareaRef"
    class="sticky-note-text-input"
    spellcheck="true"
    :maxlength="maxTextLength"
    :value="card.text"
    :style="{ fontSize: fontSize + '%' }"
    @input="handleTextInput"
    @keydown="handleKeydown"
    @pointerdown.stop
  />
</template>

<style lang="scss" scoped>
.sticky-note-text-input {
  all: unset;
  line-height: 1.25;
  cursor: text;
  pointer-events: auto;
  background-color: transparent;
  user-select: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  word-wrap: break-word;
}
</style>
