/** coordinate systems:
 - window (center of a card), delivered by mouse input
 - card (scaled by zoom, relative to a certain card)
 - board (scaled by zoom, limited by border for the top left corner of a card, used to draw card)
 - relative (0..1 for the center of a card, used for backend)
 */
export type WindowCoordinate = Coordinate & { _type: "window" };
export type BoardCoordinate = Coordinate & { _type: "board" };
export type RelativeCoordinate = Coordinate & { _type: "relative" };
export type AnyCoordinate =
  | WindowCoordinate
  | BoardCoordinate
  | RelativeCoordinate;

interface Coordinate {
  x: number;
  y: number;
}

export type CoordinateComponent = "x" | "y";

export interface Rectangle<T extends AnyCoordinate> {
  p0: T;
  p1: T;
}

export type Line<T extends AnyCoordinate> = Rectangle<T>;

export type LineComponent = "p0" | "p1";

export function oppositeLineComponent(c: LineComponent) {
  return c === "p0" ? "p1" : "p0";
}

export function isCoordinate(c?: unknown): c is Coordinate {
  return !!c && (c as Coordinate).x !== undefined;
}

export function clientCoord(elem: MouseEvent): WindowCoordinate {
  return windowCoord(elem.clientX, elem.clientY);
}

export function offsetCoord(e: MouseEvent): WindowCoordinate {
  return windowCoord(e.offsetX, e.offsetY);
}

export function scrollCoord(): WindowCoordinate {
  return windowCoord(window.scrollX, window.scrollY);
}

export function centerCoord() {
  return windowCoord(window.innerWidth / 2, window.innerHeight / 2);
}

export function windowSize() {
  return windowCoord(
    document.documentElement.clientWidth,
    document.documentElement.clientHeight,
  );
}

export function boundsCoord(elem: HTMLElement): WindowCoordinate {
  const { left, top } = elem.getBoundingClientRect();
  return windowCoord(left, top);
}

export function relativeCoord(x: number, y: number): RelativeCoordinate {
  return { x, y } as RelativeCoordinate;
}

export function windowCoord(x: number, y: number): WindowCoordinate {
  return { x, y } as WindowCoordinate;
}

export function boardCoord(x: number, y: number): BoardCoordinate {
  return { x, y } as BoardCoordinate;
}

export const offScreen = relativeCoord(-10, -10);

export function isOffScreen(c: RelativeCoordinate) {
  return c.x === offScreen.x && c.y === offScreen.y;
}
