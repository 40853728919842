<script setup lang="ts">
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { injectStrict } from "@/utils/vue";

import { sidePanelKey } from "./injectKeys";

defineProps<{ title?: string }>();

const { close } = injectStrict(sidePanelKey);
</script>

<template>
  <aside
    v-scrollable-on-board
    class="side-panel-content"
    aria-labelledby="side-panel-title"
    @click.stop
  >
    <div v-if="title" class="header">
      <h4 id="side-panel-title" style="flex-grow: 1">{{ title }}</h4>
      <IconButton
        icon="base/close"
        data-testid="side-panel-close"
        :aria-label="$t('general.close')"
        @click="close"
      />
    </div>
    <hr aria-hidden="true" />
    <template v-if="$slots['sub-title']">
      <IconButton
        v-if="!title"
        icon="base/close"
        class="close-button"
        :aria-label="$t('general.close')"
        @click="close"
      />
      <div class="sub-header">
        <slot name="sub-title" />
      </div>
      <hr aria-hidden="true" />
    </template>
    <div class="content scrollable">
      <slot></slot>
    </div>
  </aside>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/side-panel" as *;
@use "@/styles/mixins/shadow";
@use "@/styles/side-panel";
@use "@/styles/mixins/typography";

.side-panel-content {
  @include side-panel;
  @include shadow.default;

  .high-contrast-mode & {
    border-left: 2px solid colors-a11y.$divider-color;
  }

  .header {
    padding: 6px 6px 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    color: colors-old.$text-primary-color;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
  }

  .sub-header {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 1em;
    color: colors-old.$text-secondary-color;

    svg {
      flex-shrink: 0;
    }
  }

  .content {
    overflow-y: auto;
  }

  > .content {
    flex-grow: 1;
  }

  .scrollable {
    position: relative;
  }
}
</style>
