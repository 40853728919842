export interface ElementState {
  elements: Array<{
    element: HTMLElement;
  }>;
  activeElement: HTMLElement | null;
}

/**
 * Hides the specified elements by setting their display property to "none".
 * Returns the state of the elements before hiding.
 *
 * @param elements - The list of elements to hide.
 * @returns The state of the elements before hiding.
 */
export function hideElements(elements: NodeListOf<HTMLElement>): ElementState {
  const state: ElementState = {
    elements: [],
    activeElement: document.activeElement as HTMLElement | null,
  };

  elements.forEach((element) => {
    element.style.display = "none";

    if (
      !element.classList.contains("moving") &&
      !element.classList.contains("deleting")
    ) {
      state.elements.push({ element });
    }
  });

  return state;
}

/**
 * Shows the elements in the given state by setting their display style to an empty string.
 * If there is an active element in the state, it will be focused.
 *
 * @param state - The element state containing the elements to be shown.
 */
export function showElements(state: ElementState) {
  state.elements.forEach((item) => {
    item.element.style.display = "";
  });

  // only focus when the sticky note is inside the viewport
  const stickyNoteEl = state.activeElement?.closest(
    ".sticky-note",
  ) as HTMLElement | null;

  if (!stickyNoteEl) return;
  if (!isElementInViewport(stickyNoteEl)) return;

  state.activeElement?.focus?.();
}

export function isBrowserTabActive() {
  return document.visibilityState !== "hidden";
}

export function createTspan(text: string, x: number, y: number) {
  const tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
  tspan.appendChild(document.createTextNode(text));
  tspan.setAttribute("x", "" + x);
  tspan.setAttribute("y", "" + y);
  return tspan;
}

export async function fontsLoaded() {
  const fonts = document.fonts;
  return fonts ? fonts.ready : Promise.resolve();
}

export function terminateEvent(event: Event) {
  event.stopPropagation();
  event.preventDefault();
}

export function addFontFamily(el: HTMLElement, fontFamily: string) {
  fontFamily = `"${fontFamily}"`;
  const fonts = getComputedStyle(el).fontFamily.split(/,\s*/);
  if (!fonts.includes(fontFamily)) {
    // add it at second position: behind default font, but before all fallbacks
    fonts.splice(1, 0, fontFamily);
    el.style.fontFamily = fonts.join(",");
  }
}

/**
 * Checks if the scrollbars are visible in the current document.
 *
 * @returns An object with properties `x` and `y` indicating if
 * the horizontal and vertical scrollbars are visible, respectively.
 */
export function isScrollbarVisible(): { x: boolean; y: boolean } {
  const documentElement = document.documentElement;
  const hasVerticalScrollbar =
    documentElement.scrollHeight > documentElement.clientHeight;
  const hasHorizontalScrollbar =
    documentElement.scrollWidth > documentElement.clientWidth;

  return { x: hasHorizontalScrollbar, y: hasVerticalScrollbar };
}

/**
 * This function creates a temporary block element and calculates the size
 * of the scrollbar by comparing the width of the block element with and
 * without the scrollbar.
 *
 * @returns The size of the scrollbar in pixels.
 */
export function getScrollbarSize() {
  // Create a temporary block element
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // Force scrollbar to appear
  document.body.appendChild(outer);

  // Create an inner div and append it to the outer div
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculate the scrollbar sizes
  const size = outer.offsetWidth - inner.offsetWidth;

  // Remove the temporary elements from the body
  outer.parentNode!.removeChild(outer);

  return size;
}

export function isElementInViewport(el?: HTMLElement): boolean {
  if (!el) return false;

  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const isMac = navigator.platform.includes("Mac");

export const lineSeparator =
  navigator.userAgent.indexOf("Windows") < 0 ? "\n" : "\r\n";

export function whenChildElementWithClass(
  element: Element,
  className: string,
  action: (child: Element) => void,
) {
  const child = element.querySelector(`.${className}`);
  if (child) {
    action(child);
  } else {
    const observer = new window.MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof Element && node.classList.contains(className)) {
            observer.disconnect();
            action(node);
          }
        });
      }
    }, true);
    observer.observe(element, { childList: true });
    setTimeout(() => observer.disconnect(), 10000);
  }
}

export function eventTarget<T extends HTMLElement>(e: Event): T | null {
  return e.target as T;
}

export function isInput(el: HTMLElement) {
  return ["INPUT", "TEXTAREA", "SELECT"].includes(el.nodeName);
}
