import { defineStore } from "pinia";

import { IdMap } from "@/model/baseTypes";
import { BoardData } from "@/model/board";
import { FlexBackground, FlexType } from "@/model/flexboard";

import { useBoardsStore } from "./boards";
import { useNavigationStore } from "./navigation";

export const useFlexStore = defineStore("flex", {
  state: () => ({
    currentFlexBoard: null as BoardData<"flex"> | null,
    flexBackgrounds: {} as IdMap<FlexBackground>,
    flexTypes: [] as FlexType[],
  }),
  getters: {
    flexBoards: () =>
      Object.values(useBoardsStore().boards).filter(
        (board) => board.type === "flex",
      ),

    flexBoardByName() {
      return (name: string) =>
        this.flexBoards.find((board) => board.name === name);
    },

    flexBoardById() {
      return (id: string) => this.flexBoards.find((board) => board.id === id);
    },

    flexBoardsByType() {
      return (type: string) =>
        this.flexBoards.filter((board) => board.flexType.id === type);
    },

    infoLink(state) {
      return (flexType: FlexType) =>
        state.flexBackgrounds[flexType.background]?.infoLink;
    },
  },
  actions: {
    setCurrentFlexBoard(flex: BoardData<"flex">) {
      useNavigationStore().flexSelected(flex);
      this.currentFlexBoard = flex;
    },
    setFlexBackgrounds(backgrounds: FlexBackground[]) {
      this.flexBackgrounds = Object.fromEntries(
        backgrounds.map((background) => [background.id, background]),
      );
    },
    setFlexModel(flexTypes: FlexType[], flexBoards: Array<BoardData<"flex">>) {
      this.flexTypes = flexTypes;
      useBoardsStore().updateBoards("flex", flexBoards, (exist, board) => {
        exist.name = board.name;
        exist.flexType = board.flexType;
      });
    },
  },
});
