import { defineStore } from "pinia";

import { WorkingMode } from "@/model/baseTypes";
import { isSolutionBoard } from "@/model/board";

import { useBoardStore } from "./board";
import { useClientSettingsStore } from "./clientSettings";

interface State {
  workingMode: WorkingMode;
}

export const useWorkModeStore = defineStore("workMode", {
  state: (): State => ({
    workingMode: "planning",
  }),
  getters: {
    isExecutionMode(): boolean {
      return this.workingMode === "execution";
    },
    isPlanningMode(): boolean {
      return this.workingMode === "planning";
    },
    showExecutionMode(): boolean {
      return !isSolutionBoard(useBoardStore().board?.type);
    },
  },
  actions: {
    setWorkingMode(mode: WorkingMode) {
      const clientSettings = useClientSettingsStore();

      this.workingMode = mode;
      clientSettings.setWorkingMode(mode);
    },
  },
});
