import { defineStore } from "pinia";

import { plus } from "@/math/coordinates";
import { windowCoord } from "@/model/coordinates";
import { Border, Size } from "@/model/size";

import { useBoardStore } from "./board";

interface State {
  size: Size;
  scrollableMargin: Border;
  visibleMargin: Border;
  windowMargin: Border;
}

export const useBoardSizeStore = defineStore("boardSize", {
  state: (): State => ({
    // effective, calculated board pos/size
    size: {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
    },
    // scrollable margins around the board
    scrollableMargin: {
      leftTop: windowCoord(0, 0),
      rightBottom: windowCoord(0, 0),
    },
    // visible margins around the board when zoomed to 100%
    visibleMargin: {
      leftTop: windowCoord(64, 16),
      rightBottom: windowCoord(64, 64),
    },
    // margins of the window, unavailable for the board
    windowMargin: {
      leftTop: windowCoord(0, 48),
      rightBottom: windowCoord(0, 0),
    },
  }),
  getters: {
    leftTopMargin: (state) =>
      plus(
        plus(state.scrollableMargin.leftTop, state.visibleMargin.leftTop),
        state.windowMargin.leftTop,
      ),
    rightBottomMargin: (state) =>
      plus(
        plus(
          state.scrollableMargin.rightBottom,
          state.visibleMargin.rightBottom,
        ),
        state.windowMargin.rightBottom,
      ),
    totalScrollableMargin: (state) =>
      plus(state.scrollableMargin.leftTop, state.scrollableMargin.rightBottom),
    totalVisibleMargin: (state) =>
      plus(state.visibleMargin.leftTop, state.visibleMargin.rightBottom),
    totalWindowMargin: (state) =>
      plus(state.windowMargin.leftTop, state.windowMargin.rightBottom),
  },
  actions: {
    setMarginLeft(margin: number) {
      if (useBoardStore().isCurrentBoardFluid) {
        this.scrollableMargin.leftTop = windowCoord(
          margin,
          this.scrollableMargin.leftTop.y,
        );
      }
    },
  },
});
