<script setup lang="ts">
import { computed } from "vue";

import { selectionActions } from "@/action/selectionActions";
import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import { useBoardStore } from "@/store/board";
import { useDrawStore } from "@/store/draw";
import { useMessengerStore } from "@/store/messenger";
import { usePointerStore } from "@/store/pointer";
import { useSelectionStore } from "@/store/selection";
import { useSidePanelStore } from "@/store/sidePanel";
import { useUserStore } from "@/store/user";

import AddItem from "./components/AddItem.vue";
import CardZoom from "./components/CardZoom.vue";
import DrawActions from "./components/DrawActions.vue";
import MessengerItem from "./components/MessengerItem.vue";
import SelectionMenu from "./components/SelectionMenu.vue";

const showSelection = computed(
  () => useBoardStore().areMultipleStickiesSelected && !isReadonly.value,
);
const showDrawActions = computed(() => useDrawStore().active);
const showStickyActions = computed(
  () => useBoardStore().creatableStickyTypes.length > 0,
);
const showMessenger = computed(() => useMessengerStore().show);

const isReadonly = computed(() => !useUserStore().isAllowed("edit"));
const panelRightWidth = computed(() => useSidePanelStore().right.width + 10);
</script>

<template>
  <div
    v-scrollable-on-board
    class="bottom-menu"
    :style="{ right: panelRightWidth + 'px' }"
    data-no-screenshot
    :aria-label="$t('label.boardActions')"
  >
    <div data-organize-stickies class="position center">
      <div v-if="showSelection" class="menu-group">
        <SelectionMenu />
      </div>
    </div>

    <div class="position right">
      <div v-if="showDrawActions" class="menu-group">
        <DrawActions />
      </div>
      <template v-else>
        <div
          v-if="showStickyActions && !isReadonly"
          class="menu-group menu-group-add-item"
          style="padding: 0"
        >
          <AddItem />
        </div>
        <div class="menu-group">
          <ActionButton
            v-if="showStickyActions"
            :action="selectionActions.selectOnClick"
            :activated="useSelectionStore().selecting === 'click'"
          />
          <CardZoom v-if="showStickyActions" />
          <ActionButton
            :action="toggleActions.showTrailingPointer"
            :activated="usePointerStore().isPointerActive"
            trigger="board-actions-menu"
          />
        </div>
      </template>

      <div v-if="showMessenger" class="menu-group">
        <MessengerItem />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/z-index";
@use "@/styles/mixins/shadow";
@use "@/styles/side-panel";

.bottom-menu {
  z-index: z-index.$menu;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  transition: right ease-in-out side-panel.$panel-transition;

  .position {
    position: absolute;
    bottom: 0;
    display: flex;
    gap: 8px;

    &.center {
      left: 0;
      right: 0;
      margin: auto;
      width: fit-content;
    }

    &.right {
      right: 0;
    }
  }

  .menu-group {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 2px;
    height: 44px;
    background-color: colors-old.$back-color;
    border-radius: variables.$common-border-radius;
    width: fit-content;

    @include shadow.default;

    .high-contrast-mode &:not(.menu-group-add-item) {
      border: 2px solid colors-a11y.$divider-color;
    }
  }
}
</style>
