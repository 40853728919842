import { event } from "vue-gtag";

import { maxReceiveDelay } from "@/components/card/animator";
import { previewDelay } from "@/model/Settings";
import { ExecutionMode } from "@/model/baseTypes";
import { useActionStore } from "@/store/action";
import { useBoardStore } from "@/store/board";
import { actionEvent } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import {
  Action,
  ActionData,
  ActionDefinitionData,
  ActionSource,
  makeAction,
  makeActionData,
} from "./types";

export function action<TParams extends unknown[], TReturn, TState>(
  execute: ((...params: TParams) => TReturn) & {
    trigger?: ActionTrigger;
  },
  data: ActionDefinitionData<TParams, TState> = {},
): Action<TParams, TReturn, TState> {
  const actionData = makeActionData(data);
  const actionFun = (source: ActionSource, ...params: TParams) => {
    trackAction(actionData, source, params);
    if (source === "keyboard") {
      execute.trigger = "keyboard-shortcut";
    }
    return execute(...params);
  };
  return makeAction(actionFun, actionData);
}

export function previewAction<TParams extends unknown[], TReturn, TState>(
  execute: ((mode: ExecutionMode, ...params: TParams) => TReturn) & {
    trigger?: ActionTrigger;
  },
  data: ActionDefinitionData<TParams, TState> = {},
): Action<TParams, TReturn, TState> {
  const actionData = makeActionData(
    data,
    (...params: TParams) => {
      useActionStore().preview(
        actionFun,
        () => execute("preview", ...params),
        previewDelay,
      );
    },
    (...params: TParams) => {
      if (useActionStore().wasPreviewing === actionFun) {
        execute("undo", ...params);
        window.setTimeout(useActionStore().endPreview, maxReceiveDelay);
      }
    },
  );
  const actionFun = (source: ActionSource, ...params: TParams) => {
    trackAction(actionData, source, params);
    if (source === "keyboard") {
      execute.trigger = "keyboard-shortcut";
    }
    if (useActionStore().wasPreviewing === actionFun) {
      useActionStore().endPreview();
      return execute("confirm", ...params);
    }
    return execute("normal", ...params);
  };
  return makeAction(actionFun, actionData);
}

export function defineActions<
  T extends { [id in string]: Action<any, any, any> },
>(name: string, actions: T): { [id in keyof T]: T[id] } {
  Object.entries(actions).forEach(([id, action]) => {
    action.data.id = name + "." + id;
  });
  return actions as unknown as { [id in keyof T]: T[id] };
}

const actionTriggers = ["keyboard-shortcut"] as const;
type ActionTrigger = (typeof actionTriggers)[number];

// Workaround so we can know that an action was called by a specific trigger. Ex. keyboard shortcut
export function getActionTrigger(
  action: (...params: any[]) => void,
): ActionTrigger | undefined {
  if ("trigger" in action && isValidTrigger(action.trigger)) {
    return action.trigger;
  }
}

const isValidTrigger = (trigger: unknown): trigger is ActionTrigger => {
  return actionTriggers.includes(trigger as ActionTrigger);
};

const ignoreActions = new Array<string>();
const lastActionId: Record<string, string> = {};

function trackAction(
  data: ActionData<any, any>,
  source: ActionSource,
  params: any,
) {
  if (source !== "internal" && !ignoreActions.includes(data.id)) {
    if (data.history?.merge) {
      const id = data.history.saveState(...params).id;
      if (id !== lastActionId[data.id]) {
        lastActionId[data.id] = id;
        sendEvent();
      }
    } else {
      sendEvent();
    }
  }

  function sendEvent() {
    // For google analytics
    event(data.id, { page_title: useBoardStore().board?.type, source });

    // For amplitude
    trackEvent(actionEvent({ eventName: data.id, source }));
  }
}
